import React, {
  useMemo
} from 'react';
import Select from 'react-select';
import {
  pickSelectStyle,
} from 'configs/components/select';
import Empty from 'components/Empty';

import ConditionTableRow from './components/conditionTableRow';
import ActionTableRow from './components/actionTableRow';

import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import {
  useQuery,
} from "@apollo/client";
import {
  filterUnique,
  toSelArr,
  translateAllSelectItems,
} from 'helperFunctions';

import Checkbox from 'components/checkbox';

import {
  sourceOptions,
  conditionOptions,
  targetOptions,
  actionOptions,
  importantOptions,
  requesterOption,
  assignedOption,
  emptyOption,
  currentUserOption,
  completionOptions,
  creatorOption,
  boolOptions,
} from 'configs/constants/automation'

import {
  GET_MY_PROJECTS,
} from 'helpdesk/settings/projects/queries';

import {
  useTranslation
} from "react-i18next";

let fakeID = -1;

export default function TimerAutomationForm( props ) {
  const {
    onSubmit,
    onCancel,
    closeModal,
    cantSave,
    automationTimer,
    allUsers,
    userGroups,
    statuses,
    tags,
    projects,
    lockedRequester,
    companies,
  } = props;

  const {
    t
  } = useTranslation();

  const [ order, setOrder ] = React.useState( 0 );
  const [ active, setActive ] = React.useState( true );
  const [ title, setTitle ] = React.useState( "" );
  const [ description, setDescription ] = React.useState( "" );
  const [ conditions, setConditions ] = React.useState( [] );
  const [ actions, setActions ] = React.useState( [] );

  React.useEffect(() => {
    if (automationTimer){
      setOrder(automationTimer.order);
      setActive(automationTimer.active);
      setTitle(automationTimer.title);
      setDescription(automationTimer.description);
      setConditions(automationTimer.conditions);
      setActions(automationTimer.actions);
    }
  }, [automationTimer]);

  const clearData = () => {
    setOrder(0);
    setTitle("");
    setActive(true);
    setDescription("");
    setConditions([]);
    setActions([]);
  }

  const updateNotificationMessages = (newActions) => {
    return newActions.map((newAction) => ({
        ...newAction,
        notificationMessage: createNotificationMessage(newAction, newActions),
    }));
  }

    const isOriginalMessage = (message) => {
      if (message === "V helpdesku bola zaregistrovaná Vaša úloha $ID $Title" ||
        message === "Bola vam pridelena uloha $id $title" ||
        message === "Userovi $assigned bola pridelena uloha $id $title" ||
        message === "Úlohe $id  $title skončil termín naplánovania" ||
        message === "Úlohe $id  $title pridelenej $assignedTo uplynul deadline" ||
        message === "Úlohe $id  $title skončil deadline $deadlineDate"
      ){
        return true;
      }
      return false;
    }
    const createNotificationMessage = (changedAction, newActions) => {
      if (!changedAction.target || changedAction.target.value !== "notification"){
        return changedAction.notificationMessage;
      }
      if (changedAction.notificationMessage.length > 0 && !isOriginalMessage(changedAction.notificationMessage)){
        return changedAction.notificationMessage;
      }
      let message = "Some changes were made to task $id $title";
      let messageConditions = {
        taskIsCreated: conditions.flat().filter((group) =>
            group.source &&
            group.source.value === "task" &&
            group.condition &&
            group.condition.value === "created"
          ).length > 0,
        startsAtOverdue: conditions.flat().filter((group) =>
            group.source &&
            group.source.value === "startsAt" &&
            group.condition &&
            group.condition.value === "is" &&
            group.target &&
            group.target[0].value === "overdue"
          ).length > 0,
        deadlineOverdue: conditions.flat().filter((group) =>
            group.source &&
            group.source.value === "deadline" &&
            group.condition &&
            group.condition.value === "is" &&
            group.target &&
            group.target[0].value === "overdue"
          ).length > 0,
        statusIsNotClosed: conditions.flat().filter((group) =>
            group.source &&
            group.source.value === "status" &&
            group.condition &&
            group.condition.value === "isNot" &&
            group.target &&
            group.target[0].action === "CloseDate"
          ).length > 0,
      }

      const otherActions = newActions.filter((newAction) => newAction.id !== changedAction.id && newAction.target);
      let messageActions = {
        requesterSet: otherActions.find((otherAction) => otherAction.target.value === "requester"),
        notificationSendToRequesterOnly: changedAction.target.value === "notification" && changedAction.action?.value === "send" && changedAction.value && changedAction.value[0] && changedAction.value[0].value === "requester" && changedAction.value.length === 1,
        notificationSendToRequester: changedAction.target.value === "notification" && changedAction.action?.value === "send" && changedAction.value && changedAction.value[0] && changedAction.value.find((value) => value.value === "requester"),
        notificationSendToAssignedOnly: changedAction.target.value === "notification" && changedAction.action?.value === "send" && changedAction.value && changedAction.value[0] && changedAction.value[0].value === "assignedTo" && changedAction.value.length === 1,
        notificationSendToAssigned: changedAction.target.value === "notification" && changedAction.action?.value === "send" && changedAction.value && changedAction.value[0] && changedAction.value.find((value) => value.value === "assignedTo"),
      }

      if (messageConditions.taskIsCreated &&
        messageActions.requesterSet &&
        messageActions.notificationSendToRequesterOnly
      ) {
        return "V helpdesku bola zaregistrovaná Vaša úloha $ID $Title";
      }
        if (messageConditions.taskIsCreated &&
          messageActions.notificationSendToAssignedOnly
        ) {
          return "Bola vam pridelena uloha $id $title";
        }
        if (messageConditions.taskIsCreated &&
            messageActions.notificationSendToAssigned
          ) {
            return "Userovi $assigned bola pridelena uloha $id $title";
          }
      if (messageConditions.startsAtOverdue &&
        messageConditions.statusIsNotClosed
      ) {
        return "Úlohe $id  $title skončil termín naplánovania";
      }
       if (messageConditions.deadlineOverdue &&
          messageConditions.statusIsNotClosed &&
          (messageActions.notificationSendToRequester || messageActions.notificationSendToRequesterOnly)
        ) {
          return "Úlohe $id  $title pridelenej $assignedTo uplynul deadline";
        }
    if (messageConditions.deadlineOverdue &&
      messageConditions.statusIsNotClosed
    ) {
      return "Úlohe $id  $title skončil deadline $deadlineDate";
    }

      return message;
    }

  return (
      <Empty>
        <FormGroup>
          <Checkbox
            className = "m-r-5"
            label={t('active')}
            value = { active }
            onChange={() => {
              setActive(!active)
            }}
            />
        </FormGroup>

          <FormGroup>
            <Label>{t('title')}</Label>
            <input
              className="form-control"
              value={title}
              onChange={e => setTitle(e.target.value)}
              />
          </FormGroup>

          <FormGroup>
            <Label>{t('order')}</Label>
            <input
              className="form-control"
              type="number"
              value={order}
              onChange={e => setOrder(e.target.value)}
              />
          </FormGroup>

          <FormGroup>
            <Label>{t('description')}</Label>
            <Input
              className="form-control"
              value={description}
              type={'textarea'}
              onChange={e => setDescription(e.target.value)}
              />
          </FormGroup>

          <FormGroup>
            <Label>{t('conditions')}</Label>

            {
              conditions.map((orGroup, orGroupIndex) =>
                <Empty key={orGroupIndex}>
                <table className="table bkg-white project-settings">
                  <tbody>
                    {
                      orGroup.map((group, index) => (
                        <ConditionTableRow
                          {...props}
                          key={index}
                          index={index}
                          orGroupLength={orGroup.length}
                          projects={projects}
                          orGroupIndex={orGroupIndex}
                          group={group}
                          conditions={conditions}
                          setConditions={setConditions}
                        />
                    ))
                  }
                  <tr key="0">
                    <td colSpan="5">
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <button
                          className="btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            const newConditions = conditions.map((orGr, orGrIndex) => {
                            if (orGrIndex === orGroupIndex){
                              return [
                                ...orGr,
                                {
                                  orGroup: orGr[0].orGroup,
                                  id: fakeID--,
                                  source: null,
                                  condition: null,
                                  target: null,
                                }
                              ]
                            }
                            return orGr;
                          });
                            setConditions(newConditions);
                          }}
                          >
                          <i className="fa fa-plus m-r-5" style={{fontSize: "16px"}}/> {t('conjunction')}
                        </button>
                        <button
                          className="btn-link-red text-right"
                          onClick={(e) => {
                            e.preventDefault();
                            const newConditions = conditions.filter((orGr, orGrIndex) => orGrIndex !== orGroupIndex);
                            setConditions(newConditions);
                          }}
                          >
                          <i className="fa fa-times" style={{fontSize: "16px"}}/> {t('conditionGroup')}
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                {
                  conditions[0].length > 0 &&
                  conditions.length - 1 === orGroupIndex &&
                <button
                  className="btn"
                  onClick={(e) => {
                    e.preventDefault();
                    const newConditions = [...conditions, [{
                      orGroup: conditions.length + 1,
                      id: fakeID--,
                      source: null,
                      condition: null,
                      target: null,
                    }]]
                    setConditions(newConditions);
                  }}
                  >
                  {t('addConditionGroup')}
                </button>
              }
              {
                orGroupIndex < (conditions.length - 1) &&
                conditions.length > 1 &&
                <label style={{fontSize: "16px"}}>{t('or')}</label>
              }
              </Empty>
            )
          }
          {
            conditions.length === 0 &&
            <button
              className="btn-link"
              style={{display: "block"}}
              onClick={(e) => {
                e.preventDefault();
                const newConditions = [[{
                  id: fakeID--,
                  orGroup: 1,
                  source: null,
                  condition: null,
                  target: null,
                }]]
                setConditions(newConditions);
              }}
              >
              <i className="fa fa-plus m-r-5" style={{fontSize: "16px"}}/> {t('condition')}
            </button>
          }
          </FormGroup>

          <FormGroup>
            <Label>{t('actions')}</Label>
            {
              actions.length > 0 &&
              <table className="table bkg-white project-settings">
                <tbody>
                {
                  actions.map((action, index) => (
                    <ActionTableRow
                      {...props}
                      key={index}
                      index={index}
                      projects={projects}
                      conditionsHaveOnlyRepeat={conditions.length === 1 && conditions[0].length === 1 && conditions[0][0].source && conditions[0][0].source.value === "repeat"}
                      action={action}
                      actions={actions}
                      setActions={(newActions) => {
                        const updatedActions = updateNotificationMessages(newActions);
                        setActions(updatedActions);
                      }}
                      createNotificationMessage={createNotificationMessage}
                    />
                  ))
                }
              </tbody>
            </table>
          }
            <button
              style={{display: "block"}}
              className="btn-link"
              onClick={(e) => {
                e.preventDefault();
                const newActions = [...actions, {
                  id: fakeID--,
                  target: null,
                  action: null,
                  value: null,
                  notificationMessage: "",
                }]
                setActions(newActions);
              }}
              >
              <i className="fa fa-plus m-r-5" style={{fontSize: "16px"}}/> {t('action')}
            </button>
          </FormGroup>

        <div className="row">
          <button
            className="btn-link-cancel"
            onClick={() => {
              clearData();
              onCancel();
            }}> {t('cancel')} </button>

          <button className="btn ml-auto"
            disabled={cantSave}
            onClick={() =>{
              onSubmit({
                      id: automationTimer ? automationTimer.id : fakeID--,
                      active,
                      order,
                      title,
                      description,
                      conditions,
                      actions,
                    });
              clearData();
              closeModal();
            }}
            >
            {t('save')}
          </button>
        </div>
      </Empty>
  );

}
