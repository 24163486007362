import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useQuery, useSubscription } from '@apollo/client';
import classnames from 'classnames';

import Empty from 'components/Empty';
import SettingLoading from '../components/settingLoading';
import SettingListContainer from '../components/settingListContainer';
import { itemAttributesFullfillsString } from '../components/helpers';
import Loading from 'components/loading';

import CompanyAdd from './companyAdd';
import CompanyEdit from './companyEdit';
import { GET_COMPANIES, COMPANIES_SUBSCRIPTION } from './queries';
import { useTranslation } from 'react-i18next';

export default function CompaniesList(props) {
    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    const {
        data: companiesData,
        loading: companiesLoading,
        refetch: companiesRefetch,
    } = useQuery(GET_COMPANIES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(COMPANIES_SUBSCRIPTION, {
        onData: () => {
            companiesRefetch();
        },
    });

    // state
    const [companyFilter, setCompanyFilter] = React.useState('');
    //const [sortBy, setSortBy] = React.useState('');

    if (companiesLoading) {
        return <SettingLoading />;
    }

    const companies = companiesData.companies.filter((company) => !company.def);
    /*
    const CompanySort = (
        <div className="d-flex flex-row align-items-center ml-auto">
            <div className="color-basic m-r-5 m-l-5">{t('sortBy')}</div>
            <select
                value={sortBy}
                className="invisible-select font-bold text-highlight"
                onChange={(e) => setSortBy(e.target.value)}
            >
                <option value={0} key={0}>
                    {t('all')}
                </option>
                <option value={1} key={1}>
                    {t('contracted')}
                </option>
                <option value={2} key={2}>
                    {t('nonContracted')}
                </option>
            </select>
        </div>
    );
    */

    const RightSideComponent = (
        <Empty>
            {params.id && params.id === 'add' && <CompanyAdd {...props} />}
            {companiesLoading && params.id && params.id !== 'add' && <Loading />}
            {params.id &&
                params.id !== 'add' &&
                companies.some((item) => item.id === parseInt(params.id)) && <CompanyEdit />}
        </Empty>
    );

    return (
        <SettingListContainer
            header={t('companies')}
            filter={companyFilter}
            setFilter={setCompanyFilter}
            addURL={
                window.location.pathname.includes('settings')
                    ? `/helpdesk/settings/companies/add`
                    : `/helpdesk/companies/add`
            }
            addLabel={t('company')}
            RightSideComponent={RightSideComponent}
        >
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>{t('title')}</th>
                    </tr>
                </thead>
                <tbody>
                    {companies
                        .filter((item) =>
                            itemAttributesFullfillsString(item, companyFilter, ['title'])
                        )
                        .map((company) => (
                            <tr
                                key={company.id}
                                className={classnames('clickable', {
                                    'active': parseInt(params.id) === company.id,
                                })}
                                onClick={() =>
                                    navigate(
                                        window.location.pathname.includes('settings')
                                            ? `/helpdesk/settings/companies/${company.id}`
                                            : `/helpdesk/companies/${company.id}`
                                    )
                                }
                            >
                                <td>{company.title}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </SettingListContainer>
    );
}
