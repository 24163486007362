export default {
  cmdb: {
    en: 'LanCMDB',
    sk: 'LanCMDB',
    cz: 'LanCMDB',
    de: 'LanCMDB',
  },
  allCompanies: {
    en: 'All companies',
    sk: 'Všetky firmy',
    cz: 'Všechny společnosti',
    de: 'Alle Unternehmen',
  },
}
