import React, {
  useMemo
} from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import AddTimerAutomation from './add';
import EditTimerAutomation from './edit';

import {
  useTranslation
} from "react-i18next";

export default function TimerAutomation( props ) {
  const {
    automationTimers,
    addAutomationTimer,
    updateAutomationTimer,
    deleteAutomationTimer,
  } = props;

  const {
    t
  } = useTranslation();

  const [ addOpen, setAddOpen ] = React.useState( false );
  const [ editOpen, setEditOpen ] = React.useState( null );

  const disabled = false;

  return (
    <div>
      <h3>{t('timerAutomation')}</h3>
      <table className="table m-t-10 bkg-white project-settings">
        <thead>
          <tr>
            <th className="font-bold"> {t('title')} </th>
            <th className="font-bold"> {t('description')} </th>
            <th className="font-bold" width="100px"> {t('active')} </th>
            <th className="font-bold" width="100px"> {t('order')} </th>
            <th className="text-center font-bold" width="100px">{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          { automationTimers.sort((o1, o2) => (parseInt(o1.order) < parseInt(o2.order) ? -1 : 1)).map( automation =>
            <tr key={automation.id}>
              <td> {automation.title.length ? automation.title : t('noTitle')} </td>
              <td> {automation.description.length ? automation.description : t('noDescription')} </td>
              <td> {automation.active ? t('yes') : t('no')} </td>
              <td> {automation.order} </td>
              <td className="text-end">
                <button
                  className="btn-link btn-distance"
                  disabled={disabled}
                  style={{height:"auto"}}
                  onClick={()=>{
                    if(window.confirm(t('areYouSure'))){
                      deleteAutomationTimer(automation.id);
                    }
                  }}>
                  <i className="fa fa-times"  />
                </button>
                <button
                  className="btn-link"
                  style={{height:"auto"}}
                  disabled={disabled}
                  onClick={()=>{
                    setEditOpen(automation);
                  }}>
                  <i className="fa fa-pen"  />
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <button className="btn-link"
        disabled={disabled}
        onClick={()=>{
          setAddOpen(true);
        }}
        >
        <i className="fa fa-plus p-r-5" />
        {t('item')}
      </button>
      <AddTimerAutomation
        {...props}
        open={addOpen}
        closeModal={() => setAddOpen(false)}
        addAutomationTimer={addAutomationTimer}
        />
      <EditTimerAutomation
        {...props}
        open={editOpen !== null}
        automationTimer={editOpen}
        closeModal={() => setEditOpen(null)}
        updateAutomationTimer={updateAutomationTimer}
        />
    </div>
  );
}
