import {
  //momentLocalizer,
  //dateFnsLocalizer
} from "react-big-calendar";

//import moment from 'moment';
import * as fns from 'date-fns';

import {
  timestampToDateFNS,
  timestampToHoursAndMinutesFNS,
} from 'helperFunctions';
/*
moment.locale( 'sk', {
  week: {
    dow: 1,
    doy: 1,
  },
} );
*/

//export const calendarLocalizer = momentLocalizer( moment );
export const calendarLocalizer = null;
/*dateFnsLocalizer( {
  format: fns.format,
  parse: fns.parse,
  startOfWeek: fns.startOfWeek,
  getDay: fns.getDay,
  locales:  {
    'sk-SK': fns.locale.sk,
  }
} );*/

export const calendarDateFormats = {
  //dayFormat: ( date, culture, localizer ) => localizer.format( date, 'EEE', culture ),
  dayFormat: (date, culture, localizer) => {
    return fns.format(date, 'EEEE', {
      localizer
    });
  },

  timeGutterFormat: (date, culture, localizer) => {
    return timestampToHoursAndMinutesFNS(date);
  },
  dayRangeHeaderFormat: ({
    start,
    end
  }, culture, localizer) => {
    return timestampToDateFNS(start) + ' - ' + timestampToDateFNS(end);
  },
  agendaHeaderFormat: ({
    start,
    end
  }, culture, localizer) => {
    return timestampToDateFNS(start) + ' - ' + timestampToDateFNS(end);
  },
  selectRangeFormat: ({
    start,
    end
  }, culture, localizer) => {
    return timestampToHoursAndMinutesFNS(start) + ' - ' + timestampToHoursAndMinutesFNS(end);
  },
  eventTimeRangeFormat: () => null,
}

export const taskCalendarDefaults = {
  defaultDate: new Date(),
  style: {
    padding: "20px"
  },
  views: ['month', 'week', 'work_week', 'day', 'agenda'],
  drilldownView: "day",
  //  localizer: calendarLocalizer,
  resizable: true,
  popup: true,
  formats: calendarDateFormats,
  scrollToTime: fns.setHours(new Date(), 8),

}