import React from 'react';

import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import CommandBar from './components/commandBar';
import Pagination from './components/pagination';
import ActiveSearch from './components/activeSearch';
import Loading from 'components/loading';

import {
  timestampToStringFNS,
} from 'helperFunctions';

import {
  useTranslation
} from "react-i18next";

export default function PasswordsList(props) {

  const {
    loading,
    passwords,
    company,
    setLocalStringFilter,
    localStringFilter,
    setGlobalStringFilter,
  } = props;

  const navigate = useNavigate();
  const params = useParams();

  const {
    t
  } = useTranslation();

  let path = `/cmdb/passwords/${params.companyID ? params.companyID : 'all'}/p/${params.page ? params.page : 1}`;

  return (
    <div>
      <CommandBar
        {...props}
      />
      <div className="full-width scroll-visible fit-with-header-and-commandbar-list task-container">
        <div className="row m-l-30">
          <input
            type="text"
            value={localStringFilter.title}
            placeholder={t('title')}
            className="form-control width-250"
            style={{ fontSize: "12px", marginRight: "10px" }}
            onKeyPress={(e) => {
              if (e.key === 13 && !loading) {
                setGlobalStringFilter();
              }
            }}
            onChange={(e) => {
              setLocalStringFilter('title', e.target.value);
            }}
          />
          <button className="btn" onClick={setGlobalStringFilter} >
            {t('search')}
          </button>
          <button
            className="btn-link center-hor commandbar-addon m-l-5"
            onClick={() => navigate(`/cmdb/passwords/${company.id === null ? 'all' : company.id}/add`)}>
            <i className="fa fa-plus p-l-5 p-r-5" />
            {t('password')}
          </button>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th style={{ paddingBottom: "12px", paddingTop: "12px" }}>
                {t('title')}
              </th>
              <th width="250" style={{ paddingBottom: "12px", paddingTop: "12px" }}>
                {t('url')}
              </th>
              <th width="250" style={{ paddingBottom: "12px", paddingTop: "12px" }}>
                {t('login2')}
              </th>
              <th width="250" style={{ paddingBottom: "12px", paddingTop: "12px" }}>
                {t('expireDate')}
              </th>
            </tr>
          </thead>

          <tbody>
            <ActiveSearch {...props} />

            {
              passwords.map((password) => (
                <tr key={password.id} className="clickable noselect" onClick={() => navigate(`${path}/${password.id}`)}>
                  <td className="font-14-f">
                    {password.title}
                  </td>
                  <td>
                    <a href={`//${password.url}`} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>{password.url}</a>
                  </td>
                  <td>
                    {password.login.length > 0 ? password.login : t('noLogin')}
                  </td>
                  <td>
                    {password.expireDate ? timestampToStringFNS(password.expireDate) : t('noExpireDate')}
                  </td>
                </tr>
              ))
            }
            {
              passwords.length === 0 &&
              <tr>
                <td colSpan="10">
                  {t('noData')}
                </td>
              </tr>
            }
            {
              loading &&
              <tr>
                <td colSpan="100">
                  <Loading noPos flex />
                </td>
              </tr>
            }
          </tbody>
        </table>
        <Pagination {...props} />
      </div>
    </div>
  );
}