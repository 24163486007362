import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { updateArrayItem, sortBy } from 'helperFunctions';
import ProjectFilterEdit from './projectFilterEdit';
import ProjectFilterAdd from './projectFilterAdd';
import { getGroupsProblematicAttributes } from '../../helpers';
/*
import {
  GET_TASK_TYPES,
} from 'helpdesk/settings/taskTypes/queries';
*/
import { GET_BASIC_USERS } from 'helpdesk/settings/users/queries';
import { GET_BASIC_COMPANIES } from 'helpdesk/settings/companies/queries';
import { useTranslation } from 'react-i18next';

let fakeID = -1;

export default function ProjectFilters(props) {
    //data
    const {
        groups,
        statuses,
        tags,
        customAttributes,
        filters,
        addFilter,
        deleteFilter,
        updateFilter,
    } = props;

    const { t } = useTranslation();
    /*
    const {
      data: taskTypesData,
      loading: taskTypesLoading
    } = useQuery( GET_TASK_TYPES );
  */
    const { data: usersData, loading: usersLoading } =
        useQuery(GET_BASIC_USERS);

    const {
        data: companiesData,
        loading: companiesLoading,
    } = useQuery(GET_BASIC_COMPANIES);

    const [addOpen, setsetAddOpen] = React.useState(false);

    const dataLoading =
        /*  taskTypesLoading ||*/
        usersLoading || companiesLoading;

    const translatedGroups = useMemo(() => {
        return groups.map((group) => ({
            ...group,
            title: t(group.title),
        }));
    }, [groups]);

    return (
        <div>
            <table className="table bkg-white m-t-5 project-settings">
                <thead>
                    <tr>
                        <th className="font-bold">
                            {t('projectFilterName')}
                        </th>
                        <th className="font-bold">
                            {t('description')}
                        </th>
                        <th className="font-bold">
                            {t('groups')}
                        </th>
                        <th className="font-bold">
                            {t('order')}
                        </th>
                        <th
                            width="150"
                            className="font-bold"
                        >
                            {t('actions')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortBy(filters, [
                        { asc: true, key: 'order' },
                    ]).map((projectFilter) => (
                        <tr key={projectFilter.id}>
                            <td>{projectFilter.title}</td>
                            <td>
                                {projectFilter.description}
                            </td>
                            <td>
                                {getGroupsProblematicAttributes(
                                    groups,
                                    projectFilter,
                                    customAttributes,
                                    t
                                ).length !== 0 && (
                                    <i className="text-danger font-size-16 m-l-10 m-t-10 fa fa-exclamation-circle" />
                                )}
                                {translatedGroups
                                    .filter((group) =>
                                        projectFilter.groups.some(
                                            (groupId) =>
                                                group.id ===
                                                groupId
                                        )
                                    )
                                    .map(
                                        (group) =>
                                            group.title
                                    )
                                    .join(', ')}
                            </td>
                            <td>{projectFilter.order}</td>
                            <td>
                                <ProjectFilterEdit
                                    allGroups={
                                        translatedGroups
                                    }
                                    allStatuses={statuses}
                                    allTags={tags}
                                    customAttributes={
                                        customAttributes
                                    }
                                    allUsers={
                                        dataLoading
                                            ? []
                                            : usersData.basicUsers
                                    }
                                    allCompanies={
                                        dataLoading
                                            ? []
                                            : companiesData.basicCompanies
                                    }
                                    disabled={dataLoading}
                                    filter={projectFilter}
                                    updateFilter={(
                                        updatedFilter
                                    ) =>
                                        updateFilter(
                                            updatedFilter
                                        )
                                    }
                                />
                                <button
                                    className="btn-link-red"
                                    style={{
                                        height: 'auto',
                                    }}
                                    onClick={() =>
                                        deleteFilter(
                                            projectFilter.id
                                        )
                                    }
                                >
                                    <i className="fa fa-times" />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ProjectFilterAdd
                allGroups={translatedGroups}
                allStatuses={statuses}
                allTags={tags}
                customAttributes={customAttributes}
                allUsers={
                    dataLoading ? [] : usersData.basicUsers
                }
                allCompanies={
                    dataLoading
                        ? []
                        : companiesData.basicCompanies
                }
                disabled={dataLoading}
                addProjectFilter={addFilter}
            />
        </div>
    );
}
