import React from 'react';
import ColumnList from './columnList';

import { useQuery, useSubscription } from '@apollo/client';

import { processStringFilter } from 'helperFunctions';
import { useTranslation } from 'react-i18next';

import {
    setLocalTaskStringFilter,
    setSingleLocalTaskStringFilter,
    setGlobalTaskStringFilter,
} from 'apollo/localSchema/actions';

import {
    GET_LOCAL_TASK_STRING_FILTER,
    GET_GLOBAL_TASK_STRING_FILTER,
} from 'apollo/localSchema/queries';

import { GET_TASKS, ADD_TASK_SUBSCRIPTION } from 'helpdesk/task/queries';

export default function ColumnListLoader(props) {
    const {
        localProject,
        localMilestone,
        localFilter,
        orderBy,
        ascending,
        globalTaskSearch,
        page,
        limit,
        processTasks,
        currentUser,
        filterVariables,
    } = props;

    const { t } = useTranslation();

    //local queries
    const { data: localStringFilter } = useQuery(GET_LOCAL_TASK_STRING_FILTER);

    const { data: globalStringFilter } = useQuery(GET_GLOBAL_TASK_STRING_FILTER);

    //apollo queries
    const taskVariables = {
        projectId: localProject.id,
        milestoneId: localMilestone.id,
        filter: filterVariables,
        sort: {
            asc: ascending,
            key: orderBy,
        },
        search: globalTaskSearch,
        stringFilter: processStringFilter(globalStringFilter.globalTaskStringFilter),
        page,
        limit,
    };

    const {
        data: tasksData,
        loading: tasksLoading,
        refetch: tasksRefetchFunc,
    } = useQuery(GET_TASKS, {
        variables: taskVariables,
        notifyOnNetworkStatusChange: true,
    });

    //state
    const [forcedRefetch, setForcedRefetch] = React.useState(false);

    //sync
    const tasksRefetch = () => {
        tasksRefetchFunc(taskVariables);
    };

    //refetch tasks
    React.useEffect(() => {
        tasksRefetch();
    }, [
        localFilter,
        localProject.id,
        localMilestone.id,
        currentUser,
        globalStringFilter,
        forcedRefetch,
    ]);

    useSubscription(ADD_TASK_SUBSCRIPTION, {
        onData: () => {
            tasksRefetch();
        },
    });

    const dataLoading = tasksLoading;

    const tasks = dataLoading ? [] : tasksData.tasks.tasks;

    const tableProps = {
        ...props,
        loading: dataLoading,
        tasks: processTasks(tasks),
        count: tasksLoading ? null : tasksData.tasks.count,
        forceRefetch: () => setForcedRefetch(!forcedRefetch),
        localStringFilter: localStringFilter.localTaskStringFilter,
        setLocalTaskStringFilter,
        globalStringFilter: globalStringFilter.globalTaskStringFilter,
        setGlobalTaskStringFilter,
        setSingleLocalTaskStringFilter,
    };

    return <ColumnList {...tableProps} />;
}
