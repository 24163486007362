export default {
    access: {
        en: 'Access',
        sk: 'Prístup',
        cz: 'Přístup',
        de: 'Zugang',
    },
    projectSomeStatusProblem: {
        en: 'status (statuses, status date, close date, pending date)',
        sk: 'status (statusy, status dátum, dátum uzavretia, dátum pozastavenia)',
        cz: 'stav (stavy, datum stavu, datum uzavření, datum čekání)',
        de: 'Status (Status, Statusdatum, Abschlussdatum, ausstehendes Datum)',
    },
    projectSomeWorksProblem: {
        en: 'task works (scheduled date)',
        sk: 'práce (dátum naplánovania)',
        cz: 'úkol funguje (plánované datum)',
        de: 'Aufgabe funktioniert (geplanter Termin)',
    },
    projectNoDescription: {
        en: 'Project without description',
        sk: 'Projekt nemá popis',
        cz: 'Projekt bez popisu',
        de: 'Projekt ohne Beschreibung',
    },
    groups: {
        en: 'Groups',
        sk: 'Skupiny',
        cz: 'Skupiny',
        de: 'Gruppen',
    },
    groupRights: {
        en: 'Group rights',
        sk: 'Práva skupín',
        cz: 'Skupinová práva',
        de: 'Gruppenrechte',
    },
    attributesRights: {
        en: 'Attributes rights',
        sk: 'Práva polí',
        cz: 'Práva polí',
        de: 'Attributrechte',
    },
    customAttributes: {
        en: 'Custom attributes',
        sk: 'Vlastné polia',
        cz: 'Vlastní atributy',
        de: 'Benutzerdefinierte Attribute',
    },
    customAttribute: {
        en: 'Custom attribute',
        sk: 'Vlastné pole',
        cz: 'Vlastní atribut',
        de: 'Benutzerdefiniertes Attribut',
    },
    projectFilters: {
        en: 'Project filters',
        sk: 'Projektové filtre',
        cz: 'Projektové filtry',
        de: 'Projektfilter',
    },
    projectName: {
        en: 'Project name',
        sk: 'Názov projektu',
        cz: 'Název projektu',
        de: 'Projektname',
    },
    projectNamePlaceholder: {
        en: 'Enter project name',
        sk: 'Zadajte názov projektu',
        cz: 'Zadejte název projektu',
        de: 'Geben Sie den Projektnamen ein',
    },
    myTasks: {
        en: 'My tasks',
        sk: 'Moje úlohy',
        cz: 'Moje úkoly',
        de: 'Meine Aufgaben',
    },
    requestedTasks: {
        en: 'Requested tasks',
        sk: 'Zadané úlohy',
        cz: 'Požadované úkoly',
        de: 'Angeforderte Aufgaben',
    },
    importantTasks: {
        en: 'Important tasks',
        sk: 'Dôležité úlohy',
        cz: 'Důležité úkoly',
        de: 'Wichtige Aufgaben',
    },
    scheduledTasks: {
        en: 'Scheduled tasks',
        sk: 'Naplánované úlohy',
        cz: 'Naplánované úkoly',
        de: 'Geplante Aufgaben',
    },
    archived: {
        en: 'Archived',
        sk: 'Archivované',
        cz: 'Archivováno',
        de: 'Archiviert',
    },
    invoiceOn: {
        en: 'Invoiced on',
        sk: 'Zapnuté výkazovanie',
        cz: 'Fakturováno dne',
        de: 'In Rechnung gestellt am',
    },
    invoiceOff: {
        en: 'Invoiced off',
        sk: 'Vypnuté výkazovanie',
        cz: 'Vyfakturováno',
        de: 'Abgerechnet',
    },
    invoice: {
        en: 'Invoice',
        sk: 'Vykazovanie',
        cz: 'Fakturovat',
        de: 'Rechnung',
    },
    hideInvoice: {
        en: "Don't show invoice",
        sk: 'Skryť vykazovanie',
        cz: 'Nezobrazovat fakturu',
        de: 'Rechnung nicht vorzeigen',
    },
    addUserToProjectGroup: {
        en: 'Add user to project group',
        sk: 'Pridať používateľa do projektovej skupiny',
        cz: 'Přidat uživatele do projektové skupiny',
        de: 'Benutzer zur Projektgruppe hinzufügen',
    },
    userGroup: {
        en: 'User group',
        sk: 'Používateľová skupina',
        cz: 'Uživatelská skupina',
        de: 'Benutzergruppe',
    },
    deleteProject: {
        en: 'DELETE PROJECT',
        sk: 'VYMAZAŤ PROJEKT',
        cz: 'ODSTRANIT PROJEKT',
        de: 'PROJEKT LÖSCHEN',
    },
    deleteProjectMessage: {
        en: 'Deleting a project deletes all tasks in the project. Do you still want to delete the project?',
        sk: 'Vymazanie projektu vymaže aj všetky úlohy v projekte. Chcete ho aj tak vymazať?',
        cz: 'Smazáním projektu se odstraní všechny úkoly v projektu. Stále chcete smazat projekt?',
        de: 'Beim Löschen eines Projekts werden alle Aufgaben im Projekt gelöscht. Möchten Sie das Projekt trotzdem löschen?',
    },
    projectIncludes: {
        en: 'This project includes',
        sk: 'Tento projekt obsahuje',
        cz: 'Tento projekt zahrnuje',
        de: 'Dieses Projekt beinhaltet',
    },
    addNewCustomAttribute: {
        en: 'Add new custom attribute',
        sk: 'Pridať nové vlastné pole',
        cz: 'Přidat nový vlastní atribut',
        de: 'Neues benutzerdefiniertes Attribut hinzufügen',
    },
    addCustomAttribute: {
        en: 'Add custom attribute',
        sk: 'Pridať vlastné pole',
        cz: 'Přidat vlastní atribut',
        de: 'Benutzerdefiniertes Attribut hinzufügen',
    },
    editCustomAttribute: {
        en: 'Edit custom attribute',
        sk: 'Upraviť vlastné pole',
        cz: 'Upravit vlastní atribut',
        de: 'Benutzerdefiniertes Attribut bearbeiten',
    },
    saveCustomAttribute: {
        en: 'Save custom attribute',
        sk: 'Uložiť vlastné pole',
        cz: 'Uložit vlastní atribut',
        de: 'Benutzerdefiniertes Attribut speichern',
    },
    changeColor: {
        en: 'Change color',
        sk: 'Zmeniť farbu',
        cz: 'Změnit barvu',
        de: 'Farbe ändern',
    },
    icon: {
        en: 'Icon',
        sk: 'Ikona',
        cz: 'Ikona',
        de: 'Symbol',
    },
    reaction: {
        en: 'Reaction',
        sk: 'Reakcia',
        cz: 'Reakce',
        de: 'Reaktion',
    },
    generalErrors: {
        en: 'General errors',
        sk: 'Všeobecné chybové hlásenia',
        cz: 'Obecné chyby',
        de: 'Allgemeine Fehler',
    },
    errorProjectNameCantBeEmpty: {
        en: "Project name can't be empty!",
        sk: 'Projektové meno nesmie byť prázdne!',
        cz: 'Název projektu nesmí být prázdný!',
        de: 'Der Projektname darf nicht leer sein!',
    },
    errorProjectNeedsIsNewStatus: {
        en: 'You need at least one status with "is new" action.',
        sk: 'Projekt musí mať aspoň jeden status s akciou "Nová".',
        cz: 'Potřebujete alespoň jeden stav s akcí „je nový“.',
        de: 'Sie benötigen mindestens einen Status mit der Aktion „ist neu“.',
    },
    errorProjectNeedsCloseDateStatus: {
        en: 'You need at least one status with "close date" action.',
        sk: 'Projekt musí mať aspoň jeden status s akciou "Zavrieť".',
        cz: 'Potřebujete alespoň jeden stav s akcí „datum uzavření“.',
        de: 'Sie benötigen mindestens einen Status mit der Aktion "Abschlussdatum".',
    },
    errorProjectAtLeastOneAdmin: {
        en: 'There needs to be at least one user in a group that has right to read and edit this project.',
        sk: 'Projekt musí mať aspoň jedného používateľa ktorý ho vie vidieť a upravovať.',
        cz: 'Ve skupině musí být alespoň jeden uživatel, který má právo číst a upravovat tento projekt.',
        de: 'Es muss mindestens ein Benutzer in einer Gruppe vorhanden sein, der berechtigt ist, dieses Projekt zu lesen und zu bearbeiten.',
    },
    tagsErrors: {
        en: 'Tags errors',
        sk: 'Tagové chybové hlásenia',
        cz: 'Chyby značek',
        de: 'Tags Fehler',
    },
    errorProjectTag1: {
        en: 'Tag with order',
        sk: 'Tag s poradím',
        cz: 'Štítek s pořadím',
        de: 'Tag mit Bestellung',
    },
    errorProjectTag2: {
        en: 'has empty title',
        sk: 'má prázdny názov',
        cz: 'má prázdný název',
        de: 'hat einen leeren Titel',
    },
    errorProjectTag3: {
        en: 'Tag with title',
        sk: 'Tag s názvom',
        cz: 'Štítek s názvem',
        de: 'Tag mit Titel',
    },
    errorProjectTag4: {
        en: 'and order',
        sk: 'a poradím',
        cz: 'a pořadím',
        de: 'und ich werde beraten',
    },
    errorProjectTag5: {
        en: 'has invalid color',
        sk: 'má neplatnú farbu',
        cz: 'má neplatnou barvu',
        de: 'hat eine ungültige Farbe',
    },
    errorProjectTag6: {
        en: 'Tag with title',
        sk: 'Tag s názvom',
        cz: 'Štítek s názvem',
        de: 'Tag mit Titel',
    },
    errorProjectTag7: {
        en: 'is missing order',
        sk: 'nemá poradie',
        cz: 'nemá pořadí',
        de: 'außer Betrieb',
    },
    errorProjectAttribute1: {
        en: 'Attribute',
        sk: 'Pole',
        cz: 'Atribut',
        de: 'Attribut',
    },
    errorProjectAttribute2: {
        en: 'is fixed, but needs to be set to specific value',
        sk: 'je fixné, ale potrebuje mať nastavenú hodnotu',
        cz: 'je pevná, ale musí být nastavena na konkrétní hodnotu',
        de: 'ist fest, muss aber auf einen bestimmten Wert eingestellt werden',
    },
    /*
  errorProjectTaskTypeNoValue: {
    en: 'Attribute task type MUST have specific value',
    sk: 'Pole typ úlohy MUSÍ mať nastavenú hodnotu',
    cz: '',
    de: '',
  },
  */
    filterErrors: {
        en: 'Filters errors',
        sk: 'Chybové hlásenia filtrov',
        cz: 'Filtruje chyby',
        de: 'Filtert Fehler',
    },
    group: {
        en: 'Group',
        sk: 'Skupina',
        cz: 'Skupina',
        de: 'Gruppe',
    },
    options: {
        en: 'Options',
        sk: 'Možnosti',
        cz: '',
        de: '',
    },
    option: {
        en: 'Option',
        sk: 'Možnosť',
        cz: 'Možnosti',
        de: 'Optionen',
    },
    errorProjectCantUseFilter: {
        en: "can't use this filter! Attributes that they can't see are:",
        sk: 'nemôže použiť tento filter! Polia ktoré nevidia a sú vo filtri sú:',
        cz: 'tento filtr nelze použít! Atributy, které nevidí, jsou:',
        de: 'kann diesen Filter nicht verwenden! Attribute, die sie nicht sehen können, sind:',
    },
    projectACL: {
        en: 'Project ACL',
        sk: 'Projektové práva',
        cz: 'Projekt ACL',
        de: 'Projekt ACL',
    },
    rightToAdd: {
        en: 'Right to add',
        sk: 'Právo pridať',
        cz: 'Právo přidat',
        de: 'Recht hinzuzufügen',
    },
    rightToWrite: {
        en: 'Right to write',
        sk: 'Právo upraviť',
        cz: 'Právo psát',
        de: 'Recht zu schreiben',
    },
    rightToRead: {
        en: 'Right to read',
        sk: 'Právo vidieť',
        cz: 'Právo číst',
        de: 'Recht zu lesen',
    },
    viewProjectNameDescription: {
        en: 'View project name & description',
        sk: 'Vidí názov a popis projektu',
        cz: 'Zobrazit název a popis projektu',
        de: 'Projektname und -beschreibung anzeigen',
    },
    projectSettings: {
        en: 'Project settings',
        sk: 'Nastavenia projektu',
        cz: 'Nastavení projektu',
        de: 'Projekt Einstellungen',
    },
    tasklist: {
        en: 'Task list',
        sk: 'Zoznam úloh',
        cz: 'Seznam úkolů',
        de: 'Aufgabenliste',
    },
    viewMyTasks: {
        en: 'View my tasks created/requested/assigned',
        sk: 'Vidí svoje úlohy vytvorené/vyžiadané/priradené',
        cz: 'Zobrazit mé vytvořené/vyžádané/přidělené úkoly',
        de: 'Meine erstellten/angeforderten/zugewiesenen Aufgaben anzeigen',
    },
    viewMyCompanyTasks: {
        en: 'View my company tasks',
        sk: 'Vidí úlohy svojej firmy',
        cz: 'Zobrazit mé firemní úkoly',
        de: 'Sehen Sie sich meine Unternehmensaufgaben an',
    },
    viewAllTasks: {
        en: 'View all tasks in this project',
        sk: 'Vidí všetky úlohy projektu',
        cz: 'Zobrazit všechny úkoly v tomto projektu',
        de: 'Alle Aufgaben in diesem Projekt anzeigen',
    },
    tasklistView: {
        en: 'Task list view',
        sk: 'Zobrazenie zoznamu úloh',
        cz: 'Zobrazení seznamu úkolů',
        de: 'Ansicht Aufgabenliste',
    },
    taskColumn: {
        en: 'Task column',
        sk: 'Trojstĺpec',
        cz: 'Sloupec úkolu',
        de: 'Aufgabenspalte',
    },
    taskTable: {
        en: 'Task table',
        sk: 'Tabuľka úloh',
        cz: 'Tabulka úkolů',
        de: 'Aufgabentabelle',
    },
    dnd: {
        en: 'Drag & Drop',
        sk: 'Drag & Drop',
        cz: 'Drag & Drop',
        de: 'Ziehen und loslassen',
    },
    calendar: {
        en: 'Calendar',
        sk: 'Kalendár',
        cz: '',
        de: '',
    },
    projectManagement: {
        en: 'Project management',
        sk: 'Manažment projektu',
        cz: 'Kalendář',
        de: 'Kalender',
    },
    statistic: {
        en: 'Statistic',
        sk: 'Štatistika',
        cz: 'Statistický',
        de: 'Statistik',
    },
    addTask: {
        en: 'Add task',
        sk: 'Pridanie úlohy',
        cz: 'Přidat úkol',
        de: 'Aufgabe hinzufügen',
    },
    addTaskRight: {
        en: 'Add Task ( title, description, attachments )',
        sk: 'Pridanie úlohy ( názov, popis, prílohy )',
        cz: 'Přidat úkol (název, popis, přílohy)',
        de: 'Aufgabe hinzufügen (Titel, Beschreibung, Anhänge)',
    },
    editTask: {
        en: 'Edit task',
        sk: 'Upravenie úlohy',
        cz: 'Upravit úkol',
        de: 'Aufgabe bearbeiten',
    },
    deleteTask: {
        en: 'Delete Task',
        sk: 'Vymazať úlohu',
        cz: 'Smazat úkol',
        de: 'Aufgabe löschen',
    },
    importantRight: {
        en: 'Mark tasks as important',
        sk: 'Označiť úlohy ako dôležité',
        cz: 'Označte úkoly jako důležité',
        de: 'Markieren Sie Aufgaben als wichtig',
    },
    taskTitleEdit: {
        en: 'Task title edit',
        sk: 'Upravovanie názvu úlohy',
        cz: 'Úprava názvu úkolu',
        de: 'Aufgabentitel bearbeiten',
    },
    changeTaskProject: {
        en: 'Change task project',
        sk: 'Zmeniť projekt úlohy',
        cz: 'Změnit projekt úkolu',
        de: 'Aufgabenprojekt ändern',
    },
    taskAttachments: {
        en: 'Task attachments',
        sk: 'Prílohy úlohy',
        cz: 'Přílohy úkolu',
        de: 'Aufgabenanhänge',
    },
    noTaskAttachments: {
        en: 'No task attachments',
        sk: 'Žiadne prílohy v úlohe',
        cz: 'Žádné přílohy úkolů',
        de: 'Keine Aufgabenanhänge',
    },
    taskSubtasksRight: {
        en: 'Task subtasks VIEW/EDIT',
        sk: 'Podúlohy VIDIEŤ/UPRAVIŤ',
        cz: 'Dílčí úkoly úkolu ZOBRAZIT/UPRAVIT',
        de: 'Task-Untertasks ANZEIGEN/BEARBEITEN',
    },
    taskWorksRight: {
        en: 'Task works VIEW/EDIT',
        sk: 'Práce úlohy VIDIEŤ/UPRAVIŤ',
        cz: 'Úkol funguje ZOBRAZIT/UPRAVIT',
        de: 'Aufgabe funktioniert ANZEIGEN/BEARBEITEN',
    },
    taskWorksAdvancedRight: {
        en: 'Task works advanced VIEW/EDIT',
        sk: 'Práce úlohy - rozšírené VIDIEŤ/UPRAVIŤ',
        cz: 'Úkol funguje pokročilé ZOBRAZIT/UPRAVIT',
        de: 'Aufgabe funktioniert erweitertes ANZEIGEN/BEARBEITEN',
    },
    taskMaterialsRight: {
        en: 'Task materials VIEW/EDIT',
        sk: 'Materiále úlohy VIDIEŤ/UPRAVIŤ',
        cz: 'Úkolové materiály ZOBRAZIT/UPRAVIT',
        de: 'Aufgabenmaterialien ANZEIGEN/BEARBEITEN',
    },
    /*  taskPausalInfoRight: {
      en: 'Task pausal info',
      sk: 'Informácie o paušále',
      cz: 'Informace o pozastavení úkolu',
      de: 'Informationen zur Aufgabenpause',
    },*/
    commentsHistory: {
        en: 'Comments & history',
        sk: 'Komentáre a história',
        cz: 'Komentáře a historie',
        de: 'Kommentare & Verlauf',
    },
    viewComments: {
        en: 'View comments',
        sk: 'Vidieť komentáre',
        cz: 'Zobrazit komentáře',
        de: 'Kommentare ansehen',
    },
    addComments: {
        en: 'Add comments',
        sk: 'Pridávať komentáre',
        cz: 'Přidejte komentáře',
        de: 'Füge Kommentare hinzu',
    },
    internalComments: {
        en: 'Internal comments',
        sk: 'Interné komentáre',
        cz: 'Interní komentáře',
        de: 'Interne Kommentare',
    },
    sendEmailsFromComments: {
        en: 'Send emails from comments',
        sk: 'Posielať e-maile z komentárov',
        cz: 'Odesílejte e-maily z komentářů',
        de: 'Senden Sie E-Mails von Kommentaren',
    },
    taskHistory: {
        en: 'Task history',
        sk: 'História úlohy',
        cz: 'Historie úkolů',
        de: 'Aufgabenverlauf',
    },
    Admin: {
        en: 'Admin',
        sk: 'Administrátor',
        cz: 'Admin',
        de: 'Administrator',
    },
    Agent: {
        en: 'Agent',
        sk: 'Agent',
        cz: 'Agent',
        de: 'Agent',
    },
    Customer: {
        en: 'Customer',
        sk: 'Zákazník',
        cz: 'Zákazník',
        de: 'Kunde',
    },
    requesterAllUsers: {
        en: 'A requester can be ALL helpdesk users',
        sk: 'Žiadateľ môžu byť VŠETCI používatelia',
        cz: 'Žadatelem mohou být VŠICHNI uživatelé helpdesku',
        de: 'Ein Anforderer können ALLE Helpdesk-Benutzer sein',
    },
    requesterFromProject: {
        en: 'A requester can be only one of PROJECT users',
        sk: 'Žiadateľ môže byť len používateľ v PROJEKTE',
        cz: 'Žadatelem může být pouze jeden z uživatelů PROJECT',
        de: 'Ein Anforderer kann nur einer der PROJECT-Benutzer sein',
    },
    helpdeskSystemUser: {
        en: 'Helpdesk system user',
        sk: 'Používateľ systému Helpdesk',
        cz: 'Uživatel systému Helpdesk',
        de: 'Benutzer des Helpdesk-Systems',
    },
    projectGroup: {
        en: 'Project Group',
        sk: 'Projektová skupina',
        cz: 'Projektová skupina',
        de: 'Projektgruppe',
    },
    helpdeskAdminHaveAllRights: {
        en: 'Helpdesk users with the administrator role have all project rights.',
        sk: 'Používatelia s rolou Administrátora majú vždy všetky práva.',
        cz: 'Uživatelé helpdesku s rolí správce mají všechna projektová práva.',
        de: 'Helpdesk-Benutzer mit der Administratorrolle haben alle Projektrechte.',
    },
    addingCompanyToGroup: {
        en: 'Adding company to group',
        sk: 'Pridávanie firmy do skupiny',
        cz: 'Přidávání společnosti do skupiny',
        de: 'Unternehmen zur Gruppe hinzufügen',
    },
    addingUserToGroup: {
        en: 'Adding user to group',
        sk: 'Pridávanie používateľa do skupiny',
        cz: 'Přidávání uživatele do skupiny',
        de: 'Benutzer zur Gruppe hinzufügen',
    },
    projectFilterName: {
        en: 'Project filter name',
        sk: 'Názov projektového filtra',
        cz: 'Název filtru projektu',
        de: 'Name des Projektfilters',
    },
    projectFilter: {
        en: 'Project filter',
        sk: 'Projektový filter',
        cz: 'Filtr projektu',
        de: 'Projektfilter',
    },
    cantUseProjectFilter1: {
        en: 'Group',
        sk: 'Skupina',
        cz: 'Skupina',
        de: 'Gruppe',
    },
    cantUseProjectFilter2: {
        en: "can't use this filter! Attributes that they can't see are",
        sk: 'nemôže používať tento filter! Polia, ktoré nemôžu vidieť sú',
        cz: 'tento filtr nelze použít! Atributy, které nevidí, jsou',
        de: 'kann diesen Filter nicht verwenden! Attribute, die sie nicht sehen können, sind',
    },
    filterOrder: {
        en: 'Filter order',
        sk: 'Poradie filtra',
        cz: 'Pořadí filtrů',
        de: 'Reihenfolge filtern',
    },
    filterDescription: {
        en: 'Filter description',
        sk: 'Popis filtra',
        cz: 'Popis filtru',
        de: 'Filterbeschreibung',
    },
    chooseGroups: {
        en: 'Choose groups',
        sk: 'Vyberte skupiny',
        cz: 'Vyberte skupiny',
        de: 'Gruppen auswählen',
    },
    filterAttributes: {
        en: 'Filter attributes',
        sk: 'Polia filtra',
        cz: 'Filtrovat atributy',
        de: 'Attribute filtern',
    },
    groupTitle: {
        en: 'Group name',
        sk: 'Názov skupiny',
        cz: 'Skupinové jméno',
        de: 'Gruppenname',
    },
    groupTitlePlaceholder: {
        en: 'Enter groups name',
        sk: 'Zadajte názov skupiny',
        cz: 'Zadejte název skupiny',
        de: 'Geben Sie den Gruppennamen ein',
    },
    groupDescription: {
        en: 'Group description',
        sk: 'Popis skupiny',
        cz: 'Popis skupiny',
        de: 'Gruppenbeschreibung',
    },
    groupDescriptionPlaceholder: {
        en: 'Enter group description',
        sk: 'Zadajte popis skupiny',
        cz: 'Zadejte popis skupiny',
        de: 'Gruppenbeschreibung eingeben',
    },
    orderPlaceholder: {
        en: 'Set order',
        sk: 'Zadajte poradie',
        cz: 'Nastavte pořadí',
        de: 'Reihenfolge festlegen',
    },
    restore: {
        en: 'Restore',
        sk: 'Obnoviť',
        cz: 'Obnovit',
        de: 'Wiederherstellen',
    },
    update: {
        en: 'Update',
        sk: 'Aktualizovať',
        cz: 'Aktualizace',
        de: 'Aktualisieren',
    },
    group2: {
        en: 'Group',
        sk: 'Skupinu',
        cz: 'Skupina',
        de: 'Gruppe',
    },
    defaultValue: {
        en: 'Default value',
        sk: 'Prednastavená hodnota',
        cz: 'Výchozí hodnota',
        de: 'Standardwert',
    },
    noDefaultDate: {
        en: 'No default date',
        sk: 'Bez prednast. dátumu',
        cz: 'Žádné výchozí datum',
        de: 'Kein Standarddatum',
    },
    projectUserACL: {
        en: 'Project user group ACL',
        sk: 'Práva používateľskej skupiny',
        cz: 'ACL skupiny uživatelů projektu',
        de: 'Projektbenutzergruppe ACL',
    },
    required: {
        en: 'Required',
        sk: 'Povinné',
        cz: 'Požadované',
        de: 'Erforderlich',
    },
    fixed: {
        en: 'Fixed',
        sk: 'Nemeniteľné',
        cz: 'Pevný',
        de: 'Fest',
    },
    emptyUserValue: {
        en: 'Task Creator',
        sk: 'Tvorca úlohy',
        cz: 'Tvůrce úkolů',
        de: 'Aufgabenersteller',
    },
    emptyCompanyValue: {
        en: "Task Creator's company",
        sk: 'Firma tvorcu úlohy',
        cz: 'Společnost Tvůrce úkolů',
        de: 'Das Unternehmen des Aufgabenerstellers',
    },
    emptyAssigned: {
        en: 'Unassigned/Requester if can be assigned',
        sk: 'Nepriradený/Zadávateľ má assigned edit právo',
        cz: 'Nepřiřazeno/Žadatel, pokud lze přiřadit',
        de: 'Nicht zugewiesen/Anforderer, falls zugewiesen werden kann',
    },
    emptyStatus: {
        en: 'New (first with new action)',
        sk: 'Nový (prvý status s akciou "Nová")',
        cz: 'Nové (první s novou akcí)',
        de: 'Neu (zuerst mit neuer Aktion)',
    },
    areYouSure: {
        en: 'Are you sure?',
        sk: 'Ste si istý?',
        cz: 'Jsi si jistá?',
        de: 'Bist du dir sicher?',
    },
    text: {
        en: 'Text',
        sk: 'Text',
        cz: 'Text',
        de: 'Text',
    },
    textarea: {
        en: 'Textarea',
        sk: 'Textové pole',
        cz: 'Textarea',
        de: 'Textbereich',
    },
    multiselect: {
        en: 'Select multiple',
        sk: 'Výber viacerých',
        cz: 'Vyberte více',
        de: 'Mehrere auswählen',
    },
    selectNoun: {
        en: 'Select',
        sk: 'Výber',
        cz: 'Vybrat',
        de: 'Auswählen',
    },
    /*emptyPausal: {
    en: 'Set by tasks company',
    sk: 'Nastavený podľa firmy',
    cz: 'Nastaveno podle úkolů společnosti',
    de: 'Vom Aufgabenunternehmen festgelegt',
  },*/
    orderIsNotNumber: {
        en: 'Order is not a number!',
        sk: 'Poradie položky nie je číslo!',
        cz: 'Pořadí položky není číslo!',
        de: 'Die Reihenfolge eines Artikels ist keine Zahl!',
    },
    titleIsRequired: {
        en: 'Title is required!',
        sk: 'Názov je povinný!',
        cz: 'Název je povinen!',
        de: 'Name ist erforderlich!',
    },
    typeIsRequired: {
        en: 'Type is required!',
        sk: 'Typ je povinný!',
        cz: 'Typ je povinný!',
        de: 'Typ ist erforderlich!',
    },
    optionIsRequired: {
        en: 'This custom attribute needs at least one option to choose from if it is required!',
        sk: 'Tento vlastný atribút potrebuje na výber aspoň jednu možnosť, ak je potrebný!',
        cz: 'Tento vlastní atribut vyžaduje alespoň jednu možnost na výběr, pokud je vyžadován!',
        de: 'Dieses benutzerdefinierte Attribut benötigt mindestens eine Option zur Auswahl, wenn es erforderlich ist!',
    },
    readIsRequired: {
        en: 'At least one user needs the right to read!',
        sk: 'Aspoň jeden používateľ potrebuje právo čítať!',
        cz: 'Alespoň jeden uživatel potřebuje právo číst!',
        de: 'Mindestens ein Benutzer benötigt Leserechte!',
    },
};
