import React, {
  useMemo
} from 'react';

import AutomationForm from './form';

import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import {
  useQuery,
} from "@apollo/client";
import {
  filterUnique,
  toSelArr
} from 'helperFunctions';

import {
  GET_MY_PROJECTS,
} from 'helpdesk/settings/projects/queries';

import {
  useTranslation
} from "react-i18next";

export default function EditTimerAutomation( props ) {
  const {
    open,
    updateAutomationTimer,
    automationTimer,
    closeModal,
    allUsers,
    userGroups,
    statuses,
    tags,
    lockedRequester,
    companies,
    customAttributes,
  } = props;

  const {
    t
  } = useTranslation();

  const {
    data: myProjectsData,
    loading: myProjectsLoading,
    refetch: myProjectsRefetch,
  } = useQuery( GET_MY_PROJECTS, {
    fetchPolicy: 'network-only'
  } );

  const projects = useMemo(() => {
    if (myProjectsData){
      return toSelArr(myProjectsData.myProjects.map((project) => project.project));
    }
    return [];
  }, [myProjectsData]);

  const cantSave = false;

  return (
    <Modal isOpen={open}>
      <ModalHeader>
        {t('editTimerAutomation')}
      </ModalHeader>
      <ModalBody>

        <AutomationForm
          onSubmit={updateAutomationTimer}
          onCancel={closeModal}
          closeModal={closeModal}
          automationTimer={automationTimer}
          cantSave={cantSave}
          allUsers={allUsers}
          userGroups={userGroups}
          statuses={statuses}
          tags={tags}
          projects={projects}
          lockedRequester={lockedRequester}
          companies={companies}
          customAttributes={customAttributes}
          />

      </ModalBody>
    </Modal>
  );

}
