import React from 'react';
import { useQuery, useSubscription } from '@apollo/client';

import { useNavigate, useParams } from 'react-router-dom';

import CategoryAdd from 'cmdb/categories/add/modalButton';
import CategoryEdit from 'cmdb/categories/edit/modalButton';
import ItemAdd from 'cmdb/items/add';

import { NavItem, Nav, Label } from 'reactstrap';
import Empty from 'components/Empty';
import Loading from 'components/loading';

import Select from 'react-select';
import { pickSelectStyle } from 'configs/components/select';

import folderIcon from 'scss/icons/folder.svg';
import filterIcon from 'scss/icons/filter.svg';

import classnames from 'classnames';

import {
    setCmdbSidebarCompany,
    setCmdbSidebarCategory,
} from 'apollo/localSchema/actions';

import {
    CMDB_SIDEBAR_COMPANY,
    CMDB_SIDEBAR_CATEGORY,
} from 'apollo/localSchema/queries';

import {
    GET_BASIC_COMPANIES,
    COMPANIES_SUBSCRIPTION,
} from 'helpdesk/settings/companies/queries';

import {
    GET_CATEGORIES,
    CATEGORIES_SUBSCRIPTION,
} from 'cmdb/categories/queries';

import {
    toSelItem,
    toSelArr,
    translateSelectItem,
} from 'helperFunctions';

import { useTranslation } from 'react-i18next';

const allCompanies = {
    id: null,
    value: null,
    title: 'allCompanies',
    label: 'allCompanies',
};

export default function Sidebar() {
    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    const { data: companyData } = useQuery(
        CMDB_SIDEBAR_COMPANY
    );
    const { data: categoryData } = useQuery(
        CMDB_SIDEBAR_CATEGORY
    );

    const {
        data: companiesData,
        loading: companiesLoading,
        refetch: companiesRefetch,
    } = useQuery(GET_BASIC_COMPANIES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(COMPANIES_SUBSCRIPTION, {
        onData: () => {
            companiesRefetch();
        },
    });

    const {
        data: categoriesData,
        loading: categoriesLoading,
        refetch: categoriesRefetch,
    } = useQuery(GET_CATEGORIES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(CATEGORIES_SUBSCRIPTION, {
        onData: () => {
            categoriesRefetch();
        },
    });

    const [showCategories, setShowCategories] =
        React.useState(true);

    const company = companyData.cmdbSidebarCompany;
    const category = categoryData.cmdbSidebarCategory;

    React.useEffect(() => {
        if (
            !categoriesLoading &&
            params.categoryID !== undefined
        ) {
            setCmdbSidebarCategory(
                params.categoryID === 'all'
                    ? null
                    : toSelItem(
                          categoriesData.cmdbCategories.find(
                              (category) =>
                                  category.id ===
                                  parseInt(
                                      params.categoryID
                                  )
                          )
                      )
            );
        }
    }, [params.categoryID, categoriesLoading]);

    React.useEffect(() => {
        if (
            !companiesLoading &&
            params.companyID !== undefined
        ) {
            setCmdbSidebarCompany(
                params.companyID === 'all'
                    ? allCompanies
                    : toSelItem(
                          companiesData.basicCompanies.find(
                              (company) =>
                                  company.id ===
                                  parseInt(params.companyID)
                          )
                      )
            );
        }
    }, [params.companyID, companiesLoading]);

    if (companiesLoading || categoriesLoading) {
        return (
            <div className="sidebar">
                <Loading />
            </div>
        );
    }

    const companies = companiesData.basicCompanies;
    const categories = categoriesData.cmdbCategories;

    return (
        <div className="sidebar">
            <div className="scrollable fit-with-header">
                <ItemAdd
                    companyId={company ? company.id : null}
                    categoryId={
                        category ? category.id : null
                    }
                />
                <hr className="m-l-15 m-r-15 m-t-15" />
                <div>
                    <div className="sidebar-label row">
                        <div>
                            <img
                                className="m-r-9"
                                src={folderIcon}
                                alt="Folder icon not found"
                            />
                            <Label>{t('company')}</Label>
                        </div>
                    </div>
                    <div className="sidebar-menu-item">
                        <Select
                            options={[
                                translateSelectItem(
                                    allCompanies,
                                    t
                                ),
                                ...toSelArr(companies),
                            ]}
                            value={translateSelectItem(
                                company,
                                t
                            )}
                            styles={pickSelectStyle([
                                'invisible',
                                'blueFont',
                                'sidebar',
                                'flex',
                                'inputSize',
                                'noPadding',
                            ])}
                            onChange={(company) => {
                                setCmdbSidebarCompany(
                                    company
                                );
                                if (
                                    window.location.pathname.includes(
                                        '/cmdb/manuals/'
                                    )
                                ) {
                                    navigate(
                                        `/cmdb/manuals/${
                                            company.id ===
                                            null
                                                ? 'all'
                                                : company.id
                                        }`
                                    );
                                } else if (
                                    window.location.pathname.includes(
                                        '/cmdb/passwords/'
                                    )
                                ) {
                                    navigate(
                                        `/cmdb/passwords/${
                                            company.id ===
                                            null
                                                ? 'all'
                                                : company.id
                                        }`
                                    );
                                } else if (
                                    !window.location.pathname.includes(
                                        '/cmdb/repeats/'
                                    )
                                ) {
                                    navigate(`/cmdb/i/all`);
                                }
                            }}
                        />
                    </div>
                </div>
                <Nav vertical className="m-t-10">
                    {company.id !== null && (
                        <NavItem
                            className={classnames(
                                'row full-width sidebar-item',
                                {
                                    'active':
                                        window.location.pathname.includes(
                                            '/cmdb/scheme'
                                        ),
                                }
                            )}
                        >
                            <span
                                className={classnames(
                                    'clickable sidebar-menu-item link',
                                    {
                                        'active':
                                            window.location.pathname.includes(
                                                '/cmdb/scheme'
                                            ),
                                    }
                                )}
                                onClick={() => {
                                    navigate(
                                        `/cmdb/scheme/${company.id}`
                                    );
                                }}
                            >
                                {t('scheme')}
                            </span>
                        </NavItem>
                    )}
                    <NavItem
                        className={classnames(
                            'row full-width sidebar-item',
                            {
                                'active':
                                    window.location.pathname.includes(
                                        '/cmdb/manuals'
                                    ),
                            }
                        )}
                    >
                        <span
                            className={classnames(
                                'clickable sidebar-menu-item link',
                                {
                                    'active':
                                        window.location.pathname.includes(
                                            '/cmdb/manuals'
                                        ),
                                }
                            )}
                            onClick={() => {
                                navigate(
                                    `/cmdb/manuals/${
                                        company.id
                                            ? company.id
                                            : 'all'
                                    }`
                                );
                            }}
                        >
                            {t('manuals')}
                        </span>
                    </NavItem>
                    {false && (
                        <NavItem
                            className={classnames(
                                'row full-width sidebar-item',
                                {
                                    'active':
                                        window.location.pathname.includes(
                                            '/cmdb/passwords'
                                        ),
                                }
                            )}
                        >
                            <span
                                className={classnames(
                                    'clickable sidebar-menu-item link',
                                    {
                                        'active':
                                            window.location.pathname.includes(
                                                '/cmdb/passwords'
                                            ),
                                    }
                                )}
                                onClick={() => {
                                    navigate(
                                        `/cmdb/passwords/${
                                            company.id
                                                ? company.id
                                                : 'all'
                                        }`
                                    );
                                }}
                            >
                                {t('passwords')}
                            </span>
                        </NavItem>
                    )}
                </Nav>
                <hr className="m-l-15 m-r-15 m-t-15" />

                <Nav vertical>
                    <div
                        className="sidebar-label row clickable noselect"
                        onClick={() =>
                            setShowCategories(
                                !showCategories
                            )
                        }
                    >
                        <div>
                            <img
                                className="m-r-5"
                                style={{
                                    color: '#212121',
                                    height: '17px',
                                    marginBottom: '3px',
                                }}
                                src={filterIcon}
                                alt="Filter icon not found"
                            />
                            <Label className="clickable">
                                {t('categories')}
                            </Label>
                        </div>
                        <div className="ml-auto m-r-3">
                            {showCategories && (
                                <i className="fas fa-chevron-up" />
                            )}
                            {!showCategories && (
                                <i className="fas fa-chevron-down" />
                            )}
                        </div>
                    </div>
                    {showCategories && (
                        <Empty>
                            <NavItem
                                className={classnames(
                                    'row full-width sidebar-item',
                                    {
                                        'active':
                                            category ===
                                                null &&
                                            !window.location.pathname.includes(
                                                `repeats`
                                            ) &&
                                            !window.location.pathname.includes(
                                                `manuals`
                                            ) &&
                                            !window.location.pathname.includes(
                                                `scheme`
                                            ),
                                    }
                                )}
                            >
                                <span
                                    className={classnames(
                                        'clickable sidebar-menu-item link',
                                        {
                                            'active':
                                                category ===
                                                    null &&
                                                !window.location.pathname.includes(
                                                    `repeats`
                                                ) &&
                                                !window.location.pathname.includes(
                                                    `manuals`
                                                ) &&
                                                !window.location.pathname.includes(
                                                    `scheme`
                                                ),
                                        }
                                    )}
                                    onClick={() => {
                                        navigate(
                                            `/cmdb/i/all`
                                        );
                                    }}
                                >
                                    {t('allCategories')}
                                </span>
                            </NavItem>
                            {categories.map(
                                (sidebarCategory) => (
                                    <NavItem
                                        key={
                                            sidebarCategory.id
                                        }
                                        className={classnames(
                                            'row full-width sidebar-item',
                                            {
                                                'active':
                                                    category !==
                                                        null &&
                                                    category.id ===
                                                        sidebarCategory.id &&
                                                    !window.location.pathname.includes(
                                                        `repeats`
                                                    ) &&
                                                    !window.location.pathname.includes(
                                                        `manuals`
                                                    ) &&
                                                    !window.location.pathname.includes(
                                                        `scheme`
                                                    ),
                                            }
                                        )}
                                    >
                                        <span
                                            className={classnames(
                                                'clickable sidebar-menu-item link',
                                                {
                                                    'active':
                                                        category !==
                                                            null &&
                                                        category.id ===
                                                            sidebarCategory.id &&
                                                        !window.location.pathname.includes(
                                                            `repeats`
                                                        ) &&
                                                        !window.location.pathname.includes(
                                                            `manuals`
                                                        ) &&
                                                        !window.location.pathname.includes(
                                                            `scheme`
                                                        ),
                                                }
                                            )}
                                            onClick={() => {
                                                navigate(
                                                    `/cmdb/i/${sidebarCategory.id}`
                                                );
                                            }}
                                        >
                                            {
                                                sidebarCategory.title
                                            }
                                        </span>
                                        {category !==
                                            null &&
                                            sidebarCategory.id ===
                                                category.id &&
                                            !window.location.pathname.includes(
                                                `repeats`
                                            ) &&
                                            !window.location.pathname.includes(
                                                `manuals`
                                            ) &&
                                            !window.location.pathname.includes(
                                                `scheme`
                                            ) && (
                                                <CategoryEdit
                                                    id={
                                                        sidebarCategory.id
                                                    }
                                                    categories={
                                                        categories
                                                    }
                                                />
                                            )}
                                    </NavItem>
                                )
                            )}
                        </Empty>
                    )}
                </Nav>
                {showCategories && <CategoryAdd />}
                <hr className="m-l-15 m-r-15 m-t-15" />
                <Nav vertical>
                    <NavItem
                        className={classnames(
                            'row full-width sidebar-item',
                            {
                                'active':
                                    window.location.pathname.includes(
                                        `cmdb/repeats/i/${
                                            category
                                                ? category.id
                                                : 'all'
                                        }`
                                    ),
                            }
                        )}
                    >
                        <span
                            className={classnames(
                                'clickable sidebar-menu-item link',
                                {
                                    'active':
                                        window.location.pathname.includes(
                                            `cmdb/repeats/i/${
                                                category
                                                    ? category.id
                                                    : 'all'
                                            }`
                                        ),
                                }
                            )}
                            onClick={() => {
                                navigate(
                                    `/cmdb/repeats/i/${
                                        category
                                            ? category.id
                                            : 'all'
                                    }`
                                );
                            }}
                        >
                            {t('repetitiveTasks')}
                        </span>
                    </NavItem>
                </Nav>
            </div>
        </div>
    );
}
