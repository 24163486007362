export default {
  allFolders: {
    en: 'All folders',
    sk: 'Všetky priečinky',
    cz: 'Všechny složky',
    de: 'Alle Ordner',
  },
  folder: {
    en: 'Folder',
    sk: 'Priečinok',
    cz: 'Složka',
    de: 'Mappe',
  },
  folders: {
    en: 'Folders',
    sk: 'Priečinky',
    cz: 'Složky',
    de: 'Ordner',
  },
  userRights: {
    en: 'User rights',
    sk: 'Práva používateľov',
    cz: 'Uživatelská práva',
    de: 'Nutzerrechte',
  },
  write: {
    en: 'Write',
    sk: 'Upravovať',
    cz: 'Napsat',
    de: 'Schreiben',
  },
  manage: {
    en: 'Manage',
    sk: 'Spravovať',
    cz: 'Spravovat',
    de: 'Verwalten',
  },
  replacement: {
    en: 'Replacement',
    sk: 'Náhrada',
    cz: 'Výměna, nahrazení',
    de: 'Ersatz',
  },
}
