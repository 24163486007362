import React from 'react';

import { useNavigate } from 'react-router-dom';

import classnames from 'classnames';

import { Button } from 'reactstrap';

import { useTranslation } from 'react-i18next';

export default function ActiveSearch(props) {
    const {
        loading,
        itemsRefetch,
        globalStringFilter,
        clearLocalStringFilter,
        setGlobalStringFilter,
    } = props;

    const navigate = useNavigate();

    const { t } = useTranslation();

    if (
        globalStringFilter === null ||
        Object.keys(globalStringFilter).filter(
            (filterKey) =>
                (filterKey === 'active' &&
                    globalStringFilter[filterKey] !==
                        null) ||
                (filterKey !== 'active' &&
                    globalStringFilter[filterKey].length !==
                        0)
        ).length === 0
    ) {
        return null;
    }

    const clearFilter = () => {
        clearLocalStringFilter();
        setGlobalStringFilter();
    };

    let usedFilter = [];
    if (globalStringFilter !== null) {
        usedFilter = [
            ...usedFilter,
            ...Object.keys(globalStringFilter)
                .filter(
                    (filterKey) =>
                        (filterKey === 'active' &&
                            globalStringFilter[
                                filterKey
                            ] !== null) ||
                        (filterKey !== 'active' &&
                            globalStringFilter[filterKey]
                                .length !== 0)
                )
                .map((filterKey) => {
                    if (filterKey === 'active') {
                        return globalStringFilter[filterKey]
                            ? t('active2')
                            : t('inactive2');
                    }
                    return `${t(filterKey)}: ${
                        globalStringFilter[filterKey]
                    }`;
                }),
        ];
    }

    return (
        <tr style={{ backgroundColor: 'inherit' }}>
            <td colSpan="100">
                <div className={classnames('search-row')}>
                    <span className="center-hor m-l-5 font-14">
                        <span className="bolder m-r-5">
                            {t('searchedPhrases')}:
                        </span>
                        {usedFilter.join(', ')}
                    </span>

                    <Button
                        disabled={loading}
                        className="btn center-hor m-l-10"
                        onClick={clearFilter}
                    >
                        <i className="fa fa-times" />
                        {t('clearSearch')}
                    </Button>
                    <Button
                        disabled={loading}
                        className="btn center-hor m-l-10"
                        onClick={itemsRefetch}
                    >
                        <i className="fa fa-redo-alt" />
                        {t('repeatSearch')}
                    </Button>
                    <Button
                        className="btn center-hor m-l-10"
                        disabled={loading}
                        onClick={() => {
                            navigate(`/cmdb/i/all`);
                        }}
                    >
                        {t('globalSearch')}
                    </Button>
                </div>
            </td>
        </tr>
    );
}
