import React, {
  useMemo
} from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import {
  pickSelectStyle,
} from 'configs/components/select';
import Empty from 'components/Empty';
import DatePicker from 'components/DatePicker';

import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import {
  useQuery,
} from "@apollo/client";
import {
  filterUnique,
  toSelArr,
  translateSelectItem,
  translateAllSelectItems,
} from 'helperFunctions';

import Checkbox from 'components/checkbox';

import {
  newSourceOptions,
  conditionOptions,
  anyOption,
  requesterOption,
  assignedOption,
  emptyOption,
  currentUserOption,
  completionOptions,
  creatorOption,
  boolOptions,
  overdueOption,
  currentProjectOption,
  settingsForCustomAttributes,
} from 'configs/constants/automation';

import {
  GET_MY_PROJECTS,
} from 'helpdesk/settings/projects/queries';

import {
  useTranslation
} from "react-i18next";

export default function ConditionTableRow(props) {
  const {
    allUsers,
    userGroups,
    statuses,
    tags,
    lockedRequester,
    companies,
    projects,
    group,
    onChangeSource,
    onChangeCondition,
    onChangeTarget,
    index,
    orGroupIndex,
    orGroupLength,
    conditions,
    setConditions,
    customAttributes,
  } = props;

  const {
    t
  } = useTranslation();

  const translatedNewSourceOptions = translateAllSelectItems(newSourceOptions, t, "value", "value");
  const translatedConditionOptions = translateAllSelectItems(conditionOptions, t, "translationKey");
  const translatedAnyOptions = translateSelectItem(anyOption, t, "translationKey");
  const translatedRequesterOption = translateSelectItem(requesterOption, t, "translationKey");
  const translatedAssignedOption = translateSelectItem(assignedOption, t, "translationKey");
  const translatedEmptyOption = translateSelectItem(emptyOption, t, "translationKey");
  const translatedCurrentUserOption = translateSelectItem(currentUserOption, t, "translationKey");
  const translatedCreatorOption = translateSelectItem(creatorOption, t, "translationKey");
  const translatedBoolOptions = translateAllSelectItems(boolOptions, t, "translationKey");
  const translatedCompletionOptions = translateAllSelectItems(completionOptions, t, "translationKey");
  const translatedOverdueOption = translateSelectItem(overdueOption, t, "translationKey");
  const translatedCurrentProjectOption = translateSelectItem(currentProjectOption, t, "translationKey");

  const getTargetOptions = (source, condition, isAction) => {
    if (!source) {
      return [];
    }

    if (source.type) {
      return [
        anyOption,
        ...source.selectValues.map((value) => ({
          ...value,
          label: value.value.substring(0, 1).toUpperCase() + value.value.substring(1),
        }))
      ];
    }

    switch (source.value) {
      case "task":
        return [
          translatedAnyOptions,
          translatedRequesterOption,
          translatedAssignedOption,
          ...userGroups.map((group) => ({
            label: group.user.fullName,
            value: group.user.id
          }))
        ];
      case "important":
        return translatedBoolOptions;
      case 'status':
        return getTargetOptionsForStatus(condition);
      case 'tags':
        return [
          translatedEmptyOption,
          translatedAnyOptions,
          ...toSelArr(tags).map((tag) => ({
            ...tag,
            id: null
          }))
        ];
      case 'assignedTo':
        return [
          translatedEmptyOption,
          translatedAnyOptions,
          ...userGroups.map((group) => ({
            label: group.user.fullName,
            value: group.user.id
          }))
        ];
      case 'requester':
        return [
          translatedAnyOptions,
          ...(lockedRequester ? userGroups.map((group) => ({
            label: group.user.fullName,
            value: group.user.id
          })) : toSelArr(allUsers, 'fullName'))
        ];
      case 'company':
        return [
          translatedAnyOptions,
          ...companies
        ];
      case 'attachment':
        return [
          translatedAnyOptions,
          translatedRequesterOption,
          translatedAssignedOption,
          ...userGroups.map((group) => ({
            label: group.user.fullName,
            value: group.user.id
          }))
        ];
      case 'subtask':
      case 'shortSubtask':
      case 'material':
      case 'repeat':
        return getTargetOptionsForSubtask(condition, isAction);
      case 'project':
        return [
          translatedCurrentProjectOption
        ];
      case 'comment':
        return [
          translatedAnyOptions,
          translatedRequesterOption,
          translatedAssignedOption,
          ...userGroups.map((group) => ({
            label: group.user.fullName,
            value: group.user.id
          }))
        ];
      case 'email':
      case 'notification':
        return [
          translatedCreatorOption,
          translatedRequesterOption,
          translatedAssignedOption,
          ...userGroups.map((group) => ({
            label: group.user.fullName,
            value: group.user.id
          }))
        ];
      case 'pendingChangeable':
        return translatedBoolOptions;
      case 'deadline':
        return [translatedOverdueOption];
      default:
        return [];
    }
  }

  const getTargetOptionsForStatus = (condition, isAction) => {
    if (!condition) {
      return [];
    }
    switch (condition.value) {
      case "setTo":
      case 'unsetFrom':
        return [
          translatedAnyOptions,
          ...toSelArr(statuses).map((status) => ({
            ...status,
            id: null
          }))
        ];
      case 'hasAction':
        return [
          ...toSelArr(statuses, 'action').map((status) => ({
            ...status,
            id: null
          }))
        ];
      default:
        return [];
    }
  }

  const getTargetOptionsForSubtask = (condition) => {
    if (!condition) {
      return [];
    }
    switch (condition.value) {
      case "setTo":
      case 'unsetFrom':
        return completionOptions;
      default:
        return [
          translatedAnyOptions,
          translatedRequesterOption,
          translatedAssignedOption,
          ...userGroups.map((group) => ({
            label: group.user.fullName,
            value: group.user.id
          }))
        ];
    }
  }

  const getConditionOptions = (source, condition) => {
    if (!source) {
      return [];
    }
    const possibleOptions = translatedConditionOptions.filter((option) =>
      allSourceOptions.find((s) => {
        if (s.type) {
          return s.type === source.type ? s : null;
        }
        return s.value === source.value ? s : null;
      }).inEvent.conditions.includes(option.value)
    ).filter((option) => {
      if (['setTo', 'unsetFrom', 'areSetTo', 'areUnsetFrom'].includes(option.value) && ['setTo', 'unsetFrom', 'areSetTo', 'areUnsetFrom'].includes(condition.value)) {
        return true;
      }
      if (['before', 'after'].includes(option.value) && ['before', 'after'].includes(condition.value)) {
        return true;
      }
      if (['addedBy', 'editedBy', 'deletedBy'].includes(option.value) && ['addedBy', 'editedBy', 'deletedBy'].includes(condition.value)) {
        return true;
      }
      return false;
    });
    return possibleOptions;
  }

  const fillCondition = (condition, newSource) => {
    if (condition.source &&
      condition.condition &&
      newSource.inEvent.conditions.includes(condition.condition.value)) {
      return condition.condition;
    }
    if (newSource.inEvent.conditions.length === 1) {
      return translatedConditionOptions.find((option) =>
        newSource.inEvent.conditions[0] === option.value
      );
    }
    return null;
  }

  const fillTarget = (condition, newCondition) => {
    if (condition.condition && ["areSet", "areUnset", "setTo", "unsetFrom"].includes(condition.condition.value) && ["areSet", "areUnset", "setTo", "unsetFrom"].includes(newCondition.value)) {
      return condition.target;
    }
    return null;
  }

  const handleTargetChange = (newTarget, oldTarget) => {
    let newTargetHasAny = newTarget && Array.isArray(newTarget) ? newTarget.find((option) => option.value === "any") : false;
    let oldTargetHasAny = oldTarget && Array.isArray(oldTarget) ? oldTarget.find((option) => option.value === "any") : false;
    if (newTargetHasAny && !oldTargetHasAny) {
      return [translatedAnyOptions];
    }
    if (newTargetHasAny && oldTargetHasAny && newTarget.length > 1) {
      return newTarget.filter((option) => option.value !== "any");
    }
    if (Array.isArray(newTarget)) {
      return newTarget;
    }
    return [newTarget];
  }

  const mappedCustomAttributes = useMemo(() => {
    let mappedCustomAttributes = toSelArr(customAttributes);
    mappedCustomAttributes = mappedCustomAttributes.map((attribute) => ({
      ...attribute,
      ...settingsForCustomAttributes.find((setting) => setting.type.substring("customAttribute".length).toLowerCase() === (attribute.type.value ? attribute.type.value : attribute.type)),
    }));
    return mappedCustomAttributes;
  }, [customAttributes]);

  const allSourceOptions = useMemo(() => {
    return [...translatedNewSourceOptions, ...mappedCustomAttributes];
  }, [translatedNewSourceOptions, mappedCustomAttributes]);

  const renderValueInput = () => {
      if (!group.source || !group.condition) {
        return;
      }
      switch (group.source.inEvent.valueInputTypes[group.condition.value]) {
        case 'select':
          return (
            <Select
            styles={pickSelectStyle(['size16'])}
            options={getTargetOptions(group.source, group.condition)}
            value={group.target}
            onChange={target => {
              const newConditions = conditions.map((orGr, orGrIndex) => {
                if (orGrIndex === orGroupIndex) {
                  return orGr.map((g, i) => {
                    if (i === index) {
                      return {
                        ...g,
                        target: handleTargetChange(target, g.target),
                      }
                    }
                    return g;
                  })
                }
                return orGr;
              });
              setConditions(newConditions);
            }}
          />
        )
      case 'multiselect':
        return (
          <Select
            styles={pickSelectStyle(['size16'])}
            options={getTargetOptions(group.source, group.condition)}
            isMulti
            value={group.target}
            onChange={target => {
              const newConditions = conditions.map((orGr, orGrIndex) => {
                if (orGrIndex === orGroupIndex) {
                  return orGr.map((g, i) => {
                    if (i === index) {
                      return {
                        ...g,
                        target: handleTargetChange(target, g.target),
                      }
                    }
                    return g;
                  })
                }
                return orGr;
              });
              setConditions(newConditions);
            }}
          />
        )
      case 'date':
        return (
          <DatePicker
            className={classnames("form-control")}
            selected={group.target ? group.target[0].value : group.target}
            hideTime
            isClearable
            onChange={date => {
              const newConditions = conditions.map((orGr, orGrIndex) => {
                if (orGrIndex === orGroupIndex) {
                  return orGr.map((g, i) => {
                    if (i === index) {
                      return {
                        ...g,
                        target: isNaN(date.valueOf()) ? null : [{ value: date }],
                      }
                    }
                    return g;
                  })
                }
                return orGr;
              });
              setConditions(newConditions);
            }}
            placeholderText={t('date')}
          />
        )
      case 'text':
        return (
          <input
            className="form-control"
            value={group.target ? group.target[0].value : group.target}
            type={"text"}
            onChange={(e) => {
              const newConditions = conditions.map((orGr, orGrIndex) => {
                if (orGrIndex === orGroupIndex) {
                  return orGr.map((g, i) => {
                    if (i === index) {
                      return {
                        ...g,
                        target: [{ value: e.target.value }],
                      }
                    }
                    return g;
                  })
                }
                return orGr;
              });
              setConditions(newConditions);
            }}
            placeholder={t('value')}
          />
        )
      case 'number':
        return (
          <input
            className="form-control"
            value={group.target ? group.target[0].value : group.target}
            type={"number"}
            onChange={(e) => {
              const newConditions = conditions.map((orGr, orGrIndex) => {
                if (orGrIndex === orGroupIndex) {
                  return orGr.map((g, i) => {
                    if (i === index) {
                      return {
                        ...g,
                        target: [{ value: e.target.value }],
                      }
                    }
                    return g;
                  })
                }
                return orGr;
              });
              setConditions(newConditions);
            }}
            placeholder={t('value')}
          />
        )
      case 'textarea':
        return (
          <textarea
            className="form-control"
            value={group.target ? group.target[0].value : group.target}
            onChange={(e) => {
              const newConditions = conditions.map((orGr, orGrIndex) => {
                if (orGrIndex === orGroupIndex) {
                  return orGr.map((g, i) => {
                    if (i === index) {
                      return {
                        ...g,
                        target: [{ value: e.target.value }],
                      }
                    }
                    return g;
                  })
                }
                return orGr;
              });
              setConditions(newConditions);
            }}
            placeholder={t('value')}
          />
        );
      default:
        return;
    }
  }

  return (
    <tr key={group.id}>
      <td style={{ fontSize: "16px" }}>{index === 0 ? t('if') : t('and2').toUpperCase()}</td>
      <td>
        <Select
          styles={pickSelectStyle(['size16'])}
          options={allSourceOptions.filter((option) => option.inEvent.isSourceOption)}
          isDisabled={group.id > -1}
          value={group.source}
          onChange={source => {
            const newConditions = conditions.map((orGr, orGrIndex) => {
              if (orGrIndex === orGroupIndex) {
                return orGr.map((g, i) => {
                  if (i === index) {
                    return {
                      ...g,
                      source,
                      condition: fillCondition(g, source),
                      target: null,
                    }
                  }
                  return g;
                });
              }
              return orGr;
            });
            setConditions(newConditions);
          }}
        />
      </td>
      <td>
        <Select
          styles={pickSelectStyle(['size16'])}
          options={
            group.id > -1 ? getConditionOptions(group.source, group.condition) : (group.source ?
              translatedConditionOptions.filter((option) =>
                allSourceOptions.find((s) => s.value === group.source.value).inEvent.conditions.includes(option.value)
              ) :
              [])
          }
          value={group.condition}
          onChange={condition => {
            const newConditions = conditions.map((orGr, orGrIndex) => {
              if (orGrIndex === orGroupIndex) {
                return orGr.map((g, i) => {
                  if (i === index) {
                    return {
                      ...g,
                      condition,
                      target: group.id > -1 ? g.target : fillTarget(g, condition),
                    }
                  }
                  return g;
                })
              }
              return orGr;
            });
            setConditions(newConditions);
          }}
        />
      </td>
      <td width={"30%"}>
        {
          renderValueInput()
        }
      </td>
      <td width={'70px'} className="text-right">
        <button
          className="btn-link-red"
          onClick={(e) => {
            e.preventDefault();
            const newConditions = conditions.map((orGr, orGrIndex) => {
              if (orGrIndex === orGroupIndex) {
                return orGr.filter((gr) => gr.id !== group.id);
              }
              return orGr;
            }).filter((orGr) => orGr.length > 0);
            setConditions(newConditions);
          }}
        >
          <i className="fa fa-times" style={{ fontSize: "16px" }} />
        </button>
      </td>
    </tr>
          );
      }