import React from 'react';
import {
  Label,
  FormGroup,
  Input
} from 'reactstrap';

import {
  useTranslation
} from "react-i18next";
import {
  timestampToStringFNS
} from "helperFunctions";
import classnames from 'classnames';

export default function WebChat(props) {
  const {
    id,
    addMessage,
    setStatus,
    messages,
    conversation,
  } = props;

  const {
    t
  } = useTranslation();

  // state
  const [message, setMessage] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  /*
    useEffect(() => {
      var elem = document.getElementById('messages');
      elem.scrollTop = elem.scrollHeight;
    }, [messages]);
  */
  return (
    <div className="webchatWindow">
      <div className="fit-with-header">
        <h2 className="m-20" >
          {t('webchat')}
        </h2>

        <div id="messages" className="comments scroll-visible">
          {messages.map((message) => (
            <div
              key={message.id}
              className={classnames("comment", { 'm-l-30': message.fromCustomer, 'm-r-30': !message.fromCustomer })}
              style={{ backgroundColor: message.fromCustomer ? "#d5ebd4" : "#d3dfe8" }}
            >
              <div className="row">
                <div className="icon">
                  {
                    message.fromCustomer &&
                    <i className="fa fa-solid fa-user" />
                  }
                  {
                    !message.fromCustomer &&
                    <i className="fa fa-solid fa-headset" />
                  }
                </div>
                <div>
                  <div className="row">
                    <Label>{message.fromCustomer ? conversation.fullName : message.user.fullName}</Label>
                    <span className="ml-auto">{timestampToStringFNS(message.createdAt)}</span>
                  </div>
                  {message.message}
                </div>
              </div>
            </div>
          ))
          }
        </div>

        <FormGroup className="group m-r-38">
          <Input
            type="textarea"
            id="message"
            placeholder={t('addComment')}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </FormGroup>

        <div className="row btn-row m-r-38">
          <button
            className="btn mr-auto"
            onClick={() => {
              if (window.confirm(t('generalConfirmation'))) {
                setStatus({ variables: { id, active: !conversation.active } })
              }
            }}
          >
            {conversation.active ? t('endConversation') : t('reopenConversation')}
          </button>
          {errors.length > 0 &&
            <div className="m-b-10">
              {errors.map((error) => <p className="comment warning ml-auto">{t(error)}</p>)}
            </div>
          }
          <button
            className="btn"
            disabled={saving}
            onClick={() => {
              setSaving(true);
              let newErrors = [];
              if (message.length === 0) {
                newErrors.push('cantSendEmpty');
                setErrors(newErrors);
                setSaving(false);
                return;
              }
              addMessage({
                variables: {
                  conversationId: id,
                  message,
                },
              }).then(() => {
                setMessage('');
                setSaving(false);
              }).catch((e) => {
                console.log(e);
                setSaving(false);
              })
            }}
          >
            {t('submit')}
          </button>
        </div>

      </div>
    </div>
  )
}