export default {
    settings: {
        en: 'Settings',
        sk: 'Nastavenia',
        cz: 'Nastavení',
        de: 'Einstellungen',
    },
    users: {
        en: 'Users',
        sk: 'Používatelia',
        cz: 'Uživatelé',
        de: 'Benutzer',
    },
    companies: {
        en: 'Companies',
        sk: 'Firmy',
        cz: 'Společnosti',
        de: 'Firmen',
    },
    publicFilters: {
        en: 'Public filters',
        sk: 'Verejné filtre',
        cz: 'Veřejné filtry',
        de: 'Öffentliche Filter',
    },
    /*pausals: {
    en: 'Pausals',
    sk: 'Paušále',
    cz: 'Přestávky',
    de: 'Pausen',
  },*/
    projects: {
        en: 'Projects',
        sk: 'Projekty',
        cz: 'Projekty',
        de: 'Projekte',
    },
    statuses: {
        en: 'Statuses',
        sk: 'Statusy',
        cz: 'Stavy',
        de: 'Status',
    },
    item: {
        en: 'Item',
        sk: 'Položka',
        cz: 'Položka',
        de: 'Artikel',
    },
    /*
    pricelists: {
      en: 'Pricelists',
      sk: 'Cenníky',
      cz: 'Ceníky',
      de: 'Preisliste',
    },*/
    automation: {
        en: 'Automation',
        sk: 'Automatizácia',
        cz: 'Automatizace',
        de: 'Automatisierung',
    },
    timerAutomation: {
        en: 'Timers',
        sk: 'Timers',
        cz: 'Časovače',
        de: 'Timer',
    },
    eventAutomation: {
        en: 'Triggers',
        sk: 'Triggers',
        cz: 'Spouštěče',
        de: 'Löst aus',
    },
    addNewProccessAutomation: {
        en: 'Add new trigger',
        sk: 'Pridať nový trigger',
        cz: 'Přidat nový spouštěč',
        de: 'Neuen Auslöser hinzufügen',
    },
    editProccessAutomation: {
        en: 'Edit trigger',
        sk: 'Upraviť trigger',
        cz: 'Upravit spouštěč',
        de: 'Auslöser bearbeiten',
    },
    addNewTimerAutomation: {
        en: 'Add new timer',
        sk: 'Pridať nový timer',
        cz: 'Přidat nový časovač',
        de: 'Neuen Timer hinzufügen',
    },
    editTimerAutomation: {
        en: 'Edit timer',
        sk: 'Upraviť timer procesu',
        cz: 'Upravit časovač',
        de: 'Timer bearbeiten',
    },
    conditions: {
        en: 'Conditions',
        sk: 'Podmienky',
        cz: 'Podmínky',
        de: 'Bedingungen',
    },
    addConditionGroup: {
        en: 'Add condition group',
        sk: 'Pridať skupinu podmienok',
        cz: 'Přidat skupinu podmínek',
        de: 'Bedingungsgruppe hinzufügen',
    },
    applyCondition: {
        en: 'Apply this condition',
        sk: 'Podmienku aplikovať',
        cz: 'Použijte tuto podmínku',
        de: 'Wenden Sie diese Bedingung an',
    },
    conditionGroup: {
        en: 'Condition group',
        sk: 'Skupinu podmienok',
        cz: 'Skupina podmínek',
        de: 'Bedingungsgruppe',
    },
    conjunction: {
        en: 'Conjunction',
        sk: 'Konjunkciu',
        cz: 'Spojení',
        de: 'Verbindung',
    },
    condition: {
        en: 'Condition',
        sk: 'Podmienku',
        cz: 'Podmínka',
        de: 'Bedingung',
    },
    action: {
        en: 'Action',
        sk: 'Akciu',
        cz: 'Akce',
        de: 'Aktion',
    },
    if: {
        en: 'IF',
        sk: 'IF',
        cz: 'IF',
        de: 'IF',
    },
    or: {
        en: 'OR',
        sk: 'OR',
        cz: 'OR',
        de: 'OR',
    },
    and2: {
        en: 'AND',
        sk: 'AND',
        cz: 'AND',
        de: 'AND',
    },
    is: {
        en: 'is',
        sk: 'je',
        cz: 'je',
        de: 'ist',
    },
    isNot: {
        en: 'is not',
        sk: 'nie je',
        cz: 'není',
        de: 'ist nicht',
    },
    setTo: {
        en: 'set to',
        sk: 'nastaviť na',
        cz: 'nastaven na',
        de: 'einstellen',
    },
    in: {
        en: 'is in',
        sk: 'je v',
        cz: 'je v',
        de: 'ist in',
    },
    notIn: {
        en: 'is not in',
        sk: 'nie je v',
        cz: 'není v',
        de: 'ist nicht dabei',
    },
    isSetTo: {
        en: 'is set to',
        sk: 'je nastavený/á/é na',
        cz: 'je nastaveno na',
        de: 'ist eingestellt auf',
    },
    isUnsetFrom: {
        en: 'is unset from',
        sk: 'je prenastavený/á/é z',
        cz: 'je odstaveno z',
        de: 'ist ungesetzt von',
    },
    areSetTo: {
        en: 'are set to',
        sk: 'sú nastavený/á/é na',
        cz: 'jsou nastaveny na',
        de: 'eingestellt sind',
    },
    areUnsetFrom: {
        en: 'are unset from',
        sk: 'sú prenastavený/á/é z',
        cz: 'jsou odstaveny z',
        de: 'sind abgestellt',
    },
    sendTo: {
        en: 'send to',
        sk: 'poslať',
        cz: 'poslat komu',
        de: 'senden an',
    },
    isAddedBy: {
        en: 'is added by',
        sk: 'je pridaný/á/é používateľmi',
        cz: 'je přidáno uživatelem',
        de: 'wird hinzugefügt von',
    },
    isEditedBy: {
        en: 'is edited by',
        sk: 'je upravený/á/é používateľmi',
        cz: 'je editován uživatelem',
        de: 'wird bearbeitet von',
    },
    isDeletedBy: {
        en: 'je deleted by',
        sk: 'je zmazaný/á/é používateľmi',
        cz: 'je smazáno uživatelem',
        de: 'je gelöscht von',
    },
    isCreated: {
        en: 'is created',
        sk: 'je vytvorená',
        cz: 'je vytvořen',
        de: 'geschaffen',
    },
    isChanged: {
        en: 'is changed',
        sk: 'je zmenený',
        cz: 'se změnilo',
        de: 'ist geändert',
    },
    before: {
        en: 'is before',
        sk: 'je pred',
        cz: 'je dříve',
        de: 'ist vor',
    },
    after: {
        en: 'is after',
        sk: 'je po',
        cz: 'je po',
        de: 'ist danach',
    },
    whenCreated: {
        en: 'when task is created',
        sk: 'keď je úloha vytvorená',
        cz: 'když je úkol vytvořen',
        de: 'wenn Aufgabe erstellt wird',
    },
    whenEdited: {
        en: 'when task is updated',
        sk: 'keď je úloha zmenená',
        cz: 'při aktualizaci úkolu',
        de: 'wenn die Aufgabe aktualisiert wird',
    },
    hasAction: {
        en: 'has action',
        sk: 'má akciu',
        cz: 'má akci',
        de: 'hat Aktion',
    },
    creator: {
        en: 'Task creator',
        sk: 'Tvorca úlohy',
        cz: 'Tvůrce úkolu',
        de: 'Aufgabenersteller',
    },
    creatorCompany: {
        en: 'Task creator company',
        sk: 'Firma tvorcu úlohy',
        cz: 'Společnost pro tvorbu úkolů',
        de: 'Aufgabenersteller-Unternehmen',
    },
    open: {
        en: 'Open',
        sk: 'Otvorené',
        cz: 'Otevřeno',
        de: 'Offen',
    },
    contains: {
        en: 'contains',
        sk: 'obsahuje',
        cz: 'obsahuje',
        de: 'enthält',
    },
    addOption: {
        en: 'add',
        sk: 'pridať',
        cz: 'přidat',
        de: 'hinzufügen',
    },
    sendOption: {
        en: 'send to',
        sk: 'poslať',
        cz: 'poslat',
        de: 'senden an',
    },
    numLt: {
        en: 'is less than',
        sk: 'je menej než',
        cz: 'je méně než',
        de: 'ist weniger als',
    },
    numGt: {
        en: 'is greater than',
        sk: 'je viac než',
        cz: 'je větší než',
        de: 'ist größer als',
    },
    numEq: {
        en: 'is equal to',
        sk: 'je rovné',
        cz: 'je rovný',
        de: 'ist gleich',
    },
    numLtEq: {
        en: 'is less or equal to',
        sk: 'je menšie alebo rovné',
        cz: 'je menší nebo roven',
        de: 'kleiner oder gleich ist',
    },
    numGtEq: {
        en: 'is greater or equal to',
        sk: 'je vačšie alebo rovné',
        cz: 'je větší nebo rovno',
        de: 'größer oder gleich ist',
    },
    roles: {
        en: 'Roles',
        sk: 'Role',
        cz: 'Role',
        de: 'Rollen',
    },
    tripTypes: {
        en: 'Trip types',
        sk: 'Typy výjazdov',
        cz: 'Typy výletů',
        de: 'Reisearten',
    },
    /*
    taskTypes: {
      en: 'Task types',
      sk: 'Typy úloh',
      cz: 'Typy úkolů',
      de: 'Aufgabentypen',
    },
    */
    imaps: {
        en: 'IMAPs',
        sk: 'IMAPy',
        cz: 'IMAPy',
        de: 'IMAPs',
    },
    imap: {
        en: 'IMAP',
        sk: 'IMAP',
        cz: 'IMAP',
        de: 'IMAP',
    },
    smtps: {
        en: 'SMTPs',
        sk: 'SMTPs',
        cz: 'SMTPs',
        de: 'SMTPs',
    },
    contracted: {
        en: 'Contracted',
        sk: 'Zmluvný',
        cz: 'Smluvně',
        de: 'Vertrag abgeschlossen',
    },
    nonContracted: {
        en: 'Non-contracted',
        sk: 'Nezmluvný',
        cz: 'Nesmluvně',
        de: 'Vertraglos',
    },
    /*
    pricelist: {
      en: 'Pricelist',
      sk: 'Cenník',
      cz: 'Ceník',
      de: 'Preisliste',
    },*/
    default: {
        en: 'Default',
        sk: 'Predvolený',
        cz: 'Výchozí',
        de: 'Standard',
    },
    custom: {
        en: 'Custom',
        sk: 'Vlastný',
        cz: 'Zvyk',
        de: 'Brauch',
    },
    /*
    newPricelist: {
      en: 'New pricelist',
      sk: 'Nový cenník',
      cz: 'Nový ceník',
      de: 'Neue Preisliste',
    },
    pricelistTitle: {
      en: 'Pricelist title',
      sk: 'Názov cenníka',
      cz: 'Název ceníku',
      de: 'Titel der Preisliste',
    },
    pricelistTitlePlaceholder: {
      en: 'Enter pricelist title',
      sk: 'Zadajte názov cenníka',
      cz: 'Zadejte název ceníku',
      de: 'Geben Sie den Titel der Preisliste ein',
    },
    pricelistRedirect: {
      en: 'You will be redirected to a page where you can edit this pricelist. All unsaved progress will be lost, are you sure you want to proceed?',
      sk: 'Budete presmerovaný na stránku kde môžete upraviť cenník. Všetky neuložené zmeny budú stratené, ste si istý že chcete pokračovať?',
      cz: 'Budete přesměrováni na stránku, kde můžete tento ceník upravit. Veškerý neuložený postup bude ztracen. Opravdu chcete pokračovat?',
      de: 'Sie werden auf eine Seite weitergeleitet, auf der Sie diese Preisliste bearbeiten können. Alle nicht gespeicherten Fortschritte gehen verloren. Möchten Sie wirklich fortfahren?',
    },
    editDefaultPricelist: {
      en: 'Edit default pricelist',
      sk: 'Upraviť predvolený cenník',
      cz: 'Upravit výchozí ceník',
      de: 'Standardpreisliste bearbeiten',
    },*/
    rentsHeader: {
        en: 'Hardware & Software rents',
        sk: 'Prenájom Hardware & Software',
        cz: 'Pronájem hardwaru a softwaru',
        de: 'Mieten von Hard- und Software',
    },
    pricePerMonth: {
        en: 'Price/month',
        sk: 'Cena/mesiac',
        cz: 'Cena/měsíc',
        de: 'Preis/Monat',
    },
    constPcMonth: {
        en: 'Cost/pc/month',
        sk: 'Náklad/ks/mesiac',
        cz: 'Cena/ks/měsíc',
        de: 'Kosten/Stk/Monat',
    },
    euroWithoutTaxMonth: {
        en: 'EURO without Tax/month',
        sk: 'EUR bez DPH/mesiac',
        cz: 'EURO bez daně/měsíc',
        de: 'EURO ohne MwSt./Monat',
    },
    subtotal: {
        en: 'Sub-total',
        sk: 'Medzisúčet',
        cz: 'Mezisoučet',
        de: 'Zwischensumme',
    },
    billingInformation: {
        en: 'Billing information',
        sk: 'Faktúračné údaje',
        cz: 'Fakturační údaje',
        de: 'Abrechnungsdaten',
    },
    contract: {
        en: 'Contract',
        sk: 'Zmluva',
        cz: 'Smlouva',
        de: 'Vertrag',
    },
    companyTitle: {
        en: 'Company name',
        sk: 'Názov firmy',
        cz: 'Jméno společnosti',
        de: 'Name der Firma',
    },
    ico: {
        en: 'ICO',
        sk: 'ICO',
        cz: 'ICO',
        de: 'ICO',
    },
    dic: {
        en: 'DIC',
        sk: 'DIC',
        cz: 'DIC',
        de: 'DIC',
    },
    icDph: {
        en: 'IC DPH',
        sk: 'IC DPH',
        cz: 'IC DPH',
        de: 'IC DPH',
    },
    country: {
        en: 'Country',
        sk: 'Krajina',
        cz: 'Země',
        de: 'Land',
    },
    city: {
        en: 'City',
        sk: 'Mesto',
        cz: 'Město',
        de: 'Stadt',
    },
    street: {
        en: 'Street',
        sk: 'Ulica',
        cz: 'ulice',
        de: 'Straße',
    },
    country2: {
        en: 'Country',
        sk: 'Krajinu',
        cz: 'Zemi',
        de: 'Land',
    },
    street2: {
        en: 'Street',
        sk: 'Ulicu',
        cz: 'ulici',
        de: 'Straße',
    },
    postCode: {
        en: 'Post code',
        sk: 'PSČ',
        cz: 'PSČ',
        de: 'Postleitzahl',
    },
    emailPlaceholderChecked: {
        en: 'Enter e-mail (must be email or empty)',
        sk: 'Zadajte e-mail (buď musí byť e-mail alebo prázdne pole)',
        cz: 'Zadejte e-mail (musí být e-mail nebo prázdný)',
        de: 'E-Mail eingeben (muss email oder leer sein)',
    },
    phone: {
        en: 'Phone',
        sk: 'Telefónne číslo',
        cz: 'Telefon',
        de: 'Telefon',
    },
    phonePlaceholder: {
        en: 'Enter phone number',
        sk: 'Zadajte telefónne číslo',
        cz: 'Zadejte telefon',
        de: 'Geben Sie den telefon',
    },
    tax: {
        en: 'Tax',
        sk: 'Daň',
        cz: 'Daň',
        de: 'Steuer',
    },
    defaultTax: {
        en: 'Default tax for all companies',
        sk: 'Prednadstavená daň pre všetky firmy',
        cz: 'Výchozí daň pro všechny společnosti',
        de: 'Standardsteuer für alle Unternehmen',
    },
    /*
    monthlyPausal: {
      en: 'Monthly pausal',
      sk: 'Mesačný paušál',
      cz: 'Měsíční pauza',
      de: 'Monatliche Pause',
    },*/
    company2: {
        en: 'Company',
        sk: 'Firmu',
        cz: 'Společnost',
        de: 'Gesellschaft',
    },
    testImaps: {
        en: 'Test IMAPs',
        sk: 'Odtestovať IMAPy',
        cz: 'Testujte IMAP',
        de: 'Testen Sie IMAPs',
    },
    testImap: {
        en: 'Test IMAP',
        sk: 'Odtestovať IMAP',
        cz: 'Otestujte IMAP',
        de: 'IMAP testen',
    },
    testingImaps: {
        en: 'Testing IMAPs',
        sk: 'Testujú sa IMAPy',
        cz: 'Testování IMAP',
        de: 'Testen von IMAPs',
    },
    refetch: {
        en: 'Refetch',
        sk: 'Obnoviť',
        cz: 'Znovu načíst',
        de: 'Neu abrufen',
    },
    host: {
        en: 'Host',
        sk: 'Hostiteľ',
        cz: 'Hostitel',
        de: 'Gastgeber',
    },
    username: {
        en: 'Username',
        sk: 'Používateľské meno',
        cz: 'Uživatelské jméno',
        de: 'Nutzername',
    },
    order: {
        en: 'Order',
        sk: 'Poradie',
        cz: 'Pořadí',
        de: 'Befehl',
    },
    port: {
        en: 'Port',
        sk: 'Číslo portu',
        cz: 'Port',
        de: 'Port',
    },
    tls: {
        en: 'TLS (encryption)',
        sk: 'TLS (šifrovanie)',
        cz: 'TLS (šifrování)',
        de: 'TLS (Verschlüsselung)',
    },
    destination: {
        en: 'Destination',
        sk: 'Cieľová zložka',
        cz: 'Destinace',
        de: 'Ziel',
    },
    ignoredRecievers: {
        en: 'Ignored recievers',
        sk: 'Ignorované e-maile',
        cz: 'Ignorované přijímače',
        de: 'Ignorierte Empfänger',
    },
    ignoredDestinationPlaceholder: {
        en: 'Enter ignored e-mails destination',
        sk: 'Zadajte zložku pre ignorované e-maile',
        cz: 'Zadejte cíl ignorovaných e-mailů',
        de: 'Geben Sie das Ziel für ignorierte E-Mails ein',
    },
    rejectUnauthorized: {
        en: 'Reject unauthorized',
        sk: 'Odmietnuť neautorizované',
        cz: 'Odmítnout neoprávněně',
        de: 'Unbefugte ablehnen',
    },
    usersRole: {
        en: 'Users role',
        sk: 'Používateľová rola',
        cz: 'Role uživatelů',
        de: 'Benutzerrolle',
    },
    usersCompany: {
        en: 'Users company',
        sk: 'Používateľová firma',
        cz: 'Společnost uživatelů',
        de: 'Benutzerunternehmen',
    },
    tasksProject: {
        en: 'Tasks project',
        sk: 'Projekt úloh',
        cz: 'Projekt úkolů',
        de: 'Aufgaben Projekt',
    },
    sla: {
        en: 'Service level agreement',
        sk: 'Dohoda na úrovni služieb',
        cz: 'Smlouva o úrovni služeb',
        de: 'Vereinbarung zum Servicelevel',
    },
    editSla: {
        en: 'Edit service level agreement',
        sk: 'Upraviť dohodu na úrovni služieb',
        cz: 'Upravit smlouvu o úrovni služeb',
        de: 'Service Level Agreement bearbeiten',
    },
    slas: {
        en: 'Service level agreements',
        sk: 'Dohody na úrovni služieb',
        cz: 'Smlouvy o úrovni služeb',
        de: 'Service Level Agreements',
    },
    /*
    pricelistTitle: {
      en: 'Pricelist title',
      sk: 'Názov cenníka',
      cz: 'Název ceníku',
      de: 'Titel der Preisliste',
    },*/
    surcharges: {
        en: 'Surcharges',
        sk: 'Prirážky',
        cz: 'Příplatky',
        de: 'Zuschläge',
    },
    /*
    replacementPricelist: {
      en: 'A replacement pricelist',
      sk: 'Náhradný cenník',
      cz: 'Náhradní ceník',
      de: 'Eine Ersatzpreisliste',
    },
    newDefaultPricelist: {
      en: 'New default pricelist',
      sk: 'Nový predvolený cenník',
      cz: 'Nový výchozí ceník',
      de: 'Neue Standardpreisliste',
    },*/
    materialsMarginPercentage: {
        en: 'Materials margin percentage',
        sk: 'Percento prirážky materiálov',
        cz: 'Procento marže materiálu',
        de: 'Prozentsatz der Materialmarge',
    },
    materialsMarginPercentagePlaceholder: {
        en: 'Enter materials margin percentage',
        sk: 'Zadajte percento prirážky materiálov',
        cz: 'Zadejte procento marže materiálu',
        de: 'Geben Sie den Prozentsatz der Materialspanne ein',
    },
    afterHoursPercentage: {
        en: 'After hours percentage',
        sk: 'Percento navýšenia pri práci nad pracovný čas',
        cz: 'Procento po hodinách',
        de: 'Prozentsatz nach Geschäftsschluss',
    },
    unnamed: {
        en: 'Unnamed',
        sk: 'Bez názvu',
        cz: 'Bezejmený',
        de: 'Unbenannt',
    },
    pricePlaceholder: {
        en: 'Enter price',
        sk: 'Zadajte cenu',
        cz: 'Zadejte cenu',
        de: 'Preis eingeben',
    },
    allFilters: {
        en: 'All filters',
        sk: 'Všetky filtre',
        cz: 'Všechny filtry',
        de: 'Alle Filter',
    },
    withoutRole: {
        en: 'Without role',
        sk: 'Bez role',
        cz: 'Bez role',
        de: 'Ohne Rolle',
    },
    chooseRoles: {
        en: 'Choose roles',
        sk: 'Vyberte role',
        cz: 'Vyberte si role',
        de: 'Rollen auswählen',
    },
    public: {
        en: 'Public',
        sk: 'Verejný',
        cz: 'Veřejnost',
        de: 'Öffentlichkeit',
    },
    currentUser: {
        en: 'Current user',
        sk: 'Momentálny používateľ',
        cz: 'Současný uživatel',
        de: 'Aktueller Benutzer',
    },
    publicFilter2: {
        en: 'Public filter',
        sk: 'Verejný filter',
        cz: 'Veřejný filtr',
        de: 'Öffentlicher Filter',
    },
    level: {
        en: 'Level',
        sk: 'Úroveň',
        cz: 'Úroveň',
        de: 'Eben',
    },
    loginToSystem: {
        en: 'Login to system',
        sk: 'Prihlásenie do systému',
        cz: 'Přihlášení do systému',
        de: 'Melden Sie sich beim System an',
    },
    invoices: {
        en: 'Invoices',
        sk: 'Výkazy',
        cz: 'Faktury',
        de: 'Rechnungen',
    },
    addProjects: {
        en: 'Add projects',
        sk: 'Pridávať projekty',
        cz: 'Přidejte projekty',
        de: 'Projekte hinzufügen',
    },
    viewErrors: {
        en: 'View system errors',
        sk: 'Systémové chybové hlásenia',
        cz: 'Zobrazit systémové chyby',
        de: 'Systemfehler anzeigen',
    },
    undefinedError: {
        en: 'Undefined error',
        sk: 'Nedefinovaná chyba',
        cz: 'Nedefinovaná chyba',
        de: 'Undefinierter fehler',
    },
    tasklistLayoutRight: {
        en: 'Set tasklist layout (in all tasks)',
        sk: 'Meniť rozloženie stránky (vo všetkých úlohách)',
        cz: 'Nastavit rozložení seznamu úkolů (ve všech úkolech)',
        de: 'Aufgabenlisten-Layout festlegen (in allen Aufgaben)',
    },
    tasklistCalendarRight: {
        en: 'Tasklist calendar (all tasks)',
        sk: 'Zobraziť kalendár (vo všetkých úlohách)',
        cz: 'Kalendář seznamu úkolů (všechny úkoly)',
        de: 'Aufgabenlistenkalender (alle Aufgaben)',
    },
    tasklistPreferencesRight: {
        en: 'Tasklist column preferences',
        sk: 'Meniť preferencie polí zoznamu úloh',
        cz: 'Předvolby sloupců seznamu úkolů',
        de: 'Spalteneinstellungen für Aufgabenlisten',
    },
    customFiltersRight: {
        en: 'Create custom filters',
        sk: 'Vytvárať vlastné filtre',
        cz: 'Vytvářejte vlastní filtry',
        de: 'Erstellen Sie benutzerdefinierte Filter',
    },
    roleTitle: {
        en: 'Role name',
        sk: 'Názov role',
        cz: 'Název role',
        de: 'Rollenname',
    },
    generalRights: {
        en: 'General rights',
        sk: 'Všeobecné práva',
        cz: 'Obecná práva',
        de: 'Allgemeine Rechte',
    },
    granted: {
        en: 'Granted',
        sk: 'Priradené',
        cz: 'Přiznáno',
        de: 'Gewährt',
    },
    viewAndEdit: {
        en: 'View & Edit',
        sk: 'Vidieť a Upravovať',
        cz: 'Zobrazit a upravit',
        de: 'Anzeigen & Bearbeiten',
    },
    helpdeskRights: {
        en: 'Helpdesk rights',
        sk: 'Práva helpdesku',
        cz: 'Práva helpdesku',
        de: 'Helpdesk-Rechte',
    },
    role: {
        en: 'Role',
        sk: 'Rola',
        cz: 'Role',
        de: 'Rolle',
    },
    targerRoleCantBeLowerOrSameThanYours: {
        en: "Targets role can't be lower or same level than yours",
        sk: 'Cieľová rola nemôže mať menšiu alebo rovnakú úroveň ak vy',
        cz: 'Cílová role nemůže být nižší nebo stejná jako vaše',
        de: 'Die Rolle des Ziels darf nicht niedriger oder gleich hoch sein wie Ihre',
    },
    cantEditRole: {
        en: "You don't have the right to edit this role. Targets role can't be lower or same as yours",
        sk: 'Nemáte právo upravovať túto rolu. Cieľová rola nemôže mať úroveň menšiu alebo rovnakú ako vaša',
        cz: 'Nemáte právo upravovat tuto roli. Cílová role nemůže být nižší nebo stejná jako vaše',
        de: 'Sie sind nicht berechtigt, diese Rolle zu bearbeiten. Die Rolle des Ziels darf nicht niedriger oder gleich Ihrer sein',
    },
    testSmtps: {
        en: 'Test SMTPs',
        sk: 'Testovať SMTPs',
        cz: 'Testujte SMTP',
        de: 'Testen Sie SMTPs',
    },
    testingSmtps: {
        en: 'Testing SMTPs...',
        sk: 'Testujú sa SMTPs...',
        cz: 'Testování SMTP...',
        de: 'Testen von SMTPs...',
    },
    cantDeleteLastSMTP: {
        en: "Can't delete last SMTP!",
        sk: 'Nemôžete zmazať posledné SMTP!',
        cz: 'Poslední SMTP nelze zmazat',
        de: 'Letztes SMTP kann nicht gelöscht werden',
    },
    smtp: {
        en: 'SMTP',
        sk: 'SMTP',
        cz: 'SMTP',
        de: 'SMTP',
    },
    wellKnown: {
        en: 'Well known providers - requires only user and password',
        sk: 'Známi poskytovatelia - vyžadujú len meno a heslo',
        cz: 'Dobře známí poskytovatelé - vyžaduje pouze uživatele a heslo',
        de: 'Bekannte Anbieter - erfordert nur Benutzer und Passwort',
    },
    secure: {
        en: 'Secure',
        sk: 'Bezpečné pripojenie',
        cz: 'Bezpečné',
        de: 'Sicher',
    },
    selectReplacementSmtp: {
        en: 'Please choose an SMTP to replace this one',
        sk: 'Prosím vyberte náhradu tohto SMTP',
        cz: 'Vyberte SMTP, kterým chcete tento nahradit',
        de: 'Bitte wählen Sie ein SMTP, um dieses zu ersetzen',
    },
    smtpReplacement: {
        en: 'A replacement SMTP',
        sk: 'Náhradné SMTP',
        cz: 'Náhradní SMTP',
        de: 'Ein Ersatz-SMTP',
    },
    smtpNewDefault: {
        en: 'New default SMTP',
        sk: 'Nové predvolené SMTP',
        cz: 'Nový výchozí SMTP',
        de: 'Neues Standard-SMTP',
    },
    testSmtp: {
        en: 'Test SMTP',
        sk: 'Odtestovať SMTP',
        cz: 'Otestujte SMTP',
        de: 'Testen Sie SMTP',
    },
    /*
  taskType: {
    en: 'Task type',
    sk: 'Typ práce',
    cz: 'Typ úkolu',
    de: 'Aufgabentyp',
  },
  taskTypeTitle: {
    en: 'Task type name',
    sk: 'Názov typu úlohy',
    cz: 'Název typu úkolu',
    de: 'Name des Aufgabentyps',
  },
  */
    lowerMeansFirst: {
        en: 'Lower means first',
        sk: 'Menšie znamená prvý',
        cz: 'Nižší znamená první',
        de: 'Niedriger bedeutet zuerst',
    },
    statusTemplates: {
        en: 'Status templates',
        sk: 'Návrhy statusov',
        cz: 'Šablony stavu',
        de: 'Statusvorlagen',
    },
    statusTemplate: {
        en: 'Status template',
        sk: 'Návrh statusu',
        cz: 'Šablona stavu',
        de: 'Statusvorlage',
    },
    statusTitle: {
        en: 'Status name',
        sk: 'Názov statusu',
        cz: 'Název stavu',
        de: 'Statusname',
    },
    actionIfSelected: {
        en: 'Action if selected',
        sk: 'Akcia keď je vybraný',
        cz: 'Akce, pokud je vybrána',
        de: 'Aktion, falls ausgewählt',
    },
    tripType: {
        en: 'Trip type',
        sk: 'Typ výjazdu',
        cz: 'Typ výletu',
        de: 'Reisetyp',
    },
    tripTypeTitle: {
        en: 'Trip type name',
        sk: 'Názov typu výjazdu',
        cz: 'Název typu cesty',
        de: 'Name der Reiseart',
    },
    changePasswordMessage: {
        en: 'Write a new password and repeat it.',
        sk: 'Napíšte nové heslo a zopakujte ho.',
        cz: 'Napište nové heslo a zopakujte ho.',
        de: 'Schreiben Sie ein neues Passwort und wiederholen Sie es.',
    },
    newPassword: {
        en: 'New Password',
        sk: 'Nové heslo',
        cz: 'Nové heslo',
        de: 'Neues Passwort',
    },
    newPasswordCheck: {
        en: 'New password check',
        sk: 'Kontrola nového hesla',
        cz: 'Kontrola nového hesla',
        de: 'Neue Passwortprüfung',
    },
    changePassword: {
        en: 'Change password',
        sk: 'Zmeniť heslo',
        cz: 'Změnit heslo',
        de: 'Passwort ändern',
    },
    newPasswordCheckPlaceholder: {
        en: 'Re-ender password',
        sk: 'Zadajte znova rovnaké heslo',
        cz: 'Znovu zadejte heslo',
        de: 'Passwort erneut ausgeben',
    },
    filterByRoles: {
        en: 'Filter users by roles',
        sk: 'Filtrovať podľa role',
        cz: 'Filtrujte uživatele podle rolí',
        de: 'Benutzer nach Rollen filtern',
    },
    nameAndEmail: {
        en: 'Name & surname / E-mail',
        sk: 'Meno a priezvisko / E-mail',
        cz: 'Jméno a příjmení / Email',
        de: 'Vor- und Nachname / E-Mail',
    },
    deleteUserMessage: {
        en: 'Are you sure you want to delete the user?',
        sk: 'Ste si istý že chcete zmazať používateľa?',
        cz: 'Opravdu chcete uživatele smazat?',
        de: 'Möchten Sie den Benutzer wirklich löschen?',
    },
    changePasswordEdit: {
        en: 'Password change edit',
        sk: 'Zmeniť zmenu hesla',
        cz: 'Úprava změny hesla',
        de: 'Passwortänderung bearbeiten',
    },
    name: {
        en: 'Name',
        sk: 'Meno',
        cz: 'Název',
        de: 'Name',
    },
    surname: {
        en: 'Surname',
        sk: 'Priezvisko',
        cz: 'Příjmení',
        de: 'Familien-oder Nachname',
    },
    language: {
        en: 'Helpdesk language',
        sk: 'Jazyk stránky',
        cz: 'Jazyk helpdesku',
        de: 'Helpdesk-Sprache',
    },
    receiveNotifications: {
        en: 'Receive e-mail notifications',
        sk: 'Dostávať notifikácie e-mailom',
        cz: 'Přijímat upozornění e-mailem',
        de: 'E-Mail-Benachrichtigungen erhalten',
    },
    signature: {
        en: 'Signature',
        sk: 'Podpis',
        cz: 'Podpis',
        de: 'Unterschrift',
    },
    user2: {
        en: 'User',
        sk: 'Používateľa',
        cz: 'Uživatel',
        de: 'Benutzer',
    },
    usernamePlaceholder: {
        en: 'Enter username',
        sk: 'Zadajte používateľské meno',
        cz: 'Zadejte uživatelské jméno',
        de: 'Benutzernamen eingeben',
    },
    namePlaceholder: {
        en: 'Enter name',
        sk: 'Zadajte meno',
        cz: 'Zadejte uživatelské jméno',
        de: 'Benutzernamen eingeben',
    },
    surnamePlaceholder: {
        en: 'Enter surname',
        sk: 'Zadajte priezvisko',
        cz: 'Zadejte příjmení',
        de: 'Nachnamen eingeben',
    },
    signaturePlaceholder: {
        en: 'Enter signature',
        sk: 'Zadajte podpis',
        cz: 'Zadejte podpis',
        de: 'Unterschrift eingeben',
    },
    regeneratePassword: {
        en: 'Regenerate password',
        sk: 'Vygenerovať heslo',
        cz: 'Obnovit heslo',
        de: 'Passwort neu generieren',
    },
    pendingChangeable: {
        en: 'Pending changeable',
        sk: 'Dátum odloženia upraviteľný',
        cz: 'Čeká na změnu',
        de: 'Ausstehend änderbar',
    },
    createdDate: {
        en: 'Created date',
        sk: 'Dátum vytvorenia',
        cz: 'Datum vytvoření',
        de: 'Erstellungsdatum',
    },
    mail: {
        en: 'Email',
        sk: 'Email',
        cz: 'Email',
        de: 'Email',
    },
    notification: {
        en: 'Notification',
        sk: 'Notifikácia',
        cz: 'Oznámení',
        de: 'Benachrichtigung',
    },
    set: {
        en: 'Set',
        sk: 'Nastaviť',
        cz: 'Soubor',
        de: 'Satz',
    },
    send: {
        en: 'Send',
        sk: 'Poslať',
        cz: 'Poslat',
        de: 'Senden',
    },
    true: {
        en: 'True',
        sk: 'Áno',
        cz: 'Ano',
        de: 'WAHR',
    },
    false: {
        en: 'False',
        sk: 'Nie',
        cz: 'Ne',
        de: 'FALSCH',
    },
    taskCreator: {
        en: 'Task creator',
        sk: 'Tvorca úlohy',
        cz: 'Tvůrce úkolu',
        de: 'Aufgabenersteller',
    },
    assigned: {
        en: 'Assigned',
        sk: 'Priradení',
        cz: 'Zadáno',
        de: 'Zugewiesen',
    },
    empty: {
        en: 'Empty',
        sk: 'Prázdny',
        cz: 'Prázdný',
        de: 'Leer',
    },
    taskCreatorCompany: {
        en: 'Task creator company',
        sk: 'Firma tvorcu úlohy',
        cz: 'Společnost pro tvorbu úkolů',
        de: 'Aufgabenersteller-Unternehmen',
    },
    requesterCompany: {
        en: 'Requester company',
        sk: 'Firma zadávateľa úlohy',
        cz: 'Žadatelská společnost',
        de: 'Anforderndes Unternehmen',
    },
    statisticsAboutProject: {
        en: 'Statistics about projects',
        sk: 'Štatistika o projektoch',
        cz: 'Statistiky o projektech',
        de: 'Statistiken über Projekte',
    },
    statisticsAboutCustomer: {
        en: 'Statistics about customers',
        sk: 'Štatistika o zákazníkoch',
        cz: 'Statistiky o zákaznících',
        de: 'Statistiken über Kunden',
    },
    statisticsAboutTasks: {
        en: 'Statistics about tasks',
        sk: 'Štatistika o úlohách',
        cz: 'Statistiky o úkolech',
        de: 'Statistiken über Aufgaben',
    },

    maxSubtaskValues: {
        en: 'Maximum work hours',
        sk: 'Maximálny pracovný čas',
        cz: 'Maximální pracovní čas',
        de: 'Maximale Arbeitszeit',
    },

    averageSubtaskValues: {
        en: 'Average work hours',
        sk: 'Priemerný pracovný čas',
        cz: 'Průměrní pracovní čas',
        de: 'Durchschnittliche Arbeitszeit',
    },
};
