import {
  gql
} from '@apollo/client';



export const GET_AUTOFILL_FOR_TASK = gql`
  query getAutofillForTask($taskId: Int!) {
    getAutofillForTask (
      taskId: $taskId,
    ) {
      id
      title
      maxSinceCreation
      maxSubtasksValues
      subtasks {
        id
        title
        quantity
      }
    }
  }
`;