import React, { useState, useMemo } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { FormGroup } from 'reactstrap';
import Checkbox from 'components/checkbox';
import Select from 'react-select';
import { pickSelectStyle } from 'configs/components/select';
import { toSelArr } from 'helperFunctions';
import { remapRightsFromBackend } from '../helpers';
import Loading from 'components/loading';

import { useTranslation } from 'react-i18next';
import {
    defaultGroups,
    createCleanRights,
    getEmptyAttributeRights,
} from 'configs/constants/projects';

import { GET_MY_PROJECTS } from 'helpdesk/settings/projects/queries';
import { GET_BASIC_COMPANIES } from '../../companies/queries';
import { GET_BASIC_USERS } from '../../users/queries';

import { GET_PROJECT } from '../queries';

let fakeID = -1;

export default function ProjectAdd(props) {
    //data & queries
    const {
        setTitle,
        setDescription,
        setAttachments,
        setArchived,
        setHideApproved,
        setAutoApproved,
        setStatuses,
        setTags,
        groups,
        userGroups,
        companyGroups,
        setGroups,
        setUserGroups,
        setCompanyGroups,
        setCustomAttributes,
        filters,
        setFilters,
    } = props;

    const { t } = useTranslation();

    const { data: myProjectsData, loading: myProjectsLoading } = useQuery(GET_MY_PROJECTS);

    const { data: companiesData, loading: companiesLoading } = useQuery(GET_BASIC_COMPANIES, {
        fetchPolicy: 'network-only',
    });

    const { data: usersData, loading: usersLoading } = useQuery(GET_BASIC_USERS, {
        fetchPolicy: 'network-only',
    });

    const [getProjectData] = useLazyQuery(GET_PROJECT);

    const [chosenProject, setChosenProject] = useState(null);
    const [chosenTabs, setChosenTabs] = useState([]);

    const [copyWithGroupRights, setCopyWithGroupRights] = useState(false);
    const [copyWithAttributeRights, setCopyWithAttributeRights] = useState(false);

    const [copying, setCopying] = useState(false);
    const [finishedCopying, setFinishedCopying] = useState(false);

    const projects = useMemo(() => {
        if (!myProjectsLoading) {
            return myProjectsData.myProjects
                .filter((project) => project.right.projectRead)
                .map((project) => ({
                    ...project,
                    label: project.project.title,
                    value: project.project.id,
                }));
        }
        return [];
    }, [myProjectsData, myProjectsLoading]);

    const settingsTabs = [
        {
            label: t('description'),
            value: 'descriptionTab',
            func: function (e) {
                copyDescriptionTab(e);
            },
        },
        {
            label: t('statuses'),
            value: 'statuses',
            func: function (e) {
                copyStatuses(e);
            },
        },
        {
            label: t('tags'),
            value: 'tags',
            func: function (e) {
                copyTags(e);
            },
        },
        {
            label: t('groups'),
            value: 'groups',
            func: function (e) {
                copyGroups(e);
            },
        },
        {
            label: t('users'),
            value: 'users',
            func: function (e) {
                copyUsers(e);
            },
        },
        {
            label: t('companies'),
            value: 'companies',
            func: function (e) {
                copyCompanies(e);
            },
        },
        {
            label: t('customAttributes'),
            value: 'customAttributes',
            func: function (e) {
                copyCustomAttributes(e);
            },
        },
        {
            label: t('projectFilters'),
            value: 'projectFilters',
            func: function (e) {
                copyProjectFilters(e);
            },
        },
    ];

    const getDefaultGroupData = (project) => {
        const users = toSelArr(usersData.basicUsers, 'email');
        const companies = toSelArr(companiesData.basicCompanies);
        const groups = toSelArr(project.groups.map((group) => remapRightsFromBackend(group)));
        const userGroups = project.groups.reduce((acc, cur) => {
            let group = groups.find((group) => group.id === cur.id);
            let userGroups = cur.users.map((user1) => ({
                user: users.find((user2) => user2.id === user1.id),
                group,
            }));
            return [...acc, ...userGroups];
        }, []);

        const companyGroups = project.groups.reduce((acc, cur) => {
            let group = groups.find((group) => group.id === cur.id);
            let companyGroups = cur.companies.map((company1) => ({
                company: companies.find((company2) => company2.id === company1.id),
                group,
            }));
            return [...acc, ...companyGroups];
        }, []);

        return {
            groups,
            userGroups,
            companyGroups,
        };
    };

    const copyDescriptionTab = (project) => {
        setTitle(project.title);
        setDescription(project.description);
        //// TODO: figure out attachments
        let copiedAttachments = [];
        /*project.attachments.forEach((item, i) => {
      let a = getAttachment(item);
    });*/
        setAttachments(copiedAttachments);
        setArchived(project.archived);
        setHideApproved(project.hideApproved);
        setAutoApproved(project.autoApproved);
    };

    const copyStatuses = (project) => {
        const newStatuses = project.statuses.map((status) => ({
            action: status.action,
            color: status.color,
            icon: status.icon,
            id: fakeID--,
            order: status.order,
            title: status.title,
        }));
        setStatuses(newStatuses);
    };

    const copyTags = (project) => {
        const newTags = project.tags.map((tag) => ({
            color: tag.color,
            id: fakeID--,
            order: tag.order,
            title: tag.title,
        }));
        setTags(newTags);
    };

    const copyGroups = (project) => {
        const { groups } = getDefaultGroupData(project);
        let newGroups = groups.map((group) => ({
            ...group,
            id: group.id * -1,
            attributeRights: copyWithAttributeRights
                ? group.attributeRights
                : getEmptyAttributeRights(group.def ? group.order : 3),
            rights: copyWithGroupRights
                ? group.rights
                : createCleanRights(
                      group.def ? group.order < 2 : false,
                      group.def ? group.order : 3
                  ),
        }));
        setGroups(newGroups);
        if (!chosenTabs.find((tab) => tab.value === 'users')) {
            setUserGroups(
                userGroups.map((userGroup) => {
                    if (userGroup.group.def) {
                        return {
                            group: newGroups.find(
                                (group) => group.def && group.order === userGroup.group.order
                            ),
                            user: userGroup.user,
                        };
                    }
                    return {
                        group: newGroups.find((group) => group.def && group.order === 2),
                        user: userGroup.user,
                    };
                })
            );
        }
        if (!chosenTabs.find((tab) => tab.value === 'companies')) {
            setCompanyGroups(
                companyGroups.map((companyGroup) => {
                    if (companyGroup.group.def) {
                        return {
                            group: newGroups.find(
                                (group) => group.def && group.order === companyGroup.group.order
                            ),
                            company: companyGroup.company,
                        };
                    }
                    return {
                        group: newGroups.find((group) => group.def && group.order === 2),
                        company: companyGroup.company,
                    };
                })
            );
        }
        if (!chosenTabs.find((tab) => tab.value === 'projectFilters')) {
            setFilters(
                filters.map((filter) => ({
                    ...filter,
                    groups: filter.groups.map((group) => {
                        if (group <= -1 && group >= -3) {
                            return newGroups.find(
                                (newGroup) => newGroup.def && newGroup.order === group * -1 - 1
                            ).id;
                        }
                        return newGroups.find((newGroup) => newGroup.def && newGroup.order === 0)
                            .id;
                    }),
                }))
            );
        }
    };

    const copyUsers = (project) => {
        const { userGroups } = getDefaultGroupData(project);
        if (chosenTabs.find((tab) => tab.value === 'groups')) {
            setUserGroups(
                userGroups.map((userGroup) => ({
                    group: {
                        ...userGroup.group,
                        id: userGroup.group.id * -1,
                    },
                    user: userGroup.user,
                }))
            );
        } else {
            setUserGroups(
                userGroups.map((userGroup) => {
                    if (userGroup.group.def) {
                        return {
                            group: {
                                ...userGroup.group,
                                id: userGroup.group.id * -1,
                            },
                            user: userGroup.user,
                        };
                    }
                    return {
                        group: defaultGroups[2],
                        user: userGroup.user,
                    };
                })
            );
        }
    };

    const copyCompanies = (project) => {
        const { companyGroups } = getDefaultGroupData(project);
        if (chosenTabs.find((tab) => tab.value === 'groups')) {
            setCompanyGroups(
                companyGroups.map((companyGroup) => ({
                    group: {
                        ...companyGroup.group,
                        id: companyGroup.group.id * -1,
                    },
                    company: companyGroup.company,
                }))
            );
        } else {
            setCompanyGroups(
                companyGroups.map((companyGroup) => {
                    if (companyGroup.group.def) {
                        return {
                            group: {
                                ...companyGroup.group,
                                id: companyGroup.group.id * -1,
                            },
                            company: companyGroup.company,
                        };
                    }
                    return {
                        group: defaultGroups[2],
                        company: companyGroup.company,
                    };
                })
            );
        }
    };

    const copyCustomAttributes = (project) => {
        const newCustomAttributes = project.customAttributes.map((customAttribute) => {
            let typeLabel = 'Text';
            if (customAttribute.type === 'textarea') {
                typeLabel = 'Textarea';
            }
            if (customAttribute.type === 'number') {
                typeLabel = 'Number';
            }
            if (customAttribute.type === 'select') {
                typeLabel = 'Select';
            }
            if (customAttribute.type === 'multiselect') {
                typeLabel = 'Multiselect';
            }
            let newCustomAttribute = {
                ...customAttribute,
                id: customAttribute.id * -1,
                addGroups: chosenTabs.find((tab) => tab.value === 'groups')
                    ? [project.groups.sort((g1, g2) => (g1.order < g2.order ? -1 : 1))[0]]
                    : [groups.sort((g1, g2) => (g1.order < g2.order ? -1 : 1))[0]],
                editGroups: chosenTabs.find((tab) => tab.value === 'groups')
                    ? [project.groups.sort((g1, g2) => (g1.order < g2.order ? -1 : 1))[0]]
                    : [groups.sort((g1, g2) => (g1.order < g2.order ? -1 : 1))[0]],
                viewGroups: chosenTabs.find((tab) => tab.value === 'groups')
                    ? [project.groups.sort((g1, g2) => (g1.order < g2.order ? -1 : 1))[0]]
                    : [groups.sort((g1, g2) => (g1.order < g2.order ? -1 : 1))[0]],
                type: {
                    label: typeLabel,
                    value: customAttribute.type,
                },
            };
            delete newCustomAttribute.__typename;
            if (newCustomAttribute.defaultValue.__typename) {
                delete newCustomAttribute.defaultValue.__typename;
            }
            return newCustomAttribute;
        });
        setCustomAttributes(newCustomAttributes);
    };

    const copyProjectFilters = (project) => {
        const newProjectFilters = project.projectFilters.map((projectFilter) => {
            let newFilter = {
                ...projectFilter,
                id: projectFilter.id * -1,
                groups: chosenTabs.find((tab) => tab.value === 'groups')
                    ? [project.groups.sort((g1, g2) => (g1.order < g2.order ? -1 : 1))[0].id]
                    : [groups.sort((g1, g2) => (g1.order < g2.order ? -1 : 1))[0].id],
                filter: {
                    ...projectFilter.filter,
                    customAttributes:
                        projectFilter.filter.customAttributes.length > 0
                            ? projectFilter.filter.customAttributes.map((customAttribute) => ({
                                  text: customAttribute.text,
                                  number: customAttribute.number,
                                  selectValues: customAttribute.selectValues
                                      ? customAttribute.selectValues
                                      : [],
                                  customAttribute: customAttribute.customAttribute.id * -1,
                              }))
                            : [],
                },
            };
            delete newFilter.__typename;
            delete newFilter.filter.__typename;

            return newFilter;
        });
        setFilters(newProjectFilters);
    };

    const copySettings = () => {
        setCopying(true);
        getProjectData({
            variables: {
                id: chosenProject.value,
            },
        }).then((response) => {
            if (response?.data?.project) {
                chosenTabs.forEach((item) => {
                    item.func(response.data.project);
                });
            }
            setChosenProject(null);
            setChosenTabs([]);
            setCopying(false);
            setFinishedCopying(true);
        });
    };

    if (usersLoading || companiesLoading) {
        return <Loading />;
    }

    return (
        <div>
            <FormGroup className="m-b-25">
                <label>{t('project')}</label>
                <Select
                    id="project"
                    name="project"
                    styles={pickSelectStyle()}
                    options={projects}
                    value={chosenProject}
                    onChange={(e) => {
                        setFinishedCopying(false);
                        setChosenProject(e);
                    }}
                />
            </FormGroup>

            <FormGroup className="m-b-25">
                <label>{t('settings')}</label>
                <Select
                    id="settingsTabs"
                    name="settingsTabs"
                    isMulti
                    styles={pickSelectStyle()}
                    options={settingsTabs}
                    value={chosenTabs}
                    onChange={(e) => {
                        setFinishedCopying(false);
                        setChosenTabs(e);
                    }}
                />
            </FormGroup>

            {chosenTabs.find((tab) => tab.value === 'groups') && (
                <FormGroup className="m-b-25">
                    <Checkbox
                        className="m-b-5 p-l-0"
                        value={copyWithGroupRights}
                        onChange={() => setCopyWithGroupRights(!copyWithGroupRights)}
                        label={t('copyWithGroupRights')}
                    />
                    <Checkbox
                        className="m-b-5 p-l-0"
                        value={copyWithAttributeRights}
                        onChange={() => setCopyWithAttributeRights(!copyWithAttributeRights)}
                        label={t('copyWithAttributeRights')}
                    />
                </FormGroup>
            )}

            <div style={{ justifyContent: 'space-between' }}>
                <button
                    className="btn-link-cancel mr-auto"
                    onClick={(e) => {
                        e.preventDefault();
                        setFinishedCopying(false);
                    }}
                >
                    {t('cancel')}
                </button>
                <button
                    className="btn"
                    disabled={chosenProject === null}
                    onClick={(e) => {
                        e.preventDefault();
                        copySettings();
                    }}
                >
                    {!finishedCopying && !copying && t('copy')}
                    {copying && (
                        <span>
                            <i className={`fa fa-spinner`} />
                            {t('copying')}
                        </span>
                    )}
                    {finishedCopying && t('finishedCopying')}
                </button>
            </div>
        </div>
    );
}
