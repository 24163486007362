import React, {
  useMemo
} from 'react';
import {
  useLocation
} from 'react-router-dom';

import MainSettingsSidebar from './indexSettings';
import TasksSidebar from './tasksSidebar';
import settings from 'configs/constants/settings';
import classnames from 'classnames';
import {
  getMyData
} from 'helperFunctions';

export default function Sidebar(props) {
  //data & queries
  const {
    sidebarOpen,
    setSidebarOpen
  } = props;

  const currentUser = getMyData();

  const location = useLocation();

  const accessRights = currentUser && currentUser.role ? currentUser.role.accessRights : {};

  const canSeeSettings = settings.some(s => accessRights[s.value]);

  const showSettings = useMemo(() => {
    return location.pathname.includes('settings') && canSeeSettings;
  }, [location, canSeeSettings]);



  return (
    <div className={classnames({ "sidebar": sidebarOpen || showSettings, "sidebar-compressed": !sidebarOpen && !showSettings })}>
      <div className="scrollable fit-with-header">
        {!showSettings &&
          <TasksSidebar {...props} toggleSidebar={() => setSidebarOpen(!sidebarOpen)} sidebarOpen={sidebarOpen} />
        }
        {showSettings &&
          <MainSettingsSidebar {...props} />
        }
      </div>
    </div>
  );
}