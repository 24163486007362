import React from 'react';
import {
  Route,
  Routes,
  Outlet,
} from 'react-router-dom';

import AccessDenied from 'components/accessDenied';
import Sidebar from 'lanwiki/components/sidebar';
import ErrorMessages from 'components/errorMessages';
import NotificationList from 'components/notifications';
import PageHeader from 'components/PageHeader';
import SelectPage from 'components/SelectPage';
import PagesList from './pages/list';
import PageView from './pages/edit';

import Empty from 'components/Empty';

import {
  getMyData,
} from 'helperFunctions';

export default function LanwikiNavigation() {
  //new Blob([str]).size;
  const currentUser = getMyData();
  const accessRights = currentUser && currentUser.role ? currentUser.role.accessRights : {};

  return (
    <Routes>
      <Route
        path='lanwiki/*'
        element={
          <div>
            <div className="page-header">
              <div className="center-ver row center flex">
                <SelectPage />
                <PageHeader />
              </div>
            </div>

            <div className="row center center-ver">
              <Sidebar />
              <div className="main">
                <Outlet />
              </div>
            </div>
          </div>
        }
        >

        <Route
          path="errorMessages"
          element={accessRights.viewErrors ? <ErrorMessages /> : <AccessDenied />}
          />
        <Route
          path="notifications"
          element={<NotificationList />}
          />
        <Route
          path="notifications/:notificationID"
          element={<NotificationList />}
          />

        <Route
          path='i/:folderID'
          element={<PagesList />}
          />

        <Route
          path='i/:folderID/p/:page'
          element={<PagesList />}
          />
        <Route
          path='i/:folderID/p/:page/:pageID'
          element={<PageView />}
          />

      </Route>

      <Route
        path="*"
        element={null}
        />

    </Routes>
  );
}
