import React from 'react';

import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  useMutation,
} from "@apollo/client";

import axios from 'axios';

import ManualForm from 'cmdb/manuals/form';

import {
  REST_URL,
} from 'configs/restAPI';

import {
  ADD_MANUAL,
  UPDATE_MANUAL,
} from 'cmdb/manuals/queries';

import {
  extractImages,
  replacePlaceholdersWithLinks,
} from 'helperFunctions';

export default function ManualAddContainer() {

  const navigate = useNavigate();
  const params = useParams();

  const companyId = params.companyID === 'all' ? null : parseInt( params.companyID );

  //mutations
  const [ addManual ] = useMutation( ADD_MANUAL );
  const [ updateManual ] = useMutation( UPDATE_MANUAL );

  return (
    <ManualForm
      edit={false}
      addManual={(data, setSaving) => {
        setSaving(true);
        const separatedData = extractImages(data.body);
        data.body = separatedData.value;
        addManual({variables: {...data, companyId}}).then((response1) => {
          const id = response1.data.addCmdbManual.id;
          if(separatedData.files.length > 0){
            const formData = new FormData();
            separatedData.files.forEach( ( file ) => formData.append( `file`, file ) );
            formData.append( "token", `Bearer ${sessionStorage.getItem( "acctok" )}` );
            formData.append( "id", id );
            formData.append( "type", "manual" );
            axios.post( `${REST_URL}/cmdb-upload-text-images`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            } ).then((response2) => {
              if(!response2.data.ok){
                setSaving(false);
                return;
              }
              const newBody = replacePlaceholdersWithLinks(separatedData.value, response2.data.attachments, 'get-cmdb-file');
              updateManual({variables: { id, body: newBody, title: data.title }}).then(() => {
                setSaving(false);
                navigate(`/cmdb/manuals/${companyId === null ? 'all' : companyId}/p/1/${id}`);
              }).catch((e) => {
                console.log(e);
                setSaving(false);
              })
            })
          }else{
            setSaving(false);
            navigate(`/cmdb/manuals/${companyId === null ? 'all' : companyId}/p/1/${id}`);
          }
        }).catch((e) => {
          console.log(e);
          setSaving(false);
        })
      }}
      close={(() => navigate(-1) )}
      />
  );

}
