import React, {
  useMemo
} from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import {
  filterUnique
} from 'helperFunctions';
import Checkbox from 'components/checkbox';
import AddCustomAttributeModal from './addCustomAttribute';
import EditCustomAttributeModal from './editCustomAttribute';
import {
  useTranslation
} from "react-i18next";

const types = [
  {
    label: 'Text',
    value: 'text'
  },
  {
    label: 'Textarea',
    value: 'textarea'
  },
  {
    label: 'Number',
    value: 'number'
  },
  {
    label: 'Select - single',
    value: 'select'
  },
  {
    label: 'Select - multi',
    value: 'multiselect'
  }
]

export default function CustomAttributes( props ) {
  const {
    disabled,
    groups,
    customAttributes,
    addCustomAttribute,
    updateCustomAttribute,
    deleteCustomAttribute,
  } = props;

  const {
    t
  } = useTranslation();
  
  const [ addCustomAttributeOpen, setAddCustomAttributeOpen ] = React.useState( false );
  const [ editCustomAttributeOpen, setEditCustomAttributeOpen ] = React.useState( null );

  const getDefaultValues = (customAttribute) => {
    const type = typeof customAttribute.type === "string" ? customAttribute.type : customAttribute.type.value;
    if (["text", "textarea"].includes(type)){
      return customAttribute.defaultValue.text;
    }
    if (["number"].includes(type)){
      return customAttribute.defaultValue.number;
    }
    return (customAttribute.selectValues.filter(option => option.def).sort((o1, o2) => (o1.order < o2.order ? -1 : 1)).map((option) => (<p id={option.id} key={option.id ? option.id : option.value}>{option.value.substring(0,1).toUpperCase() + option.value.substring(1)}</p>))    )
  }

  const translatedGroups = useMemo( () => {
    return groups.map((group) => ({
      ...group,
      title: t(group.title)
    }))
  }, [groups]);

  return (
    <div>
      <table className="table m-t-10 bkg-white project-settings">
        <thead>
          <tr>
            <th className="font-bold"> {t('order')} </th>
            <th className="font-bold"> {t('title')} </th>
            <th className="font-bold"> {t('type')} </th>
            <th className="font-bold"> {t('options')} </th>
            <th className="font-bold"> {t('add')} </th>
            <th className="font-bold"> {t('read')} </th>
            <th className="font-bold"> {t('write')} </th>
            <th className="font-bold"> {t('defaultValue')} </th>
            <th className="font-bold"> {t('required')} </th>
            <th className="text-center font-bold"></th>
          </tr>
        </thead>
        <tbody>
          { customAttributes.sort((o1, o2) => (parseInt(o1.order) < parseInt(o2.order) ? -1 : 1)).map( customAttribute =>
            <tr key={customAttribute.id}>
              <td> {customAttribute.order} </td>
              <td> {customAttribute.title} </td>
              <td> {t(typeof customAttribute.type === "string" ? (customAttribute.type === "select" ? t(customAttribute.type+"Noun") : t(customAttribute.type)) : customAttribute.type.value) } </td>
              <td> {customAttribute.selectValues ? customAttribute.selectValues.sort((o1, o2) => (o1.order < o2.order ? -1 : 1)).map((option) => (<p id={option.id} key={option.id ? option.id : option.value}>{option.value.substring(0,1).toUpperCase() + option.value.substring(1)}</p>)) : '' } </td>
              <td> {customAttribute.addGroups.map((add) => (<p id={add.id} key={add.id}>{t(add.title)}</p>))} </td>
              <td> {customAttribute.viewGroups.map((read) => (<p id={read.id} key={read.id}>{t(read.title)}</p>))} </td>
              <td> {customAttribute.editGroups.map((write) => (<p id={write.id} key={write.id}>{t(write.title)}</p>))} </td>
              <td> {getDefaultValues(customAttribute)} </td>
              <td> {customAttribute.required ? t('yes') : t('no')} </td>
              <td className="text-end">
                <button
                  className="btn-link btn-distance"
                  disabled={disabled}
                  style={{height:"auto"}}
                  onClick={()=>{
                    if(window.confirm(t('areYouSure'))){
                      deleteCustomAttribute(customAttribute.id);
                    }
                  }}>
                  <i className="fa fa-times"  />
                </button>
                <button
                  className="btn-link"
                  style={{height:"auto"}}
                  disabled={disabled}
                  onClick={()=>{
                    setEditCustomAttributeOpen(customAttribute);
                  }}>
                  <i className="fa fa-pen"  />
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <button className="btn-link"
        disabled={disabled}
        onClick={()=>{
          setAddCustomAttributeOpen(true);
        }}
        >
        <i className="fa fa-plus p-r-5" />
        {t('customAttribute')}
      </button>
      <AddCustomAttributeModal
        groups={translatedGroups}
        open={addCustomAttributeOpen}
        addCustomAttribute={addCustomAttribute}
        closeModal={() => setAddCustomAttributeOpen(false)}
        />
      <EditCustomAttributeModal
        groups={translatedGroups}
        open={editCustomAttributeOpen !== null}
        updateCustomAttribute={ updateCustomAttribute }
        attribute={ editCustomAttributeOpen }
        closeModal={() => setEditCustomAttributeOpen(null)}
        />
    </div>
  );
}
