import React, {
  useState
} from 'react';
import Select, {
  Creatable
} from 'react-select';
import {
  pickSelectStyle,
} from 'configs/components/select';
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Empty from 'components/Empty';
import Checkbox from 'components/checkbox';
import {
  useQuery,
} from "@apollo/client";
import {
  filterUnique,
  toSelArr
} from 'helperFunctions';

import {
  GET_BASIC_ROLES,
} from 'helpdesk/settings/roles/queries';

import {
  useTranslation
} from "react-i18next";

const types = [
  {
    label: 'Text',
    value: 'text'
  },
  {
    label: 'Textarea',
    value: 'textarea'
  },
  {
    label: 'Number',
    value: 'number'
  },
  {
    label: 'Select - single',
    value: 'select'
  },
  {
    label: 'Select - multi',
    value: 'multiselect'
  }
]

let fakeID = -1;

export default function SelectsSpecificFields( props ) {
  const {
    type,
    selectValues,
    setSelectValues,
    setDefaultValue,
  } = props;

    const {
      t
    } = useTranslation();

  const [openAdd, setOpenAdd] = useState(false);
  const [newOption, setNewOption] = useState({def: false, value: "", order: -1});

  return (
    <Empty>
      <FormGroup>
        <Label>{t('options')}</Label>
        <table  className="table">
            <thead>
              <tr>
                <th width="50px">{t('default')}</th>
                <th className="p-l-0">{t('title')}</th>
                <th width="100px" className="text-end">{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {
                selectValues.map((option, index) => (
                  <tr key={option.order}>
                    <td>
                      <Checkbox
                        className="m-t-5 segoe-blue-text"
                        value={ option.def }
                        onChange={()=>{
                          let newSelectValues = [...selectValues];
                          let newValue = !option.def;
                          newSelectValues = selectValues.map((op) => {
                            if ((type === "select" || type.value === "select") && newValue){
                              return ({...op, def: op.id === option.id ? newValue : false});
                            }
                            return ({...op, def: op.id === option.id ? newValue : op.def});
                          });
                          setSelectValues(newSelectValues);
                        }}
                        />
                    </td>
                    <td className="p-l-0">
                      <input
                        className="form-control hidden-input segoe-blue-text"
                        value={option.value}
                        onChange={(e) => {
                          let newSelectValues = selectValues.map((op) => ({...op, value: op.id === option.id ? e.target.value : op.value}));
                          setSelectValues(newSelectValues);
                        }}
                        />
                    </td>
                    <td className="text-end">
                      <button
                        className="btn-link btn-distance"
                        disabled={index === 0}
                        onClick={()=>{
                          let newSelectValues = [...selectValues];
                          newSelectValues[index-1] = {...selectValues[index], order: index-1};
                          newSelectValues[index] = {...selectValues[index-1], order: index};
                          setSelectValues(newSelectValues);
                        }}
                        >
                        <i className="fa fa-arrow-up"  />
                      </button>
                      <button
                        className="btn-link btn-distance"
                        disabled={index === selectValues.length - 1}
                        onClick={()=>{
                          let newSelectValues = [...selectValues];
                          newSelectValues[index+1] = {...selectValues[index], order: index+1};
                          newSelectValues[index] = {...selectValues[index+1], order: index};
                          setSelectValues(newSelectValues);
                        }}
                        >
                        <i className="fa fa-arrow-down"  />
                      </button>
                      <button
                        className="btn-link"
                        onClick={()=>{
                          let newSelectValues = [...selectValues.filter(op => op.id !== option.id)].map((op, i) => ({...op, order: i}));
                          setSelectValues(newSelectValues);
                        }}>
                        <i className="fa fa-times" />
                      </button>
                    </td>
                  </tr>
                ))
              }
              {
                openAdd &&
                <tr key={"addOption"}>
                  <td>
                    <Checkbox
                      className="m-t-5 segoe-blue-text"
                      value={ newOption.def }
                      onChange={()=>{
                        setNewOption({...newOption, def: !newOption.def});
                      }}
                      />
                  </td>
                  <td className="p-l-0">
                    <input
                      className="form-control segoe-blue-text"
                      value={newOption.value}
                      onChange={(e) => {
                        setNewOption({...newOption, value: e.target.value});
                      }}
                      />
                  </td>
                  <td className="text-end">
                    <button
                      className="btn-link btn-distance"
                      disabled={newOption.value.length === 0 || selectValues.some(op => op.value === newOption.value)}
                      onClick={()=>{
                        
                        const newSelectValue = ({...newOption, order: selectValues.length, id: fakeID--});
                        let newSelectValues = [...selectValues];
                        if ((type === "select" || type.value === "select") && newSelectValue.def){
                          newSelectValues = newSelectValues.map((option) => ({
                            ...option,
                            def: false
                          }));
                        }
                        newSelectValues = [...newSelectValues, {...newSelectValue}];
                        setNewOption({def: false, value: "", order: -1});
                        setOpenAdd(false);
                        setSelectValues(newSelectValues);

                      }}>
                      <i className="fa fa-plus" />
                    </button>

                    <button
                      className="btn-link"
                      onClick={()=>{
                        setOpenAdd(false);
                      }}>
                      <i className="fa fa-times" />
                    </button>
                  </td>
                </tr>
              }
            </tbody>
        </table>
        {
          !openAdd &&
            <button
              className="btn-link"
              onClick={() => setOpenAdd(true)}
              >
              <i className="fa fa-plus" />
              {t('option')}
            </button>
        }
      </FormGroup>
    </Empty>
  );
}
