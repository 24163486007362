export default {
  invoiceOfCompany: {
    en: 'Invoice of company',
    sk: 'Fakturačný výkaz firmy',
    cz: 'Faktura společnosti',
    de: 'Rechnung des Unternehmens',
  },
  companyName: {
    en: 'Company name',
    sk: 'Názov firmy',
    cz: 'Jméno společnosti',
    de: 'Name der Firma',
  },
  materials: {
    en: 'Materials',
    sk: 'Materiále',
    cz: 'Materiály',
    de: 'Materialien',
  },
  rentedItems: {
    en: 'Rented items',
    sk: 'Prenajaté veci',
    cz: 'Pronajaté věci',
    de: 'Gemietete Artikel',
  },
  noInvoiceableCompanies: {
    en: 'No invoiceable companies',
    sk: 'Žiadne vykázateľné firmy',
    cz: 'Žádné fakturovatelné společnosti',
    de: 'Keine fakturierbaren Unternehmen',
  },
  invoicePending: {
    en: 'Invoicing',
    sk: 'Faktúrujú sa',
    cz: 'Fakturace',
    de: 'Fakturierung',
  },
  selected: {
    en: 'Selected',
    sk: 'Vybrané',
    cz: 'Vybraný',
    de: 'Ausgewählt',
  },
  mark: {
    en: 'Mark',
    sk: 'Označiť',
    cz: 'Označit',
    de: 'Markieren',
  },
  unmark: {
    en: 'Unmark',
    sk: 'Odznačiť',
    cz: 'Zrušit označení',
    de: 'Markierung aufheben',
  },
  allTasks: {
    en: 'All tasks',
    sk: 'Všetky úlohy',
    cz: 'Všechny úkoly',
    de: 'Alle Aufgaben',
  },
  /*worksTripsInPausal: {
    en: 'Works and trips in pausal',
    sk: 'Práce a výjazdy v rámci paušálu',
    cz: '',
    de: '',
  },
  worksTripsOverPausal: {
    en: 'Works and trips over pausal',
    sk: 'Práce a výjazdy nad rámec paušálu',
    cz: '',
    de: '',
  },*/
  worksTripsInProject: {
    en: 'Works and trips in projects',
    sk: 'Projektové práce a výjazdy',
    cz: 'Práce a výlety v projektech',
    de: 'Arbeiten und Reisen in Projekten',
  },
/*  overtimeWorkExtraPrice: {
    en: 'Total of extra price for overtime works',
    sk: 'Spolu prirážka za práce mimo pracovných hodín',
    cz: '',
    de: '',
  },
  overtimeTripExtraPrice: {
    en: 'Total of extra price for overtime trips',
    sk: 'Spolu prirážka za výjazdy mimo pracovných hodín',
    cz: '',
    de: '',
  },*/
  totalPriceWithoutTax: {
    en: 'Total price without tax',
    sk: 'Spolu cena bez DPH',
    cz: 'Celková cena bez daně',
    de: 'Gesamtpreis ohne MwSt',
  },
  totalPriceWithTax: {
    en: 'Total price with tax',
    sk: 'Spolu cena s DPH',
    cz: 'Celková cena s daní',
    de: 'Gesamtpreis mit MwSt',
  },
  euro: {
    en: 'Euro',
    sk: 'Eur',
    cz: 'Euro',
    de: 'Euro',
  },
  material: {
    en: 'Material',
    sk: 'Materiál',
    cz: 'Materiál',
    de: 'Material',
  },
  monthlyCompanyRent: {
    en: 'Monthly rent of services and hardware',
    sk: 'Mesačný prenájom služieb a harware',
    cz: 'Měsíční nájem služeb a hardwaru',
    de: 'Monatliche Miete von Dienstleistungen und Hardware',
  },
  pricePcMonth: {
    en: 'Price/pc/month',
    sk: 'Cena/ks/mesiac',
    cz: 'Cena/ks/měsíc',
    de: 'Preis/Stk/Monat',
  },
  priceTotalMonth: {
    en: 'Price total/month',
    sk: 'Cena spolu/mesiac',
    cz: 'Cena celkem/měsíc',
    de: 'Gesamtpreis/Monat',
  },
  pricePerHour: {
    en: 'Price/hour',
    sk: 'Cena/hodna',
    cz: 'Cena/hod',
    de: 'Preis/Stunde',
  },
  pricePerQuantity: {
    en: 'Price/Qt.',
    sk: 'Cena/Mn.',
    cz: 'Cena/množství',
    de: 'Preis/Qut.',
  },
  pricePerUnit: {
    en: 'Price/pc',
    sk: 'Cena/ks',
    cz: 'Cena/ks',
    de: 'Preis/Stk',
  },
  totalPrice: {
    en: 'Total price',
    sk: 'Cena spolu',
    cz: 'Celková cena',
    de: 'Gesamtpreis',
  }
}
