import React from 'react';
import {
  useQuery,
  useMutation,
  useSubscription,
} from "@apollo/client";
import {
  toSelArr,
  getMyData,
} from 'helperFunctions';
import Empty from 'components/Empty';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import TaskAdd from './taskAdd';
import {
  addLocalError,
} from 'apollo/localSchema/actions';
import ProjectSelectModal from './projectSelectModal';
import classnames from 'classnames';
import {
  useTranslation
} from "react-i18next";
/*
import {
  GET_TASK_TYPES,
  TASK_TYPES_SUBSCRIPTION,
} from 'helpdesk/settings/taskTypes/queries';
*/
import {
  GET_TRIP_TYPES,
  TRIP_TYPES_SUBSCRIPTION,
} from 'helpdesk/settings/tripTypes/queries';

import {
  GET_BASIC_USERS,
  USERS_SUBSCRIPTION,
} from 'helpdesk/settings/users/queries';

import {
  GET_BASIC_COMPANIES,
  COMPANIES_SUBSCRIPTION,
} from 'helpdesk/settings/companies/queries';

import {
  GET_MY_PROJECTS,
  PROJECTS_SUBSCRIPTION,
} from 'helpdesk/settings/projects/queries';

import {
  SET_TASK_LAYOUT,
  ADD_TASK,
  SET_AFTER_TASK_CREATE,
} from '../queries';

export default function TaskAddContainer(props) {
  const {
    disabled,
    projectID: sidebarProjectID,
    noText,
    sidebarOpen,
    toggleSidebar
  } = props;

  const {
    t
  } = useTranslation();

  //data & queries
  const {
    data: companiesData,
    loading: companiesLoading,
    refetch: companiesRefetch,
  } = useQuery(GET_BASIC_COMPANIES, {
    fetchPolicy: 'network-only'
  });
  const {
    data: usersData,
    loading: usersLoading,
    refetch: usersRefetch,
  } = useQuery(GET_BASIC_USERS, {
    fetchPolicy: 'network-only'
  });
  /*
  const {
    data: taskTypesData,
    loading: taskTypesLoading,
    refetch: taskTypesRefetch,
  } = useQuery( GET_TASK_TYPES, {
    fetchPolicy: 'network-only'
  } );
  */
  const {
    data: tripTypesData,
    loading: tripTypesLoading,
    refetch: tripTypesRefetch,
  } = useQuery(GET_TRIP_TYPES, {
    fetchPolicy: 'network-only'
  });

  const {
    data: projectsData,
    loading: projectsLoading,
    refetch: projectsRefetch,
  } = useQuery(GET_MY_PROJECTS, {
    fetchPolicy: 'network-only'
  });

  //mutations
  const [addTask] = useMutation(ADD_TASK);
  const [setTaskLayout] = useMutation(SET_TASK_LAYOUT);
  const [setAfterTaskCreate] = useMutation(SET_AFTER_TASK_CREATE);

  //subscriptions
  /*
  useSubscription( TASK_TYPES_SUBSCRIPTION, {
    onData: () => {
      taskTypesRefetch();
    }
  } );
  */

  useSubscription(TRIP_TYPES_SUBSCRIPTION, {
    onData: () => {
      tripTypesRefetch();
    }
  });

  useSubscription(PROJECTS_SUBSCRIPTION, {
    onData: () => {
      projectsRefetch();
    }
  });

  useSubscription(COMPANIES_SUBSCRIPTION, {
    onData: () => {
      companiesRefetch();
    }
  });

  useSubscription(USERS_SUBSCRIPTION, {
    onData: () => {
      usersRefetch();
    }
  });

  //state
  const [openAddTaskModal, setOpenAddTaskModal] = React.useState(false);
  const [projectID, setProjectID] = React.useState(sidebarProjectID ? sidebarProjectID : null);

  React.useEffect(() => {
    setProjectID(sidebarProjectID ? sidebarProjectID : null);
  }, [sidebarProjectID]);

  const setTaskLayoutFunc = (value) => {
    setTaskLayout({
        variables: {
          taskLayout: value,
        }
      })
      .catch((err) => addLocalError(err));
  }

  const currentUser = getMyData();

  const loading = (
    companiesLoading ||
    usersLoading ||
    /*  taskTypesLoading || */
    tripTypesLoading ||
    projectsLoading ||
    !currentUser
  );

  const renderCopyButton = () => {
    return (
      <button
        type="button"
        className={classnames("btn-link btn-distance", { 'task-add-layout-button': !noText })}
        disabled={disabled}
        onClick={() => {
          setOpenAddTaskModal(true);
        }}
      >
        <i className="far fa-copy" />
        {!noText && t('copy')}
      </button>
    )
  }

  const renderAddTaskButtonClosedSidebar = () => {
    return (
      <button
        className="btn-link color-white popover-toggler full-width"
        onClick={() => {
          setOpenAddTaskModal(true);
        }}
      >
        <i className="fa fa-plus" />
      </button>
    )
  }


  const renderAddTaskButton = () => {
    return (
      <div className="row">
        <span
          className='btn-link'
          style={{ height: "30px", display: "flex", alignItems: "center", marginTop: "15px", marginLeft: "15px" }}
          onClick={(e) => {
            e.preventDefault();
            toggleSidebar()
          }}
        >
          <i className="fa fa-bars" />
        </span>
        <button
          className="btn sidebar-btn"
          style={{ width: "calc(100% - 61px)" }}
          onClick={() => {
            setOpenAddTaskModal(true);
          }}
        >
          <i className="fa fa-plus" />
          {t('task')}
        </button>
      </div>
    )
  }

  const renderModal = () => {
    if (!openAddTaskModal) {
      return null;
    }
    if (projectID === null) {
      return (
        <ProjectSelectModal
          projects={
            loading ? [] :
              toSelArr(projectsData.myProjects.map((myProject) => ({
                ...myProject.project,
                right: myProject.right,
                attributeRights: myProject.attributeRights,
                users: myProject.usersWithRights.map((userWithRights) => userWithRights.user.id)
              }))).filter((project) => project.right.addTask)
          }
          onSubmit={(projectID) => {
            setProjectID(projectID);
          }}
          closeModal={() => {
            setOpenAddTaskModal(false);
          }}
          loading={loading}
        />
      )
    }

    return (
      <Modal isOpen={openAddTaskModal} className="task-add-container" modalClassName="overflow-x-auto" >
        <ModalBody>
          {openAddTaskModal && !loading &&
            <TaskAdd
              {...props}
              projectID={projectID}
              loading={loading}
              projects={
                toSelArr(projectsData.myProjects.map((myProject) => ({
                  ...myProject.project,
                  right: myProject.right,
                  attributeRights: myProject.attributeRights,
                  users: myProject.usersWithRights
                }))).filter((project) => project.right.addTask)
              }
              myProjects={loading ? [] : projectsData.myProjects}
              users={usersData ? toSelArr(usersData.basicUsers, 'fullName') : []}
              companies={toSelArr(companiesData.basicCompanies)}
              tripTypes={toSelArr(tripTypesData.tripTypes)}
              currentUser={currentUser}
              defaultUnit={null}
              closeModal={(clearProject = false) => {
                setOpenAddTaskModal(false);
                if (!sidebarProjectID || clearProject) {
                  setProjectID(null);
                }
              }}
              addTask={addTask}
              setTaskLayout={setTaskLayoutFunc}
              setAfterTaskCreate={setAfterTaskCreate}
            />
          }
        </ModalBody>
      </Modal>
    )
  }

  if (props.duplicateTask) {
    return (
      <Empty>
        {renderCopyButton()}
        {renderModal()}
      </Empty>
    );
  }
  return (
    <div className="display-inline">
      {
        !props.duplicateTask &&
        sidebarOpen &&
        renderAddTaskButton()
      }

      {
        !props.duplicateTask &&
        !sidebarOpen &&
        renderAddTaskButtonClosedSidebar()
      }

      {renderModal()}

    </div>
  );
}