import React from 'react';
import Empty from 'components/Empty';
import GroupAdd from './groupAdd';
import GroupEdit from './groupEdit';
import {
  defaultGroups,
  createCleanRights
} from 'configs/constants/projects';
import {
  useTranslation
} from "react-i18next";

let fakeID = -(defaultGroups.length + 1);

export default function ProjectGroups(props) {
  const {
    groups,
    addGroup,
    updateGroup,
    deleteGroup,
  } = props;

  const {
    t
  } = useTranslation();

  const [editedGroup, setEditedGroup] = React.useState(null);

  return (
    <Empty>
      <table className="table project-settings bkg-white">
        <thead>
          <tr>
            <th className="font-bold">
              {t('groupTitle')}
            </th>
            <th className="font-bold">
              {t('description')}
            </th>
            <th width="50" className="font-bold">
              {t('order')}
            </th>
            <th width="100" className="font-bold">
              {t('actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {groups.sort((g1, g2) => (parseInt(g1.order) > parseInt(g2.order) ? 1 : -1)).map((group) => (
            <tr key={group.id}>
              <td>
                {t(group.title)}
              </td>
              <td>
                {group.description.length > 0 ? group.description : 'No description'}
              </td>
              <td className="p-l-17">
                {group.order}
              </td>
              {!group.def &&
                <td>
                  <button
                    className="btn-link"
                    onClick={() => setEditedGroup(group)}
                  >
                    {t('edit')}
                  </button>
                  <button
                    className="btn-link-red"
                    onClick={() => deleteGroup(group.id)}
                  >
                    <i className="fa fa-times" />
                  </button>
                </td>
              }
              {group.def &&
                <td>
                  {t('default')}
                </td>
              }
            </tr>
          ))}
        </tbody>
      </table>
      <GroupAdd
        submit={addGroup}
        getFakeId={() => fakeID--}
        reccomendedOrder={groups.length - 1}
      />
      <GroupEdit
        open={editedGroup !== null}
        closeModal={() => setEditedGroup(null)}
        group={editedGroup}
        updateGroup={updateGroup}
      />
    </Empty>
  )
}