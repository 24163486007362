export default {
  close: {
    en: 'Close',
    sk: 'Zavrieť',
    cz: 'Zavřít',
    de: 'Nah dran',
  },
  cancel: {
    en: 'Cancel',
    sk: 'Zrušiť',
    cz: 'Zrušení',
    de: 'Absagen',
  },
  clear: {
    en: 'Clear',
    sk: 'Vyčistiť',
    cz: 'Průhledná',
    de: 'Klar',
  },
  completeDeletion: {
    en: 'Complete deletion',
    sk: 'Dokončiť mazanie',
    cz: 'Dokončete smazání',
    de: 'Vollständige Löschung',
  },
  save: {
    en: 'Save',
    sk: 'Uložiť',
    cz: 'Uložit',
    de: 'Speichern',
  },
  show: {
    en: 'Show',
    sk: 'Zobraziť',
    cz: 'Ukázat',
    de: 'Zeigen',
  },
  invoiceAction: {
    en: 'Invoice',
    sk: 'Vykázať',
    cz: 'Fakturovat',
    de: 'Rechnung',
  },
  saving: {
    en: 'Saving',
    sk: 'Ukladá sa',
    cz: 'Ukládání',
    de: 'Speichern',
  },
  saveChanges: {
    en: 'Save changes',
    sk: 'Uložiť zmeny',
    cz: 'Uložit změny',
    de: 'Änderungen speichern',
  },
  adding: {
    en: 'Adding',
    sk: 'Pridáva sa',
    cz: 'Adding',
    de: 'Hinzufügen',
  },
  edit: {
    en: 'Edit',
    sk: 'Upraviť',
    cz: 'Upravit',
    de: 'Bearbeiten',
  },
  backToTasks: {
    en: 'Back to tasks',
    sk: 'Späť do úloh',
    cz: 'Zpět k úkolům',
    de: 'Zurück zu den Aufgaben',
  },
  cancelChanges: {
    en: 'Cancel changes',
    sk: 'Zrušiť zmeny',
    cz: 'Zrušit změny',
    de: 'Änderungen abbrechen',
  },
  delete: {
    en: 'Delete',
    sk: 'Vymazať',
    cz: 'Vymazat',
    de: 'Löschen',
  },
  add: {
    en: 'Add',
    sk: 'Pridať',
    cz: 'Přidat',
    de: 'Hinzufügen',
  },
  skip: {
    en: 'Skip',
    sk: 'Preskočiť',
    cz: 'Přeskočit',
    de: 'Überspringen',
  },
  select: {
    en: 'Select',
    sk: 'Vybrať',
    cz: 'Vybrat',
    de: 'Auswählen',
  },
  copy: {
    en: 'Copy',
    sk: 'Kopírovať',
    cz: 'kopírovat',
    de: 'Kopieren',
  },
  copying: {
    en: 'Copying',
    sk: 'Kopíruje sa',
    cz: 'Kopírování',
    de: 'Kopieren',
  },
  finishedCopying: {
    en: 'Finished copying!',
    sk: 'Kopírovanie dokončené',
    cz: 'Kopírování dokončeno!',
    de: 'Fertig kopiert!',
  },
  copied: {
    en: 'Copied!',
    sk: 'Skopírované!',
    cz: 'Zkopírováno!',
    de: 'Kopiert!',
  },
  mainPage: {
    en: 'Go to main page',
    sk: 'Prejsť na hlavnú stránku',
    cz: 'Přejít na hlavní stránku',
    de: 'Gehen Sie zur Hauptseite',
  },
}