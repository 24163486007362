import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import CommandBar from './components/commandBar';
import Pagination from './components/pagination';
import ActiveSearch from './components/activeSearch';

import Loading from 'components/loading';

import { timestampToStringFNS } from 'helperFunctions';

import { useTranslation } from 'react-i18next';

export default function ManualsList(props) {
    const {
        loading,
        manuals,
        company,
        setLocalStringFilter,
        localStringFilter,
        setGlobalStringFilter,
    } = props;

    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    let path = `/cmdb/manuals/${params.companyID ? params.companyID : 'all'}/p/${
        params.page ? params.page : 1
    }`;

    return (
        <div>
            <CommandBar {...props} />
            <div className="full-width scroll-visible fit-with-header-and-commandbar-list task-container">
                <div className="row m-l-30">
                    <input
                        type="text"
                        value={localStringFilter.title}
                        placeholder={t('title')}
                        className="form-control width-250"
                        style={{ fontSize: '12px', marginRight: '10px' }}
                        onKeyPress={(e) => {
                            if (e.key === 13 && !loading) {
                                setGlobalStringFilter();
                            }
                        }}
                        onChange={(e) => {
                            setLocalStringFilter('title', e.target.value);
                        }}
                    />
                    <button className="btn" onClick={setGlobalStringFilter}>
                        {t('search')}
                    </button>
                    <button
                        className="btn-link center-hor commandbar-addon m-l-5"
                        onClick={() =>
                            navigate(
                                `/cmdb/manuals/${company.id === null ? 'all' : company.id}/add`
                            )
                        }
                    >
                        <i className="fa fa-plus p-l-5 p-r-5" />
                        {t('manual')}
                    </button>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{ paddingBottom: '12px', paddingTop: '12px' }}>
                                {t('title')}
                            </th>
                            <th width="250" style={{ paddingBottom: '12px', paddingTop: '12px' }}>
                                {t('updatedAt')}
                            </th>
                            <th width="350" style={{ paddingBottom: '12px', paddingTop: '12px' }}>
                                {t('createdAt')}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <ActiveSearch {...props} />

                        {manuals.map((manual) => (
                            <tr
                                key={manual.id}
                                className="clickable noselect"
                                onClick={() => navigate(`${path}/${manual.id}`)}
                            >
                                <td className="font-14-f">{manual.title}</td>
                                <td className="p-l-0">
                                    <span className="">
                                        {manual.createdBy ? `${t('createdBy')} ` : ''}
                                    </span>
                                    <span className="bolder">
                                        {manual.createdBy ? `${manual.createdBy.fullName}` : ''}
                                    </span>
                                    <span className="">
                                        {manual.createdBy ? ` ${t('atDate')} ` : t('createdAt')}
                                    </span>
                                    <span className="bolder">
                                        {manual.createdAt
                                            ? timestampToStringFNS(manual.createdAt)
                                            : ''}
                                    </span>
                                </td>
                                <td className="p-l-0">
                                    <span className="">
                                        {manual.updatedBy ? `${t('changedBy')} ` : ''}
                                    </span>
                                    <span className="bolder">
                                        {manual.updatedBy ? `${manual.updatedBy.fullName}` : ''}
                                    </span>
                                    <span className="">
                                        {manual.updatedBy ? ` ${t('atDate')} ` : t('changedAt')}
                                    </span>
                                    <span className="bolder">
                                        {manual.createdAt
                                            ? timestampToStringFNS(manual.updatedAt)
                                            : ''}
                                    </span>
                                </td>
                            </tr>
                        ))}
                        {manuals.length === 0 && (
                            <tr>
                                <td colSpan="10">{t('noData')}</td>
                            </tr>
                        )}
                        {loading && (
                            <tr>
                                <td colSpan="100">
                                    <Loading noPos flex />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Pagination {...props} />
            </div>
        </div>
    );
}
