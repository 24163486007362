import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  useLocation,
} from 'react-router-dom';
import {
  GET_MY_DATA,
  USER_DATA_SUBSCRIPTION,
} from 'apollo/globalQueries';
import {
  useQuery,
  useMutation,
  useSubscription,
} from "@apollo/client";
import Empty from 'components/Empty';
import {
  getMyData,
} from 'helperFunctions';
import i18n from "i18next";

import Loading from 'components/loading';
import Reroute from 'reroute';
import HelpdeskNavigation from 'helpdesk/navigation';
import InvoicesNavigation from 'invoices/navigation';
import LanwikiNavigation from 'lanwiki/navigation';
import LanpassNavigation from 'lanpass/navigation';
import CMDBNavigation from 'cmdb/navigation';

import {
  LOGOUT_USER
} from 'components/queries';

export default function Navigation(props) {

  //const location = useLocation();

  const {
    data: userDataData,
    loading: userDataLoading,
    refetch: userDataRefetch,
  } = useQuery(GET_MY_DATA, {
    fetchPolicy: 'network-only'
  });

  useSubscription(USER_DATA_SUBSCRIPTION, {
    onData: () => {
      userDataRefetch()
    }
  });

  const [logoutUser] = useMutation(LOGOUT_USER);

  const currentUser = getMyData();
  React.useEffect(() => {
    if (currentUser) {
      i18n.changeLanguage(currentUser.language);
    }
  }, [currentUser, currentUser ? currentUser.language : null]);
  if (!userDataLoading && currentUser === null) {
    logoutUser()
      .then(() => {
        // TODO: useLocation() may be used only in the context of a <Router> component.
        //location.reload( false );
      });
    return null;
  }
  if (userDataLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={<Reroute />}
        />

        <Route
          path="*"
          element={null}
        />
      </Routes>

      <HelpdeskNavigation />

      {
        userDataData.getMyData.role.accessRights.lanwiki &&
        <LanwikiNavigation />
      }

      {
        userDataData.getMyData.role.accessRights.pass &&
        <LanpassNavigation />
      }

      {
        userDataData.getMyData.role.accessRights.cmdb &&
        <CMDBNavigation />
      }


      {/*
          false &&
          <Route
            path='invoices/*'
            element={
              <Empty>
                <InvoicesNavigation />
                <Outlet />
              </Empty>
            }
            />
          */}

    </BrowserRouter>
  )
}