export default {
  January: {
    en: 'January',
    sk: 'Január',
    cz: 'Leden',
    de: 'Januar',
  },
  February: {
    en: 'February',
    sk: 'Február',
    cz: 'Únor',
    de: 'Februar',
  },
  March: {
    en: 'March',
    sk: 'Marec',
    cz: 'Březen',
    de: 'Marsch',
  },
  April: {
    en: 'April',
    sk: 'Apríl',
    cz: 'Buben',
    de: 'April',
  },
  May: {
    en: 'May',
    sk: 'Marec',
    cz: 'Květen',
    de: 'Mai',
  },
  June: {
    en: 'June',
    sk: 'Jún',
    cz: 'Červen',
    de: 'Juni',
  },
  July: {
    en: 'July',
    sk: 'Júl',
    cz: 'Červenec',
    de: 'Juli',
  },
  August: {
    en: 'August',
    sk: 'August',
    cz: 'Srpen',
    de: 'August',
  },
  September: {
    en: 'September',
    sk: 'September',
    cz: 'Září',
    de: 'September',
  },
  October: {
    en: 'October',
    sk: 'Október',
    cz: 'Říjen',
    de: 'Oktober',
  },
  November: {
    en: 'November',
    sk: 'November',
    cz: 'Listopad',
    de: 'November',
  },
  December: {
    en: 'December',
    sk: 'December',
    cz: 'Prosinec',
    de: 'Dezember',
  },
}
