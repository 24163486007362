import React from 'react';
import {
  useTranslation
} from "react-i18next";
import {
  useQuery,
  useSubscription,
} from "@apollo/client";
import Loading from 'components/loading';
import {} from './queries';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import {
  toSelArr,
} from 'helperFunctions';
import Empty from 'components/Empty';
import ProjectStatistics from './project';
import CustomerStatistics from './customer';
import TaskStatistics from './task';

import {
  getMyData,
} from 'helperFunctions';
import {
  GET_MY_PROJECTS,
  PROJECTS_SUBSCRIPTION,
} from 'helpdesk/settings/projects/queries';
import {
  GET_BASIC_COMPANIES,
  COMPANIES_SUBSCRIPTION,
} from 'helpdesk/settings/companies/queries';

export default function Statistics() {

  const {
    t
  } = useTranslation();

  const {
    data: myProjectsData,
    loading: myProjectsLoading,
    refetch: myProjectsRefetch,
  } = useQuery(GET_MY_PROJECTS, {
    fetchPolicy: 'network-only'
  });

  useSubscription(PROJECTS_SUBSCRIPTION, {
    onData: () => {
      myProjectsRefetch();
    }
  });

  const {
    data: basicCompaniesData,
    loading: basicCompaniesLoading,
    refetch: basicCompaniesRefetch,
  } = useQuery(GET_BASIC_COMPANIES, {
    fetchPolicy: 'network-only'
  });
  useSubscription(COMPANIES_SUBSCRIPTION, {
    onData: () => {
      basicCompaniesRefetch();
    }
  });

  //load projects and companies

  const currentUser = getMyData();
  const accessRights = currentUser && currentUser.role ? currentUser.role.accessRights : {};

  const [tab, setTab] = React.useState(1);

  if (myProjectsLoading || basicCompaniesLoading) {
    return (<Loading />);
  }
  return (
    <div className="p-20">
      <h2>{t('statistics')}</h2>
      <Nav tabs>
        {accessRights.statisticsProject &&
          <Empty>
            <NavItem className="clickable noselect">
              <NavLink onClick={() => setTab(1)} active={tab === 1} className="font-17">{t('projects')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink>|</NavLink>
            </NavItem>
          </Empty>
        }
        {accessRights.statisticsCustomer &&
          <Empty>
            <NavItem className="clickable noselect">
              <NavLink onClick={() => setTab(2)} active={tab === 2} className="font-17">{t('customers')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink>|</NavLink>
            </NavItem>
          </Empty>
        }
        {accessRights.statisticsTasks &&
          <NavItem className="clickable noselect">
            <NavLink onClick={() => setTab(3)} active={tab === 3} className="font-17">{t('tasks')}</NavLink>
          </NavItem>
        }
      </Nav>
      <TabContent activeTab={tab}>
        {accessRights.statisticsProject &&
          <TabPane tabId={1}>
            <ProjectStatistics projects={toSelArr(myProjectsData.myProjects.map((myProject) => myProject.project))} />
          </TabPane>
        }

        {accessRights.statisticsCustomer &&
          <TabPane tabId={2}>
            <CustomerStatistics companies={toSelArr(basicCompaniesData.basicCompanies)} />
          </TabPane>
        }

        {accessRights.statisticsTasks &&
          <TabPane tabId={3}>
            <TaskStatistics projects={toSelArr(myProjectsData.myProjects.map((myProject) => myProject.project))} />
          </TabPane>
        }
      </TabContent>
    </div>
  );
}