import React, {
  Component
} from 'react';
import ReactToPrint from 'react-to-print';
import VykazyTablePrint from 'helpdesk/components/vykazy/vykazyTablePrint';
import SubtasksPrint from './subtasks';
import ShortSubtasksPrint from './shortSubtasks';
import MaterialsPrint from './materials';
import {
  intervals
} from 'configs/constants/repeat';
import {
  timestampToStringFNS
} from 'helperFunctions';

export default class TaskPrint extends Component {
  constructor( props ) {
    super( props );

    this.state = {};
  }
  render() {
    return (
      <div style={{display: "flex", alignItems: "center"}}>
					<ReactToPrint
						trigger={() =>
							<button className="btn-link btn-distance" disabled={!this.props.isLoaded}>
								<i
									className="fas fa-print"
									/> Print
							</button>
						}
						content={() => this.componentRef}
					/>

				<div style={{display: "none"}}>
					<TaskInfo ref={el => (this.componentRef = el)} {...this.props}/>
				</div>
			</div>
    )
  }
}

class TaskInfo extends Component {

  render() {
    if ( !this.props.isLoaded ) {
      return null;
    }

    let repeatInterval = null;// ( this.props.repeat ? intervals.find( ( interval ) => interval.title === this.props.repeat.repeatInterval ) : null );
    const task = this.props.task;
    const customAttributes = this.props.customAttributes;
    const t = this.props.t;

    function getCustomAttributeDisplayValue(customAttribute){
      if (["text", "textarea"].includes(customAttribute.type)){
        return customAttribute.value.text;
      }
      if (["number"].includes(customAttribute.type)){
        return customAttribute.value.number;
      }
      return customAttribute.value.selectValues ? customAttribute.value.selectValues.map((value) => value.value).join(", ") : "";
    }

    function getRepeatInterval(repeat){
      const interval = intervals.find( ( interval ) => interval.value === repeat.repeatInterval );
      return t(interval.value).toLowerCase();
    }

    return (
      <div className="m-100 print">
					<div className="row flex">
						<h2 className="center-hor text-extra-slim">{`${task.id}: ${task.title}`}</h2>

						<div className="ml-auto center-hor">
							<p className="m-b-0 task-info">
								<span className="color-muted">
									{t('createdBy')}
								</span>
								{`${task.createdBy ? task.createdBy.name : ""} ${task.createdBy ? task.createdBy.surname : ""}`}
								<span className="color-muted">
                  {` ${t('atDate')} ${timestampToStringFNS(task.createdAt)}`}
								</span>
							</p>
							<p className="m-b-0">
								{(()=>{
									if(task.status && task.status.action==='PendingDate'){
										return (
											<span className="color-muted task-info m-r--40">
												<span className="center-hor">
													{`${t('pendingDate')}: ${task.pendingDate ? timestampToStringFNS(task.pendingDate) : "No pending date"}`}
												</span>
											</span>)
								}else if(task.status && (task.status.action==='CloseDate'||task.status.action==='Invoiced'||task.status.action==='CloseInvalid')){
									return (
										<span className="color-muted task-info m-r--40">
											<span className="center-hor">
												{`${t('closedDate')}: ${timestampToStringFNS(task.closeDate)}`}
											</span>
										</span>)
									}else{
										return (
											<span className="task-info ">
												<span className="center-hor color-muted">
													{task.statusChange ? (`${t('statusChangedAt')} ${timestampToStringFNS(task.statusChange)}`) : ""}
												</span>
											</span>
										)
									}
								})()}
							</p>
						</div>
					</div>

          <hr className="m-b-10"/>

            <div className="info-bar">

              {/*Project*/}
              {
                task.project &&
                <div>
                  <label>{t('project')}</label>
                  <span>
                    {`${task.project ? task.project.title : t('noProject')}`}
                  </span>
                </div>
              }
              {/*Status*/}
              {
                task.status &&
                <div>
                  <label>{t('status')}</label>
                  <span>
                    {`${task.status ? task.status.title : t('noStatus')}`}
                  </span>
                </div>
              }
              {/*Requester*/}
              {
                task.requester &&
              <div>
                <label>{t('requester')}</label>
                <span>
                  {`${task.requester ? task.requester.fullName : t('noRequester')}`}
                </span>
              </div>
            }
              {/*Company*/}
              <div>
                <label>{t('company')}</label>
                <span>
                  {`${task.company ? task.company.title : t('noCompany')}`}
                </span>
              </div>
              {/*Assigned*/}
              {
                task.assignedTo.length &&
                <div>
                  <label>{t('assignedTo')}</label>
                  <span>
                    {`${task.assignedTo.length > 0 ? task.assignedTo.map(a => a.fullName).join(", ") : t('taskNotAssigned')}`}
                  </span>
                </div>
              }
              {/*Planned*/}
              {
                task.startsAt &&
              <div>
                <label>{t('plannedAt')}</label>
                <span>
                  {`${task.startsAt ? timestampToStringFNS(task.startsAt.valueOf()) : t('noPlannedAt')}`}
                </span>
              </div>
            }
              {/*deadline*/}
              {
                task.deadline &&
              <div>
                <label>{t('deadline')}</label>
                <span>
                  {`${task.deadline ? timestampToStringFNS(task.deadline) : t('noDeadline')}`}
                </span>
              </div>
            }
              {/*repeat*/}
              {
                task.repeat &&
              <div>
                <label>{t('repeat')}</label>
                <span>
                  {task.repeat ? `${t('repeatEvery')} ${task.repeat.repeatEvery} ${getRepeatInterval(task.repeat)} ${t('startsAt').toLowerCase()} ${timestampToStringFNS(task.repeat.startsAt)}` : t('noRepeat')}
                </span>
              </div>
            }

              {/*customAttributes*/}
              {
                customAttributes.map((customAttribute) => (
                  <div key={customAttribute.id}>
                      <label>{customAttribute.label}</label>
                      <span>
                        {getCustomAttributeDisplayValue(customAttribute)}
                      </span>
                  </div>
                ))
              }

          </div>

          {/*tags*/}
          {
            task.tags &&
          <div className="print-tags">
            <label>{t('tags')}</label>
            <span>
              {`${task.tags.length ? task.tags.map((t) => t.title).join(", ") : t('noTags')}`}
            </span>
          </div>
        }

          <div className="print-description">
          <label>{t('description')}</label>
          {(task.description && task.description.length !== 0 ?
            <div dangerouslySetInnerHTML={{__html:task.description }} /> :
              <div>{t('noTaskDescription')}</div>
            )}
          </div>

          <div className="print-attachments">
            <label>{t('taskAttachments')}</label>
            <span>
              {`${task.taskAttachments && task.taskAttachments.length > 0 ? task.taskAttachments.map((a) => a.filename).join(", ") : t('noTaskAttachments')}`}
            </span>
          </div>

          <ShortSubtasksPrint {...this.props} />

          <SubtasksPrint {...this.props} />

          <MaterialsPrint {...this.props} />

				</div>
    );
  }
}
