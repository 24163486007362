import React from 'react';
import Select, {
  Creatable
} from 'react-select';
import {
  pickSelectStyle,
} from 'configs/components/select';
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import Empty from 'components/Empty';
import {
  useQuery,
} from "@apollo/client";
import {
  filterUnique,
  toSelArr,
  toSelItem
} from 'helperFunctions';

import {
  GET_BASIC_ROLES,
} from 'helpdesk/settings/roles/queries';

import Checkbox from 'components/checkbox';

import {
  useTranslation
} from "react-i18next";

const types = [
  {
    label: 'Text',
    value: 'text'
  },
  {
    label: 'Textarea',
    value: 'textarea'
  },
  {
    label: 'Number - whole',
    value: 'integer'
  },
  {
    label: 'Number - decimal',
    value: 'decimal'
  },
  {
    label: 'Select - single',
    value: 'select'
  },
  {
    label: 'Select - multi',
    value: 'multiselect'
  }
]

export default function GeneralFields( props ) {

  const {
    type,
    groups,
    order,
    setOrder,
    title,
    setTitle,
    required,
    setRequired,
    options,
    read,
    setRead,
    add,
    setAdd,
    write,
    setWrite
  } = props;

  const {
    t
  } = useTranslation();

  const cantSave = (
    isNaN( parseInt( order ) ) ||
    title.length === 0 ||
    type === null ||
    ( options && options.length < 2 ) ||
    read.length === 0
  )

  return (
    <Empty>
          <FormGroup>
            <Label>{t('title')}</Label>
            <input
              className="form-control"
              value={title}
              onChange={e => setTitle(e.target.value)}
              />
          </FormGroup>
          <FormGroup>
            <Label>{t('order')}</Label>
            <input
              className="form-control"
              type="number"
              value={order}
              onChange={e => setOrder(e.target.value)}
              />
          </FormGroup>
          <Checkbox
            className="m-t-5"
            label={t("required")}
            value={  required }
            onChange={ () => {
              if (!required){
                setAdd(toSelArr(groups));
              }
              setRequired(!required)
            }}
            />
          <FormGroup>
            <Label>{t('rightToAdd')}</Label>
            <Select
              styles={pickSelectStyle()}
              options={toSelArr(groups)}
              value={toSelArr(add)}
              isMulti
              onChange={ (addRights) => {
                if (!required){                  
                  setAdd(addRights);
                }
              }}
              />
          </FormGroup>
          <FormGroup>
            <Label>{t('rightToRead')}</Label>
            <Select
              styles={pickSelectStyle()}
              options={toSelArr(groups)}
              value={toSelArr(read)}
              isMulti
              onChange={ (readRights) => {
                setRead(readRights);
                setWrite(write.filter((writeRight) => readRights.some((readRight) => readRight.id === writeRight.id ) ))
              }}
              />
          </FormGroup>
          <FormGroup>
            <Label>{t('rightToWrite')}</Label>
            <Select
              styles={pickSelectStyle()}
              options={toSelArr(groups)}
              value={toSelArr(write)}
              isMulti
              onChange={ (writeRights) => {
                setWrite(writeRights);
                for (var i = 0; i < writeRights.length; i++) {
                  if (!read.find((right) => right.id === writeRights[i].id)){
                    setRead([...toSelArr(read), {...writeRights[i]}])
                  }
                }
              }}
              />
          </FormGroup>
          </Empty>

  );
}
