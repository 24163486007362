import React from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Empty from 'components/Empty';
import ErrorIcon from 'components/errorMessages/errorIcon';
import NotificationIcon from 'components/notifications/notificationIcon';
import LocalErrors from 'components/localErrors';
import classnames from 'classnames';
import { getLocation, getMyData } from 'helperFunctions';
import { useTranslation } from 'react-i18next';

import UserProfile from 'helpdesk/settings/users/userProfile';

import { LOGOUT_USER } from './queries';

export default function PageHeader() {
    //data & queries
    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation();

    const [logoutUser] = useMutation(LOGOUT_USER);

    //state
    const [modalUserProfileOpen, setModalUserProfileOpen] = React.useState(false);

    const currentUser = getMyData();
    const accessRights = currentUser && currentUser.role ? currentUser.role.accessRights : {};

    const URL = getLocation(location);
    return (
        <div className={classnames('page-header flex m-l-30')}>
            <div className="d-flex full-height">
                <div className="center-hor"></div>

                {false && (
                    <div className="center-hor">
                        <Link
                            to={{ pathname: `/lanwiki/i/all` }}
                            className={
                                'header-link' +
                                (URL.includes('lanwiki') ? ' header-link-active' : '')
                            }
                        >
                            {t('lanWiki')}
                        </Link>
                        <Link
                            to={{ pathname: `/helpdesk/taskList/i/all` }}
                            className={
                                'header-link' +
                                (URL.includes('helpdesk/taskList') ? ' header-link-active' : '')
                            }
                        >
                            {t('tasks')}
                        </Link>
                        {accessRights.vykazy && (
                            <Empty>
                                <Link
                                    to={{ pathname: `/reports` }}
                                    className={
                                        'header-link' +
                                        (URL.includes('reports') ? ' header-link-active' : '')
                                    }
                                >
                                    {t('invoices')}
                                </Link>
                                <Link
                                    to={{ pathname: `/cmdb` }}
                                    className={
                                        'header-link' +
                                        (URL.includes('cmdb') ? ' header-link-active' : '')
                                    }
                                >
                                    {t('cmdb')}
                                </Link>
                            </Empty>
                        )}
                    </div>
                )}

                <div className="ml-auto center-hor row m-r-30">
                    <div
                        className=" header-icon center-hor clickable"
                        onClick={() => setModalUserProfileOpen(true)}
                    >
                        {!currentUser
                            ? `${t('loading')}...`
                            : `${currentUser.name} ${currentUser.surname}`}
                        <i className="fas fa-user m-l-5" />
                    </div>

                    <LocalErrors />
                    {accessRights.viewErrors && <ErrorIcon location={URL} />}

                    <NotificationIcon location={URL} />
                    <i
                        className="header-icon clickable fa fa-sign-out-alt center-hor"
                        onClick={() => {
                            if (window.confirm(t('logoutConfirmation'))) {
                                logoutUser().then(() => {
                                    navigate('/helpdesk/taskList/i/all');
                                    window.location.reload(false);
                                });
                            }
                        }}
                    />
                </div>
            </div>

            <Modal style={{ width: '800px' }} isOpen={modalUserProfileOpen}>
                <ModalHeader>{t('userProfile')}</ModalHeader>
                <ModalBody>
                    <UserProfile closeModal={() => setModalUserProfileOpen(false)} />
                </ModalBody>
            </Modal>
        </div>
    );
}
