export default {
  webchat: {
    en: 'WebChat',
    sk: 'WebChat',
    cz: 'WebChat',
    de: 'WebChat',
  },
  addComment: {
    en: 'Add comment',
    sk: 'Pridať komentár',
    cz: 'Přidat komentář',
    de: 'Einen Kommentar hinzufügen',
  },
  submit: {
    en: 'Submit',
    sk: 'Odoslať',
    cz: 'Předložit',
    de: 'Einreichen',
  },
  endConversation: {
    en: 'End conversation',
    sk: 'Ukončiť konverzáciu',
    cz: 'Ukončete konverzaci',
    de: 'Gespräch beenden',
  },
  cantSendEmpty: {
    en: 'Cannot send an empty comment!',
    sk: 'Nedá sa poslať prázdny komentár!',
    cz: 'Nelze odeslat prázdný komentář!',
    de: 'Es kann kein leerer Kommentar gesendet werden!',
  },
  closed: {
    en: 'Closed',
    sk: 'Uzavreté',
    cz: 'Zavřeno',
    de: 'Abgeschlossen',
  },
  reopenConversation: {
    en: 'Re-open conversation',
    sk: 'Otvoriť konverzáciu',
  },
  placeIframe: {
    en: 'Place this code on your website for a chat window.',
    sk: 'Umiestnite tento kód na svoju webovú stránku pre okno rozhovoru.',
    cz: 'Umístěte tento kód na svůj web pro okno chatu.',
    de: 'Platzieren Sie diesen Code auf Ihrer Website für ein Chat-Fenster.',
  },
  generateNewToken: {
    en: 'Generate a new token',
    sk: 'Vygenerujte nový token',
    cz: 'Vygenerujte nový token',
    de: 'Neues Token generieren',
  },
  generatingNewToken: {
    en: 'Generating a new token',
    sk: 'Generovanie nového tokenu',
    cz: 'Generování nového tokenu',
    de: 'Generieren eines neuen Tokens',
  },
  oldTokenWontWork: {
    en: 'If you generate a new token, you will have to replace the webchat code on your site with the new one generated above. All the other webchat windows will cease to work.',
    sk: 'Ak vygenerujete nový token, budete musieť nahradiť kód webchatu na svojej stránke novým kódom vygenerovaným vyššie. Všetky ostatné okná webchatu prestanú fungovať.',
    cz: 'Pokud vygenerujete nový token, budete muset nahradit kód webchatu na vašem webu novým kódem vygenerovaným výše. Všechna ostatní okna webového chatu přestanou fungovat.',
    de: 'Wenn Sie ein neues Token generieren, müssen Sie den Webchat-Code auf Ihrer Website durch den oben generierten ersetzen. Alle anderen Webchat-Fenster funktionieren nicht mehr.',
  },
}