import {
  gql
} from '@apollo/client';

export const GET_WEBCHAT_TOKEN = gql`
  query webchatToken($forceNew: Boolean!) {
    webchatToken (
      forceNew: $forceNew,
    )
  }
`;