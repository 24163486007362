import React from 'react';

import {
  Route,
  Routes,
  Outlet,
} from 'react-router-dom';

import ManualsList from './manuals/list';
import ManualAdd from './manuals/add';
import ManualEdit from './manuals/edit';
import PasswordsList from './passwords/list';
import PasswordAdd from './passwords/add';
import PasswordEdit from './passwords/edit';
import SchemeLoader from './scheme';
import ItemsList from './items/list';
import ItemView from './items/edit';
import AccessDenied from 'components/accessDenied';
import Sidebar from 'cmdb/components/sidebar';
import ErrorMessages from 'components/errorMessages';
import NotificationList from 'components/notifications';
import PageHeader from 'components/PageHeader';
import SelectPage from 'components/SelectPage';
import RepeatsList from 'helpdesk/components/repeat/repeatsList';

import {
  getMyData,
} from 'helperFunctions';

export default function CMDBNavigation() {

  const currentUser = getMyData();
  const accessRights = currentUser && currentUser.role ? currentUser.role.accessRights : {};

  return (
    <Routes>
      <Route
        path='cmdb/*'
        element={
          <div>
            <div className="page-header">
              <div className="center-ver row center flex">
                <SelectPage />
                <PageHeader />
              </div>
            </div>

            <div className="row center center-ver">
              <Sidebar />
              <div className="main">
                <Outlet />
              </div>
            </div>
          </div>
        }
        >

        <Route
          path="errorMessages"
          element={accessRights.viewErrors ? <ErrorMessages /> : <AccessDenied />}
          />
        <Route
          path="notifications"
          element={<NotificationList />}
          />
        <Route
          path="notifications/:notificationID"
          element={<NotificationList />}
          />

        <Route
          path='scheme/:companyID'
          element={<SchemeLoader />}
          />

        <Route
          path='manuals/:companyID'
          element={<ManualsList />}
          />

        <Route
          path='manuals/:companyID/p/:page'
          element={<ManualsList />}
          />

        <Route
          path='manuals/:companyID/p/:page/:manualID'
          element={<ManualEdit />}
          />

        <Route
          path='manuals/:companyID/add'
          element={<ManualAdd />}
          />


        <Route
          path='passwords/:companyID'
          element={<PasswordsList />}
          />

        <Route
          path='passwords/:companyID/p/:page'
          element={<PasswordsList />}
          />

        <Route
          path='passwords/:companyID/p/:page/:passwordID'
          element={<PasswordEdit />}
          />

        <Route
          path='passwords/:companyID/add'
          element={<PasswordAdd />}
          />


        <Route
          path='i/:categoryID'
          element={<ItemsList />}
          />

        <Route
          path='i/:categoryID/p/:page'
          element={<ItemsList />}
          />
        <Route
          path='i/:categoryID/p/:page/:itemID'
          element={<ItemView />}
          />

        <Route
          path='repeats/i/:categoryID'
          element={<RepeatsList />}
          />

      </Route>

      <Route
        path="*"
        element={null}
        />

    </Routes>
  );
}
