import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import {
    FormGroup,
    Label,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import DatePicker from 'components/DatePicker';
import Loading from 'components/loading';
import Select from 'react-select';
import Empty from 'components/Empty';

import {
    toSelArr,
    toSelItem,
    filterUnique,
    timestampToStringFNS,
    fromUnixToDate,
} from 'helperFunctions';
import { pickSelectStyle } from 'configs/components/select';
import * as fns from 'date-fns';

import {
    AreaChart,
    Area,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

import {
    AI_AUTOFILLS,
    AI_AUTOFILL_STATISTIC,
} from './queries';

import { useTranslation } from 'react-i18next';

export default function AIStatistics() {
    const { t } = useTranslation();

    const [tab, setTab] = React.useState(1);
    const [chosenAutofill, setChosenAutofill] =
        React.useState({});
    const [dateFrom, setDateFrom] = React.useState(
        fns.startOfMonth(new Date())
    );
    const [dateTo, setDateTo] = React.useState(
        fns.endOfMonth(new Date())
    );

    const {
        data: aiAutofillsData,
        loading: aiAutofillsLoading,
    } = useQuery(AI_AUTOFILLS);

    const {
        data: aiAutofillStatisticsData,
        loading: aiAutofillStatisticsLoading,
        refetch: aiAutofillStatisticsRefetch,
    } = useQuery(AI_AUTOFILL_STATISTIC, {
        fetchPolicy: 'network-only',
        variables: {
            aiAutofillId: chosenAutofill.id
                ? chosenAutofill.id
                : -1,
            dateFrom: dateFrom.valueOf().toString(),
            dateTo: dateTo.valueOf().toString(),
        },
    });

    useEffect(() => {
        if (
            aiAutofillsData &&
            aiAutofillsData.aiAutofills &&
            aiAutofillsData.aiAutofills.length > 0
        ) {
            setChosenAutofill(
                toSelItem(aiAutofillsData.aiAutofills[0])
            );
        } else {
            setChosenAutofill({});
        }
    }, [aiAutofillsData]);

    useEffect(() => {
        aiAutofillStatisticsRefetch({
            aiAutofillId: chosenAutofill.id
                ? chosenAutofill.id
                : -1,
            dateFrom: dateFrom.valueOf().toString(),
            dateTo: dateTo.valueOf().toString(),
        });
    }, [chosenAutofill, dateFrom, dateTo]);

    const graph1Data = useMemo(() => {
        if (aiAutofillStatisticsData) {
            return [
                {
                    date: fns.format(dateFrom, 'dd.MM'),
                    value: 0,
                },
                ...aiAutofillStatisticsData.aiAutofillStatistic.map(
                    (statistic) => ({
                        //date: moment(parseInt(statistic.closeDate)).format("DD.MM."),
                        date: timestampToStringFNS(
                            statistic.closeDate,
                            'dd.MM.'
                        ),
                        //value: moment(parseInt(statistic.closeDate)).diff(moment(parseInt(statistic.createdAt)), 'hours'),
                        value: fns.differenceInHours(
                            fromUnixToDate(
                                statistic.closeDate
                            ),
                            fromUnixToDate(
                                statistic.createdAt
                            )
                        ),
                    })
                ),
                {
                    date: fns.format(dateTo, 'dd.MM'),
                    value: 0,
                },
            ];
        }
        return [
            {
                date: fns.format(dateFrom, 'dd.MM'),
                value: 0,
            },
            {
                date: fns.format(dateTo, 'dd.MM'),
                value: 0,
            },
        ];
    }, [aiAutofillStatisticsData]);

    const graph2Data = useMemo(() => {
        if (aiAutofillStatisticsData) {
            return [
                {
                    date: fns.format(dateFrom, 'dd.MM'),
                    value: 0,
                },
                ...aiAutofillStatisticsData.aiAutofillStatistic.map(
                    (statistic) => ({
                        //date: moment(parseInt(statistic.closeDate)).format("DD.MM."),
                        date: timestampToStringFNS(
                            statistic.closeDate,
                            'dd.MM.'
                        ),
                        value: statistic.subtaskValues,
                    })
                ),
                {
                    date: fns.format(dateTo, 'dd.MM'),
                    value: 0,
                },
            ];
        }
        return [
            {
                date: fns.format(dateFrom, 'dd.MM'),
                value: 0,
            },
            {
                date: fns.format(dateTo, 'dd.MM'),
                value: 0,
            },
        ];
    }, [aiAutofillStatisticsData]);

    if (aiAutofillsLoading) {
        return <Loading />;
    }

    const getAverageInterval = () => {
        if (
            !aiAutofillStatisticsData ||
            !aiAutofillStatisticsData.aiAutofillStatistic ||
            aiAutofillStatisticsData.aiAutofillStatistic
                .length === 0
        ) {
            return 0;
        }
        //return aiAutofillStatisticsData.aiAutofillStatistic.map((statistic) => moment(parseInt(statistic.closeDate)).diff(moment(parseInt(statistic.createdAt)), 'hours')).reduce((a, b) => a + b, 0) / aiAutofillStatisticsData.aiAutofillStatistic.length;
        return (
            aiAutofillStatisticsData.aiAutofillStatistic
                .map((statistic) =>
                    fns.differenceInHours(
                        fromUnixToDate(statistic.closeDate),
                        fromUnixToDate(statistic.createdAt)
                    )
                )
                .reduce((a, b) => a + b, 0) /
            aiAutofillStatisticsData.aiAutofillStatistic
                .length
        );
    };

    const getAverageSubtaskValue = () => {
        if (
            !aiAutofillStatisticsData ||
            !aiAutofillStatisticsData.aiAutofillStatistic ||
            aiAutofillStatisticsData.aiAutofillStatistic
                .length === 0
        ) {
            return 0;
        }
        return (
            aiAutofillStatisticsData.aiAutofillStatistic
                .map((statistic) => statistic.subtaskValues)
                .reduce((a, b) => a + b, 0) /
            aiAutofillStatisticsData.aiAutofillStatistic
                .length
        );
    };

    const CustomTooltip = ({ payload, label, active }) => {
        if (active && payload && label) {
            return (
                <div
                    style={{
                        border: '0px',
                        outline: 'none',
                    }}
                >{`${payload[0].value}h`}</div>
            );
        }

        return null;
    };

    return (
        <div className="scroll-visible p-20 fit-with-header">
            <h2 className="m-b-20">{`${t(
                'aiStatistics'
            )}`}</h2>
            <FormGroup className="flex-row width-550">
                <Label className="center-hor m-r-10 width-150">
                    {t('Training task')}
                </Label>
                <Select
                    styles={pickSelectStyle(['flex'])}
                    options={
                        aiAutofillsData &&
                        aiAutofillsData.aiAutofills
                            ? toSelArr(
                                  aiAutofillsData.aiAutofills
                              )
                            : []
                    }
                    value={chosenAutofill}
                    onChange={(newValue) => {
                        setChosenAutofill(newValue);
                    }}
                />
            </FormGroup>
            <FormGroup className="flex-row width-550">
                <Label className="center-hor m-r-10 width-150">
                    {t('period')}
                </Label>
                <div className="flex-row">
                    <Label className="center-hor m-r-10">
                        {t('from')}:
                    </Label>
                    <DatePicker
                        className="form-control"
                        selected={dateFrom}
                        hideTime={true}
                        onChange={(date) => {
                            setDateFrom(date);
                        }}
                    />
                    <Label className="center-hor m-l-10 m-r-10">
                        {t('to')}:
                    </Label>
                    <DatePicker
                        className="form-control"
                        selected={dateTo}
                        hideTime={true}
                        onChange={(date) => {
                            setDateTo(date);
                        }}
                    />
                </div>
            </FormGroup>
            <Nav tabs>
                <Empty>
                    <NavItem className="clickable noselect">
                        <NavLink
                            onClick={() => setTab(1)}
                            active={tab === 1}
                            className="font-17"
                        >
                            {t('intervalFromCreateToClose')}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink>|</NavLink>
                    </NavItem>
                </Empty>
                <Empty>
                    <NavItem className="clickable noselect">
                        <NavLink
                            onClick={() => setTab(2)}
                            active={tab === 2}
                            className="font-17"
                        >
                            {t('workTime')}
                        </NavLink>
                    </NavItem>
                </Empty>
            </Nav>
            <TabContent activeTab={tab}>
                <TabPane tabId={1}>
                    {aiAutofillStatisticsLoading && (
                        <Loading />
                    )}

                    {!aiAutofillStatisticsLoading && (
                        <ResponsiveContainer
                            width="100%"
                            height={300}
                        >
                            <AreaChart
                                data={graph1Data}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip
                                    content={
                                        <CustomTooltip />
                                    }
                                    wrapperStyle={{
                                        padding: 10,
                                        backgroundColor:
                                            '#ccc',
                                        border: '0px',
                                        outline: 'none',
                                    }}
                                />
                                <Area
                                    type="monotone"
                                    dataKey="value"
                                    stroke="#8884d8"
                                    fill="#8884d8"
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    )}

                    {!aiAutofillStatisticsLoading && (
                        <div>
                            <div className="flex-row m-t-50">
                                <div className="statistics-box">
                                    {t('totalClosedTasks')}
                                    <div className="statistics-box-value">
                                        {aiAutofillStatisticsData &&
                                            aiAutofillStatisticsData.aiAutofillStatistic &&
                                            aiAutofillStatisticsData
                                                .aiAutofillStatistic
                                                .length >
                                                0 &&
                                            filterUnique(
                                                aiAutofillStatisticsData.aiAutofillStatistic,
                                                'taskId'
                                            ).length}
                                        {(!aiAutofillStatisticsData ||
                                            !aiAutofillStatisticsData.aiAutofillStatistic ||
                                            aiAutofillStatisticsData
                                                .aiAutofillStatistic
                                                .length ===
                                                0) &&
                                            0}
                                    </div>
                                </div>
                                <div className="statistics-box">
                                    {t('maxInterval')}
                                    <div className="statistics-box-value">
                                        {`${
                                            chosenAutofill.maxSinceCreation
                                        } ${t(
                                            'hoursShort'
                                        )}`}
                                    </div>
                                </div>
                                <div
                                    className="statistics-box"
                                    style={{
                                        backgroundColor:
                                            '#FF931E',
                                        textShadow:
                                            '3px 3px #b87124',
                                    }}
                                >
                                    {t('averageInterval')}
                                    <div className="statistics-box-value">
                                        {`${getAverageInterval()} ${t(
                                            'hoursShort'
                                        )}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </TabPane>

                <TabPane tabId={2}>
                    {aiAutofillStatisticsLoading && (
                        <Loading />
                    )}

                    {!aiAutofillStatisticsLoading && (
                        <ResponsiveContainer
                            width="100%"
                            height={300}
                        >
                            <AreaChart
                                data={graph2Data}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip
                                    content={
                                        <CustomTooltip />
                                    }
                                    wrapperStyle={{
                                        padding: 10,
                                        backgroundColor:
                                            '#ccc',
                                        border: '0px',
                                        outline: 'none',
                                    }}
                                />
                                <Area
                                    type="monotone"
                                    dataKey="value"
                                    stroke="#8884d8"
                                    fill="#8884d8"
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    )}
                    {!aiAutofillStatisticsLoading && (
                        <div>
                            <div className="flex-row m-t-50">
                                <div className="statistics-box">
                                    {t('totalClosedTasks')}
                                    <div className="statistics-box-value">
                                        {aiAutofillStatisticsData &&
                                            aiAutofillStatisticsData.aiAutofillStatistic &&
                                            aiAutofillStatisticsData
                                                .aiAutofillStatistic
                                                .length >
                                                0 &&
                                            filterUnique(
                                                aiAutofillStatisticsData.aiAutofillStatistic,
                                                'taskId'
                                            ).length}
                                        {(!aiAutofillStatisticsData ||
                                            !aiAutofillStatisticsData.aiAutofillStatistic ||
                                            aiAutofillStatisticsData
                                                .aiAutofillStatistic
                                                .length ===
                                                0) &&
                                            0}
                                    </div>
                                </div>
                                <div className="statistics-box">
                                    {t('maxSubtaskValues')}
                                    <div className="statistics-box-value">
                                        {`${
                                            chosenAutofill.maxSubtasksValues
                                        } ${t(
                                            'hoursShort'
                                        )}`}
                                    </div>
                                </div>
                                <div
                                    className="statistics-box"
                                    style={{
                                        backgroundColor:
                                            '#FF931E',
                                        textShadow:
                                            '3px 3px #b87124',
                                    }}
                                >
                                    {t(
                                        'averageSubtaskValues'
                                    )}
                                    <div className="statistics-box-value">
                                        {`${getAverageSubtaskValue()} ${t(
                                            'hoursShort'
                                        )}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </TabPane>
            </TabContent>
        </div>
    );
}
