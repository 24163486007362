import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import classnames from 'classnames';

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SettingsInput from '../components/settingsInput';
import { toSelItem, isEmail } from 'helperFunctions';
import { addLocalError } from 'apollo/localSchema/actions';
import { useTranslation } from 'react-i18next';
import { ADD_COMPANY, GET_COMPANY_DEFAULTS } from './queries';

export default function CompanyAdd(props) {
    const { addCompanyToList, closeModal } = props;

    const navigate = useNavigate();

    const { t } = useTranslation();

    const { data: companyDefaultsData, loading: companyDefaultsLoading } = useQuery(
        GET_COMPANY_DEFAULTS,
        {
            fetchPolicy: 'network-only',
        }
    );

    const [addCompany] = useMutation(ADD_COMPANY);

    //state
    const [title, setTitle] = React.useState('');
    const [dph, setDph] = React.useState(0);
    const [ico, setIco] = React.useState('');
    const [dic, setDic] = React.useState('');
    const [ic_dph, setIcDph] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [city, setCity] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [zip, setZip] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [description, setDescription] = React.useState('');

    const [saving, setSaving] = React.useState(false);
    const [newData, setNewData] = React.useState(false);
    const [openedTab, setOpenedTab] = React.useState('company');

    //sync

    React.useEffect(() => {
        if (!companyDefaultsLoading) {
            setDph(companyDefaultsData.companyDefaults.dph);
        }
    }, [companyDefaultsLoading]);

    //functions
    const addCompanyFunc = () => {
        setSaving(true);

        addCompany({
            variables: {
                title,
                dph: dph === '' ? 0 : parseInt(dph),
                ico,
                dic,
                ic_dph,
                country,
                city,
                street,
                zip,
                email: email.length > 0 ? email : null,
                phone,
                description,
                rents: [],
            },
        })
            .then((response) => {
                if (closeModal) {
                    addCompanyToList(
                        toSelItem({
                            ...response.data.addCompany,
                            __typename: 'BasicCompany',
                        })
                    );
                    closeModal();
                } else {
                    navigate(`/helpdesk/settings/companies/${response.data.addCompany.id}`);
                }
            })
            .catch((err) => {
                addLocalError(err);
            });
        setSaving(false);
    };

    const cancel = () => {
        setNewData(false);
    };

    const attributes = [title, ico];
    const cannotSave =
        saving || attributes.some((attr) => attr === '') || (!isEmail(email) && email.length !== 0);

    return (
        <div>
            {newData && !closeModal && (
                <div
                    style={{
                        position: 'fixed',
                        zIndex: '999',
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100vh',
                    }}
                />
            )}
            <div
                className={classnames(
                    'p-20',
                    { 'scroll-visible fit-with-header': !closeModal },
                    {
                        'bring-to-front': newData,
                    }
                )}
            >
                <h2 className="m-b-20">{`${t('add')} ${t('company2').toLowerCase()}`}</h2>

                <Nav tabs className="no-border m-b-25">
                    <NavItem>
                        <NavLink
                            className={classnames(
                                { active: openedTab === 'company' },
                                'clickable',
                                ''
                            )}
                            onClick={() => setOpenedTab('company')}
                        >
                            {t('billingInformation')}
                        </NavLink>
                    </NavItem>
                    {/* myRights.pausals &&
            <Empty>
              <NavItem>
                <NavLink>
                  |
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: openedTab === 'contract' }, "clickable", "")}
                  onClick={() => setOpenedTab('contract') }
                  >
                  {t('contract')}
                </NavLink>
              </NavItem>
            </Empty>
          */}
                </Nav>

                <TabContent activeTab={openedTab}>
                    <TabPane tabId={'company'}>
                        <SettingsInput
                            required
                            id="name"
                            label={t('companyTitle')}
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                                setNewData(true);
                            }}
                        />

                        <SettingsInput
                            id="dph"
                            label={t('tax')}
                            value={dph}
                            onChange={(e) => {
                                setDph(e.target.value);
                                setNewData(true);
                            }}
                        />

                        <SettingsInput
                            required
                            id="ico"
                            label={t('ico')}
                            value={ico}
                            onChange={(e) => {
                                setIco(e.target.value);
                                setNewData(true);
                            }}
                        />

                        <SettingsInput
                            id="dic"
                            label={t('dic')}
                            value={dic}
                            onChange={(e) => {
                                setDic(e.target.value);
                                setNewData(true);
                            }}
                        />

                        <SettingsInput
                            id="ic_dph"
                            label={t('icDph')}
                            value={ic_dph}
                            onChange={(e) => {
                                setIcDph(e.target.value);
                                setNewData(true);
                            }}
                        />

                        <SettingsInput
                            id="country"
                            label={t('country')}
                            placeholder={`${t('enter')} ${t('country2').toLowerCase()}`}
                            value={country}
                            onChange={(e) => {
                                setCountry(e.target.value);
                                setNewData(true);
                            }}
                        />

                        <SettingsInput
                            id="city"
                            label={t('city')}
                            value={city}
                            onChange={(e) => {
                                setCity(e.target.value);
                                setNewData(true);
                            }}
                        />

                        <SettingsInput
                            id="street"
                            label={t('street')}
                            placeholder={`${t('enter')} ${t('street2').toLowerCase()}`}
                            value={street}
                            onChange={(e) => {
                                setStreet(e.target.value);
                                setNewData(true);
                            }}
                        />

                        <SettingsInput
                            id="psc"
                            label={t('postCode')}
                            value={zip}
                            onChange={(e) => {
                                setZip(e.target.value);
                                setNewData(true);
                            }}
                        />

                        <SettingsInput
                            id="mail"
                            label={t('email')}
                            placeholder={t('emailPlaceholderChecked')}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setNewData(true);
                            }}
                            inputClassName={
                                email.length > 0 && !isEmail(email) ? 'form-control-warning' : ''
                            }
                        />

                        <SettingsInput
                            id="phone"
                            label={t('phone')}
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value);
                                setNewData(true);
                            }}
                        />

                        <SettingsInput
                            id="description"
                            label={t('description')}
                            type="textarea"
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                                setNewData(true);
                            }}
                        />
                    </TabPane>
                </TabContent>

                <div className="form-buttons-row p-l-20 p-r-20">
                    {closeModal && (
                        <button
                            className="btn-link-cancel mr-auto"
                            disabled={saving}
                            onClick={closeModal}
                        >
                            {t('cancel')}
                        </button>
                    )}
                    {!closeModal && newData && (
                        <button
                            className="btn-link-cancel mr-auto"
                            disabled={saving}
                            onClick={cancel}
                        >
                            {t('cancel')}
                        </button>
                    )}

                    {cannotSave && (
                        <div className="ml-auto message error-message m-r-10">
                            {t('fillAllRequiredInformation')}
                        </div>
                    )}

                    <button
                        className={classnames('btn', { 'ml-auto': !cannotSave })}
                        disabled={cannotSave || !newData}
                        onClick={() => {
                            /*    if ( pricelist !== null && pricelist.value === "0" && pricelistName !== ""){
                    savePriceList();
                  } else {*/
                            addCompanyFunc();
                            //  }
                        }}
                    >
                        {saving
                            ? `${t('adding')}...`
                            : `${t('add')} ${t('company2').toLowerCase()}`}
                    </button>
                </div>
            </div>
        </div>
    );
}
