import React, { useMemo } from "react";
import classnames from "classnames";
import Select from "react-select";
import { pickSelectStyle } from "configs/components/select";
import DatePicker from "components/DatePicker";

import {
  toSelArr,
  translateSelectItem,
  translateAllSelectItems,
} from "helperFunctions";

import {
  newSourceOptions,
  conditionOptions,
  anyOption,
  boolOptions,
  overdueOption,
  settingsForCustomAttributes,
} from "configs/constants/automation";

import { useTranslation } from "react-i18next";

export default function ConditionTableRow(props) {
  const {
    allUsers,
    userGroups,
    statuses,
    tags,
    lockedRequester,
    companies,
    projects,
    group,
    index,
    orGroupIndex,
    conditions,
    setConditions,
    customAttributes,
  } = props;

  const { t } = useTranslation();

  const translatedNewSourceOptions = translateAllSelectItems(
    newSourceOptions,
    t,
    "value",
    "value"
  );
  const translatedConditionOptions = translateAllSelectItems(
    conditionOptions,
    t,
    "translationKey"
  );
  const translatedBoolOptions = translateAllSelectItems(
    boolOptions,
    t,
    "translationKey"
  );
  const translatedOverdueOption = translateSelectItem(
    overdueOption,
    t,
    "translationKey"
  );

  const getTargetOptions = (source) => {
    if (!source) {
      return [];
    }

    if (source.type) {
      return [
        anyOption,
        ...source.selectValues.map((value) => ({
          ...value,
          label:
            value.value.substring(0, 1).toUpperCase() +
            value.value.substring(1),
        })),
      ];
    }

    switch (source.value) {
      case "task": {
        return [];
      }
      case "important": {
        return translatedBoolOptions;
      }
      case "status": {
        return toSelArr(statuses);
      }
      case "tags": {
        return toSelArr(tags);
      }
      case "assignedTo": {
        return userGroups.map((group) => ({
          label: group.user.fullName,
          value: group.user.id,
        }));
      }
      case "requester": {
        return lockedRequester
          ? userGroups.map((group) => ({
              label: group.user.fullName,
              value: group.user.id,
            }))
          : toSelArr(allUsers, "fullName");
      }
      case "company": {
        return companies;
      }
      case "project": {
        return projects;
      }
      case "pendingChangeable": {
        return translatedBoolOptions;
      }
      case "deadline":
      case "startsAt": {
        return [translatedOverdueOption];
      }
      default:
        return [];
    }
  };

  const fillCondition = (condition, newSource) => {
    if (
      condition.source &&
      condition.condition &&
      newSource.inTimer.conditions.includes(condition.condition.value)
    ) {
      return condition.condition;
    }
    if (newSource.inTimer.conditions.length === 1) {
      return translatedConditionOptions.find(
        (option) => newSource.inTimer.conditions[0] === option.value
      );
    }
    return null;
  };

  const fillTarget = (condition, newCondition) => {
    if (
      condition.condition &&
      ["are", "areNot", "is", "isNot", "in", "notIn"].includes(
        condition.condition.value
      ) &&
      ["are", "areNot", "is", "isNot", "in", "notIn"].includes(
        newCondition.value
      )
    ) {
      return condition.target;
    }
    return null;
  };

  const fillTargetFromSource = (source) => {
    if (
      [
        "startsAt",
        "deadline",
        "pendingDate",
        "closeDate",
        "updatedDate",
        "statusChange",
      ].includes(source.value)
    ) {
      return [
        {
          value: new Date(),
        },
      ];
    }
    return null;
  };

  const mappedCustomAttributes = useMemo(() => {
    let mappedCustomAttributes = toSelArr(customAttributes);
    mappedCustomAttributes = mappedCustomAttributes.map((attribute) => ({
      ...attribute,
      ...settingsForCustomAttributes.find(
        (setting) =>
          setting.type.substring("customAttribute".length).toLowerCase() ===
          (attribute.type.value ? attribute.type.value : attribute.type)
      ),
    }));
    return mappedCustomAttributes;
  }, [customAttributes]);

  const allSourceOptions = useMemo(() => {
    return [...translatedNewSourceOptions, ...mappedCustomAttributes];
  }, [translatedNewSourceOptions, mappedCustomAttributes]);

  const renderValueInput = () => {
    if (!group.source || !group.condition) {
      return;
    }
    switch (group.source.inTimer.valueInputTypes[group.condition.value]) {
      case "select": {
        return (
          <Select
            styles={pickSelectStyle(["size16"])}
            options={getTargetOptions(group.source, group.condition)}
            value={group.target}
            onChange={(target) => {
              const newConditions = conditions.map((orGr, orGrIndex) => {
                if (orGrIndex === orGroupIndex) {
                  return orGr.map((g, i) => {
                    if (i === index) {
                      return {
                        ...g,
                        target: [target],
                      };
                    }
                    return g;
                  });
                }
                return orGr;
              });
              setConditions(newConditions);
            }}
          />
        );
      }
      case "multiselect": {
        return (
          <Select
            styles={pickSelectStyle(["size16"])}
            options={getTargetOptions(group.source, group.condition)}
            isMulti
            value={group.target}
            onChange={(target) => {
              const newConditions = conditions.map((orGr, orGrIndex) => {
                if (orGrIndex === orGroupIndex) {
                  return orGr.map((g, i) => {
                    if (i === index) {
                      return {
                        ...g,
                        target,
                      };
                    }
                    return g;
                  });
                }
                return orGr;
              });
              setConditions(newConditions);
            }}
          />
        );
      }
      case "date": {
        return (
          <DatePicker
            className={classnames("form-control")}
            selected={group.target ? group.target[0].value : group.target}
            hideTime
            isClearable
            readOnly={true}
            onChange={() => {}}
            placeholderText={t("date")}
          />
        );
      }
      case "text": {
        let value = group.target ? group.target[0].value : group.target;
        const isDateSource = [
          "startsAt",
          "deadline",
          "pendingDate",
          "closeDate",
          "updatedAt",
          "statusDate",
        ].includes(group.source.value);
        if (isDateSource) {
          value = "now";
        }
        return (
          <input
            className="form-control"
            value={value}
            readOnly={isDateSource}
            type={"text"}
            onChange={(e) => {
              const newConditions = conditions.map((orGr, orGrIndex) => {
                if (orGrIndex === orGroupIndex) {
                  return orGr.map((g, i) => {
                    if (i === index) {
                      return {
                        ...g,
                        target: [{ value: e.target.value }],
                      };
                    }
                    return g;
                  });
                }
                return orGr;
              });
              setConditions(newConditions);
            }}
            placeholder={t("value")}
          />
        );
      }
      case "number": {
        return (
          <input
            className="form-control"
            value={group.target ? group.target[0].value : group.target}
            type={"number"}
            onChange={(e) => {
              const newConditions = conditions.map((orGr, orGrIndex) => {
                if (orGrIndex === orGroupIndex) {
                  return orGr.map((g, i) => {
                    if (i === index) {
                      return {
                        ...g,
                        target: [{ value: parseInt(e.target.value) }],
                      };
                    }
                    return g;
                  });
                }
                return orGr;
              });
              setConditions(newConditions);
            }}
            placeholder={t("value")}
          />
        );
      }
      case "textarea": {
        return (
          <textarea
            className="form-control"
            value={group.target ? group.target[0].value : group.target}
            onChange={(e) => {
              const newConditions = conditions.map((orGr, orGrIndex) => {
                if (orGrIndex === orGroupIndex) {
                  return orGr.map((g, i) => {
                    if (i === index) {
                      return {
                        ...g,
                        target: [{ value: e.target.value }],
                      };
                    }
                    return g;
                  });
                }
                return orGr;
              });
              setConditions(newConditions);
            }}
            placeholder={t("value")}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <tr key={group.id}>
      <td style={{ fontSize: "16px" }}>
        {index === 0 ? t("if") : t("and2").toUpperCase()}
      </td>
      <td>
        <Select
          styles={pickSelectStyle(["size16"])}
          options={allSourceOptions.filter(
            (option) => option.inTimer.isSourceOption
          )}
          value={group.source}
          isDisabled={group.id > -1}
          onChange={(source) => {
            const newConditions = conditions.map((orGr, orGrIndex) => {
              if (orGrIndex === orGroupIndex) {
                return orGr.map((g, i) => {
                  if (i === index) {
                    return {
                      ...g,
                      source,
                      condition: fillCondition(g, source),
                      target: fillTargetFromSource(source),
                    };
                  }
                  return g;
                });
              }
              return orGr;
            });
            setConditions(newConditions);
          }}
        />
      </td>
      <td>
        <Select
          styles={pickSelectStyle(["size16"])}
          options={
            group.source
              ? translatedConditionOptions.filter((option) =>
                  allSourceOptions
                    .find((s) => s.value === group.source.value)
                    .inTimer.conditions.includes(option.value)
                )
              : []
          }
          value={group.condition}
          onChange={(condition) => {
            const newConditions = conditions.map((orGr, orGrIndex) => {
              if (orGrIndex === orGroupIndex) {
                return orGr.map((g, i) => {
                  if (i === index) {
                    return {
                      ...g,
                      condition,
                      target: fillTarget(g, condition),
                    };
                  }
                  return g;
                });
              }
              return orGr;
            });
            setConditions(newConditions);
          }}
        />
      </td>
      <td width={"30%"}>{renderValueInput()}</td>
      <td width={"70px"} className="text-right">
        <button
          className="btn-link-red"
          onClick={(e) => {
            e.preventDefault();
            const newConditions = conditions
              .map((orGr, orGrIndex) => {
                if (orGrIndex === orGroupIndex) {
                  return orGr.filter((gr) => gr.id !== group.id);
                }
                return orGr;
              })
              .filter((orGr) => orGr.length > 0);
            setConditions(newConditions);
          }}
        >
          <i className="fa fa-times" style={{ fontSize: "16px" }} />
        </button>
      </td>
    </tr>
  );
}
