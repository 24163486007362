import {
  testingTranslations
} from 'configs/restAPI';
import ai from './ai';
import components from './components';
import project from './project';
import settings from './settings';
import task from './task';
import general from './general';
import webchat from './webchat';
import statistics from './statistics';

const invoices = {
  ...ai,
  ...components,
  ...project,
  ...settings,
  ...task,
  ...general,
  ...webchat,
  ...statistics,
}

if (testingTranslations) {

  //compare keys if same throw error
  //get keys of all objects, filter duplicates, throw them in error
  const test = [
    ...Object.keys(ai),
    ...Object.keys(components),
    ...Object.keys(project),
    ...Object.keys(settings),
    ...Object.keys(task),
    ...Object.keys(general),
    ...Object.keys(webchat),
    ...Object.keys(statistics),
  ]

  const testResult = test.filter((key, index) => test.findIndex((key2) => key2 === key) !== index);
  if (testResult.length > 0) {
    throw new Error(`Some thranslations have duplicate keys: ${testResult.join(', ')}`);
  }
}

export default invoices;