import React from 'react';

import { useNavigate } from 'react-router-dom';

export default function Reroute( props ) {

  const navigate = useNavigate();
  React.useEffect( () => {
    navigate( './helpdesk/taskList/i/all' );
  } );
  return null;
}
