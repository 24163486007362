import React, {
  useState
} from 'react';

import {
  Label,
} from 'reactstrap';

import {
  renderCustomAttribute,
} from 'helperFunctions';

import {
  useTranslation
} from "react-i18next";

export default function CustomAttribtueEntry(props) {
  const {
    customAttribute,
    customAttributes,
    setCustomAttributes,
    additionalSelectStyles
  } = props;

  const {
    t
  } = useTranslation();

  const [value, setValue] = useState({
    text: "",
    number: "",
    selectValues: []
  });

  React.useEffect(() => {
    setValue({
      text: customAttribute.value.text,
      number: customAttribute.value.number,
      selectValues: customAttribute.value.selectValues ? customAttribute.value.selectValues.map((value) => ({
        ...value,
        label: value.label ? value.label : value.value.substring(0, 1).toUpperCase() + value.value.substring(1)
      })) : []
    })
  }, [customAttribute]);

  const assignOnChangeFunction = () => {
    switch (customAttribute.type) {
      case "textarea":
        return ((e) => {
          setValue({
            ...value,
            text: e.target.value.replace("↵", "\n"),
          });
        });
      case "number":
        return ((e) => {
          setValue({
            ...value,
            number: parseFloat(e.target.value),
          });
        });
      case "select":
        return ((e) => {
          setValue({
            ...value,
            selectValues: [e],
          });
        });
      case "multiselect":
        return ((e) => {
          setValue({
            ...value,
            selectValues: e,
          });
        });
      default:
        return ((e) => {
          setValue({
            ...value,
            text: e.target.value,
          });
        });
    }
  }

  return (
    <div className="form-selects-entry-column" key={customAttribute.id} >
      <Label>{customAttribute.label}{!customAttribute.isEdit && customAttribute.required ? (<span className="warning-big">*</span>) : ""}</Label>
      <div className="form-selects-entry-column-rest" >
        {
          renderCustomAttribute(
            customAttribute.id,
            customAttribute.label,
            customAttribute.type,
            value,
            assignOnChangeFunction(),
            (() => {
              let newCustomAttributes = customAttributes.map((item) => {
                if (item.id === customAttribute.id) {
                  return ({
                    ...item,
                    value
                  })
                } else {
                  return item;
                }
              });
              setCustomAttributes(newCustomAttributes);
            }),
            customAttribute.type === "select" ? [{ label: t('empty'), value: null, id: null }, ...customAttribute.selectValues] : customAttribute.selectValues,
            ['colored', ...(additionalSelectStyles ? additionalSelectStyles : [])],
            !customAttribute.canEdit
          )
        }
      </div>
    </div>
  )
}