import React from 'react';

import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  useQuery,
  useSubscription,
} from "@apollo/client";
import {
  useTranslation,
} from "react-i18next";
import {
  Label,
} from 'reactstrap';
import classnames from 'classnames';
import {
  timestampToStringFNS
} from "helperFunctions";

import Empty from 'components/Empty';
import SettingLoading from '../settings/components/settingLoading';
import SettingListContainer from '../settings/components/settingListContainer';
import Conversation from './conversationLoader';
import {
  GET_CONVERSATIONS,
  CONVERSATIONS_SUBSCRIPTION,
} from './queries';

export default function WebChatList() {

  const navigate = useNavigate();
  const params = useParams();

  const {
    t
  } = useTranslation();

  // state
  const [webchatFilter, setWebchatFilter] = React.useState("");

  const {
    data: conversationsData,
    loading: conversationsLoading,
    refetch: conversationsRefetch,
  } = useQuery(GET_CONVERSATIONS, {
    fetchPolicy: 'network-only'
  });

  useSubscription(CONVERSATIONS_SUBSCRIPTION, {
    onData: () => {
      conversationsRefetch();
    }
  });

  if (conversationsLoading) {
    return (<SettingLoading />);
  }

  const conversations = conversationsData.conversations;

  const RightSideComponent = (
    <Empty>
      {params.id && conversations.some((item) => item.id === parseInt(params.id)) &&
        <Conversation conversation={conversations.find((item) => item.id === parseInt(params.id))} />
      }
    </Empty>
  )

  return (
    <SettingListContainer
      header={t('webchat')}
      filter={webchatFilter}
      setFilter={setWebchatFilter}
      noAdd
      RightSideComponent={RightSideComponent}
    >
      <table className="table table-hover">
        <tbody>
          {conversations.filter((conversation) => conversation.fullName.toLowerCase().includes(webchatFilter.toLowerCase()) || conversation.email.toLowerCase().includes(webchatFilter.toLowerCase()) || conversation.initialComment.toLowerCase().includes(webchatFilter.toLowerCase()))
            .map((conversation) => (
              <tr
                key={conversation.id}
                className={classnames(
                  "clickable",
                  {
                    "active": parseInt(params.id) === conversation.id
                  },

                )}
                onClick={() => {
                  navigate(`/helpdesk/webchat/${conversation.id}`);
                }}>
                <td>
                  <div className="row">
                    <Label>
                      {conversation.fullName}
                    </Label>
                    <div className="ml-auto width-200 row">
                      <span
                        className="label label-info height-20"
                        style={{ backgroundColor: conversation.active ? "#7ed321" : "#9b9b9b" }}>
                        {conversation.active ? t('open') : t('closed')}
                      </span>
                      <div style={{ color: conversation.read ? "transparent" : "red" }} className="m-l-5">
                        {conversation.unreadMessages}
                        <i className="fa fa-solid fa-bell" />
                      </div>
                      <div className="ml-auto">
                        {timestampToStringFNS(conversation.createdAt)}
                      </div>
                    </div>
                  </div>
                  <Label>
                    {conversation.email}
                  </Label>
                  <p>{conversation.initialComment}</p>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </SettingListContainer>
  )
}