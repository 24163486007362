import React from 'react';

import {
  Nav,
  Label,
} from 'reactstrap';
import {
  NavLink,
} from 'react-router-dom';
import settings from 'configs/constants/settings';
import {
  getMyData,
} from 'helperFunctions';
import DefaultCompany from './defaultCompany';

import {
  useTranslation
} from "react-i18next";

export default function SettingsSidebar(props) {
  //data & queries
  const {
    t
  } = useTranslation();

  const currentUser = getMyData();
  const accessRights = currentUser && currentUser.role ? currentUser.role.accessRights : {};

  return (
    <Nav vertical>
      <div className="sidebar-align p-t-7 p-b-7" style={{ display: "flex" }}>
        <i className="fa fa-cog font-19" />
        <Label className="noselect m-b-0">
          {t('settings')}
        </Label>
      </div>
      {accessRights.companies && <DefaultCompany {...props} accessRights={accessRights} />}
      {
        settings.filter((setting) => accessRights[setting.value] || setting.value === "webchat").map((setting) =>
          <NavLink
            key={setting.link}
            className={({ isActive }) => `link-item ${isActive ? 'active' : ''}`}
            to={`/helpdesk/settings/${setting.link}`}
          >
            {t(setting.title)}
          </NavLink>
        )
      }
      <NavLink
        key="back-to-tasks"
        className={() => `link-item text-highlight`}
        to={`/helpdesk/taskList/i/all`}
      >
        <i className="fa fa-chevron-left m-r-5 center-hor" />
        {t('backToTasks')}
      </NavLink>
    </Nav>
  );
}