import React, {
  useMemo
} from 'react';

import {
  useLocation
} from 'react-router-dom';

import SettingsSidebar from './settingsSidebar';
import AISidebar from './aiSidebar';

import classnames from 'classnames';

export default function MainSettingsSidebar(props) {
  //data & queries
  const {
    sidebarOpen,
  } = props;

  const location = useLocation();

  const showAISettings = useMemo(() => {
    return location.pathname.includes('ai');
  }, [location]);

  return (
    <div className={classnames({ "sidebar": sidebarOpen || showAISettings, "sidebar-compressed": !sidebarOpen && !showAISettings })}>
      <div className="scrollable fit-with-header">
        {
          showAISettings &&
          <AISidebar {...props} />
        }
        {
          !showAISettings &&
          <SettingsSidebar {...props} />
        }
      </div>
    </div>
  );
}