import {
    applyOccasion,
    newSourceOptions,
    settingsForCustomAttributes,
    conditionOptions,
    actionOptions,
    anyOption,
    emptyOption,
    creatorOption,
    requesterOption,
    currentUserOption,
    assignedOption,
    creatorCompanyOption,
    requesterCompanyOption,
    assignedTo,
    boolOptions,
} from 'configs/constants/automation';
import { fromUnixToDate, translateSelectItem, translateAllSelectItems } from 'helperFunctions';

let fakeID = -1;

export const remapRightsToBackend = (group) => {
    return {
        id: group.id,
        title: group.title,
        def: group.def,
        admin: group.admin,
        description: group.description,
        order: parseInt(group.order),
        attributeRights: {
            status: {
                required: group.attributeRights.status.required,
                add: group.attributeRights.status.add,
                view: group.attributeRights.status.view || group.attributeRights.status.edit,
                edit: group.attributeRights.status.edit,
            },
            tags: {
                required: group.attributeRights.tags.required,
                add: group.attributeRights.tags.add,
                view: group.attributeRights.tags.view || group.attributeRights.tags.edit,
                edit: group.attributeRights.tags.edit,
            },
            assigned: {
                required: group.attributeRights.assigned.required,
                add: group.attributeRights.assigned.add,
                view: group.attributeRights.assigned.view || group.attributeRights.assigned.edit,
                edit: group.attributeRights.assigned.edit,
            },
            requester: {
                required: group.attributeRights.requester.required,
                add: group.attributeRights.requester.add,
                view: group.attributeRights.requester.view || group.attributeRights.requester.edit,
                edit: group.attributeRights.requester.edit,
            },
            company: {
                required: group.attributeRights.company.required,
                add: group.attributeRights.company.add,
                view: group.attributeRights.company.view || group.attributeRights.company.edit,
                edit: group.attributeRights.company.edit,
            },
            /*
      taskType: {
        required: group.attributeRights.taskType.required,
        add: group.attributeRights.taskType.add,
        view: group.attributeRights.taskType.view || group.attributeRights.taskType.edit,
        edit: group.attributeRights.taskType.edit
      },
      */
            /*    pausal: {
            required: group.attributeRights.pausal.required,
            add: group.attributeRights.pausal.add,
            view: group.attributeRights.pausal.view || group.attributeRights.pausal.edit,
            edit: group.attributeRights.pausal.edit
          },
          overtime: {
            required: group.attributeRights.overtime.required,
            add: group.attributeRights.overtime.add,
            view: group.attributeRights.overtime.view || group.attributeRights.overtime.edit,
            edit: group.attributeRights.overtime.edit
          },*/
            startsAt: {
                required: group.attributeRights.startsAt.required,
                add: group.attributeRights.startsAt.add,
                view: group.attributeRights.startsAt.view || group.attributeRights.startsAt.edit,
                edit: group.attributeRights.startsAt.edit,
            },
            deadline: {
                required: group.attributeRights.deadline.required,
                add: group.attributeRights.deadline.add,
                view: group.attributeRights.deadline.view || group.attributeRights.deadline.edit,
                edit: group.attributeRights.deadline.edit,
            },
            repeat: {
                add: group.attributeRights.repeat.add,
                view: group.attributeRights.repeat.view || group.attributeRights.repeat.edit,
                edit: group.attributeRights.repeat.edit,
            },
        },
        rights: {
            projectRead: group.rights.projectRead,
            projectWrite: group.rights.projectWrite,
            companyTasks: group.rights.companyTasks,
            allTasks: group.rights.allTasks,
            tasklistDnD: group.rights.tasklistDnD,
            tasklistKalendar: group.rights.tasklistKalendar,
            tasklistGantt: group.rights.tasklistGantt,
            tasklistStatistics: group.rights.tasklistStatistics,
            addTask: group.rights.addTask,
            deleteTask: group.rights.deleteTask,
            taskImportant: group.rights.taskImportant,
            taskTitleWrite: group.rights.taskTitleWrite,
            taskProjectWrite: group.rights.taskProjectWrite,
            taskDescriptionRead: group.rights.taskDescription.read,
            taskDescriptionWrite: group.rights.taskDescription.write,
            taskAttachmentsRead: group.rights.taskAttachments.read,
            taskAttachmentsWrite: group.rights.taskAttachments.write,
            taskSubtasksRead: group.rights.taskSubtasks.read,
            taskSubtasksWrite: group.rights.taskSubtasks.write,
            taskWorksRead: group.rights.taskWorks.read,
            taskWorksWrite: group.rights.taskWorks.write,
            taskWorksAdvancedRead: group.rights.taskWorksAdvanced.read,
            taskWorksAdvancedWrite: group.rights.taskWorksAdvanced.write,
            taskMaterialsRead: group.rights.taskMaterials.read,
            taskMaterialsWrite: group.rights.taskMaterials.write,
            /*  taskPausalInfo: group.rights.taskPausalInfo,*/
            viewComments: group.rights.viewComments,
            addComments: group.rights.addComments,
            internal: group.rights.internal,
            emails: group.rights.emails,
            history: group.rights.history,
        },
    };
};

export const remapRightsFromBackend = (group) => {
    const attributeRights = group.attributeRights;
    return {
        id: group.id,
        title: group.title,
        def: group.def,
        admin: group.admin,
        description: group.description,
        order: parseInt(group.order),
        attributeRights: {
            assigned: {
                required: attributeRights.assigned.required,
                add: attributeRights.assigned.add,
                view: attributeRights.assigned.view,
                edit: attributeRights.assigned.edit,
            },
            company: {
                required: attributeRights.company.required,
                add: attributeRights.company.add,
                view: attributeRights.company.view,
                edit: attributeRights.company.edit,
            },
            deadline: {
                required: attributeRights.deadline.required,
                add: attributeRights.deadline.add,
                view: attributeRights.deadline.view,
                edit: attributeRights.deadline.edit,
            },
            /*  overtime: {
          required: attributeRights.overtime.required,
          add: attributeRights.overtime.add,
          view: attributeRights.overtime.view,
          edit: attributeRights.overtime.edit
        },
        pausal: {
          required: attributeRights.pausal.required,
          add: attributeRights.pausal.add,
          view: attributeRights.pausal.view,
          edit: attributeRights.pausal.edit
        },*/
            repeat: {
                add: attributeRights.repeat.add,
                view: attributeRights.repeat.view,
                edit: attributeRights.repeat.edit,
            },
            requester: {
                required: attributeRights.requester.required,
                add: attributeRights.requester.add,
                view: attributeRights.requester.view,
                edit: attributeRights.requester.edit,
            },
            startsAt: {
                required: attributeRights.startsAt.required,
                add: attributeRights.startsAt.add,
                view: attributeRights.startsAt.view,
                edit: attributeRights.startsAt.edit,
            },
            status: {
                required: attributeRights.status.required,
                add: attributeRights.status.add,
                view: attributeRights.status.view,
                edit: attributeRights.status.edit,
            },
            tags: {
                required: attributeRights.tags.required,
                add: attributeRights.tags.add,
                view: attributeRights.tags.view,
                edit: attributeRights.tags.edit,
            },
            /*
      taskType: {
        required: attributeRights.taskType.required,
        add: attributeRights.taskType.add,
        view: attributeRights.taskType.view,
        edit: attributeRights.taskType.edit
      },
      */
        },
        rights: {
            projectRead: group.rights.projectRead,
            projectWrite: group.rights.projectWrite,
            companyTasks: group.rights.companyTasks,
            allTasks: group.rights.allTasks,
            tasklistDnD: group.rights.tasklistDnD,
            tasklistKalendar: group.rights.tasklistKalendar,
            tasklistGantt: group.rights.tasklistGantt,
            tasklistStatistics: group.rights.tasklistStatistics,
            addTask: group.rights.addTask,
            deleteTask: group.rights.deleteTask,
            taskImportant: group.rights.taskImportant,
            taskTitleWrite: group.rights.taskTitleWrite,
            taskProjectWrite: group.rights.taskProjectWrite,
            taskDescription: {
                read: group.rights.taskDescriptionRead,
                write: group.rights.taskDescriptionWrite,
            },
            taskAttachments: {
                read: group.rights.taskAttachmentsRead,
                write: group.rights.taskAttachmentsWrite,
            },
            taskSubtasks: {
                read: group.rights.taskSubtasksRead,
                write: group.rights.taskSubtasksWrite,
            },
            taskWorks: {
                read: group.rights.taskWorksRead,
                write: group.rights.taskWorksWrite,
            },
            taskWorksAdvanced: {
                read: group.rights.taskWorksAdvancedRead,
                write: group.rights.taskWorksAdvancedWrite,
            },
            taskMaterials: {
                read: group.rights.taskMaterialsRead,
                write: group.rights.taskMaterialsWrite,
            },
            /*    taskPausalInfo: group.rights.taskPausalInfo,*/
            viewComments: group.rights.viewComments,
            addComments: group.rights.addComments,
            internal: group.rights.internal,
            emails: group.rights.emails,
            history: group.rights.history,
        },
    };
};

export const mergeGroupRights = (right1, right2) => {
    if (!right1) {
        right1 = right2;
    }
    if (!right2) {
        right2 = right1;
    }

    return {
        projectRead: right1.projectRead || right2.projectRead,
        projectWrite: right1.projectWrite || right2.projectWrite,
        companyTasks: right1.companyTasks || right2.companyTasks,
        allTasks: right1.allTasks || right2.allTasks,
        tasklistDnD: right1.tasklistDnD || right2.tasklistDnD,
        tasklistKalendar: right1.tasklistKalendar || right2.tasklistKalendar,
        tasklistGantt: right1.tasklistGantt || right2.tasklistGantt,
        tasklistStatistics: right1.tasklistStatistics || right2.tasklistStatistics,
        addTask: right1.addTask || right2.addTask,
        deleteTask: right1.deleteTask || right2.deleteTask,
        taskImportant: right1.taskImportant || right2.taskImportant,
        taskTitleWrite: right1.taskTitleWrite || right2.taskTitleWrite,
        taskProjectWrite: right1.taskProjectWrite || right2.taskProjectWrite,
        taskDescriptionRead: right1.taskDescriptionRead || right2.taskDescriptionRead,
        taskDescriptionWrite: right1.taskDescriptionWrite || right2.taskDescriptionWrite,
        taskAttachmentsRead: right1.taskAttachmentsRead || right2.taskAttachmentsRead,
        taskAttachmentsWrite: right1.taskAttachmentsWrite || right2.taskAttachmentsWrite,
        taskSubtasksRead: right1.taskSubtasksRead || right2.taskSubtasksRead,
        taskSubtasksWrite: right1.taskSubtasksWrite || right2.taskSubtasksWrite,
        taskWorksRead: right1.taskWorksRead || right2.taskWorksRead,
        taskWorksWrite: right1.taskWorksWrite || right2.taskWorksWrite,
        taskWorksAdvancedRead: right1.taskWorksAdvancedRead || right2.taskWorksAdvancedRead,
        taskWorksAdvancedWrite: right1.taskWorksAdvancedWrite || right2.taskWorksAdvancedWrite,
        taskMaterialsRead: right1.taskMaterialsRead || right2.taskMaterialsRead,
        taskMaterialsWrite: right1.taskMaterialsWrite || right2.taskMaterialsWrite,
        /*    taskPausalInfo: right1.taskPausalInfo || right2.taskPausalInfo,*/
        viewComments: right1.viewComments || right2.viewComments,
        addComments: right1.addComments || right2.addComments,
        internal: right1.internal || right2.internal,
        emails: right1.emails || right2.emails,
        history: right1.history || right2.history,
    };
};

export const mergeGroupAttributeRights = (right1, right2) => {
    return {
        assigned: {
            required: right1.assigned.required && right2.assigned.required,
            add: right1.assigned.add || right2.assigned.add,
            view: right1.assigned.view || right2.assigned.view,
            edit: right1.assigned.edit || right2.assigned.edit,
        },
        company: {
            required: right1.company.required && right2.company.required,
            add: right1.company.add || right2.company.add,
            view: right1.company.view || right2.company.view,
            edit: right1.company.edit || right2.company.edit,
        },
        deadline: {
            required: right1.deadline.required && right2.deadline.required,
            add: right1.deadline.add || right2.deadline.add,
            view: right1.deadline.view || right2.deadline.view,
            edit: right1.deadline.edit || right2.deadline.edit,
        },
        /*  overtime: {
        required: right1.overtime.required && right2.overtime.required,
        add: right1.overtime.add || right2.overtime.add,
        view: right1.overtime.view || right2.overtime.view,
        edit: right1.overtime.edit || right2.overtime.edit,
      },
      pausal: {
        required: right1.pausal.required && right2.pausal.required,
        add: right1.pausal.add || right2.pausal.add,
        view: right1.pausal.view || right2.pausal.view,
        edit: right1.pausal.edit || right2.pausal.edit,
      },*/
        repeat: {
            add: right1.repeat.add || right2.repeat.add,
            view: right1.repeat.view || right2.repeat.view,
            edit: right1.repeat.edit || right2.repeat.edit,
        },
        requester: {
            required: right1.requester.required && right2.requester.required,
            add: right1.requester.add || right2.requester.add,
            view: right1.requester.view || right2.requester.view,
            edit: right1.requester.edit || right2.requester.edit,
        },
        startsAt: {
            required: right1.startsAt.required && right2.startsAt.required,
            add: right1.startsAt.add || right2.startsAt.add,
            view: right1.startsAt.view || right2.startsAt.view,
            edit: right1.startsAt.edit || right2.startsAt.edit,
        },
        status: {
            required: right1.status.required && right2.status.required,
            add: right1.status.add || right2.status.add,
            view: right1.status.view || right2.status.view,
            edit: right1.status.edit || right2.status.edit,
        },
        tags: {
            required: right1.tags.required && right2.tags.required,
            add: right1.tags.add || right2.tags.add,
            view: right1.tags.view || right2.tags.view,
            edit: right1.tags.edit || right2.tags.edit,
        },
        /*
    taskType: {
      required: right1.taskType.required || right2.taskType.required,
      add: right1.taskType.add || right2.taskType.add,
      view: right1.taskType.view || right2.taskType.view,
      edit: right1.taskType.edit || right2.taskType.edit,
    },
    */
    };
};

export const getGroupsProblematicAttributes = (groups, filterData, customAttributes, t) => {
    const filter = filterData.filter;
    const filterGroups = groups.filter((group) => filterData.groups.includes(group.id));

    let groupsWithProblematicAttributes = [];
    filterGroups.forEach((group) => {
        const attributeRights = group.attributeRights;
        const rights = group.rights;
        let problematicAttributes = [];
        if (
            !attributeRights.assigned.view &&
            !attributeRights.assigned.edit &&
            (filter.assignedToCur || filter.assignedTos.length !== 0)
        ) {
            problematicAttributes.push(t('assignedTos'));
        }

        if (
            !attributeRights.requester.view &&
            !attributeRights.requester.edit &&
            (filter.requesterCur || filter.requesters.length !== 0)
        ) {
            problematicAttributes.push(t('requester'));
        }
        /*
        if (
          !attributeRights.taskType.view && !attributeRights.taskType.edit &&
          filter.taskTypes.length !== 0
        ) {
          problematicAttributes.push( t( 'taskType' ) );
        }
        */

        if (
            !attributeRights.company.view &&
            !attributeRights.company.edit &&
            (filter.companyCur || filter.companies.length !== 0)
        ) {
            problematicAttributes.push(t('company'));
        }
        /*
        if (
          !attributeRights.overtime.view && !attributeRights.overtime.edit &&
          filter.overtime !== null
        ) {
          problematicAttributes.push( t( 'overtime' ) );
        }

        if (
          !attributeRights.pausal.view && !attributeRights.pausal.edit &&
          filter.pausal !== null
        ) {
          problematicAttributes.push( t( 'pausal' ) );
        }
    */
        if (
            !attributeRights.deadline.view &&
            !attributeRights.deadline.edit &&
            (filter.deadlineFromNow ||
                filter.deadlineFrom !== null ||
                filter.deadlineToNow ||
                filter.deadlineTo !== null)
        ) {
            problematicAttributes.push(t('deadline'));
        }

        if (
            !attributeRights.status.view &&
            !attributeRights.status.edit &&
            (filter.statuses.length !== 0 ||
                filter.statusDateFromNow ||
                filter.statusDateFrom !== null ||
                filter.statusDateToNow ||
                filter.statusDateTo !== null ||
                filter.closeDateFromNow ||
                filter.closeDateFrom !== null ||
                filter.closeDateToNow ||
                filter.closeDateTo !== null ||
                filter.pendingDateFromNow ||
                filter.pendingDateFrom !== null ||
                filter.pendingDateToNow ||
                filter.pendingDateTo !== null)
        ) {
            problematicAttributes.push(t('projectSomeStatusProblem'));
        }

        if (
            !rights.taskWorks.read &&
            (filter.scheduledFromNow ||
                filter.scheduledFrom !== null ||
                filter.scheduledToNow ||
                filter.scheduledTo !== null)
        ) {
            problematicAttributes.push(t('projectSomeWorksProblem'));
        }

        if (customAttributes.length > 0) {
            customAttributes.forEach((customAttribute) => {
                if (!customAttribute.viewGroups.find((viewGroup) => viewGroup.id === group.id)) {
                    problematicAttributes.push(customAttribute.title);
                }
            });
        }

        if (problematicAttributes.length !== 0) {
            groupsWithProblematicAttributes.push({
                group,
                attributes: problematicAttributes,
            });
        }
    });
    return groupsWithProblematicAttributes;
};

export const remapAutomationsFromBackend = (automations, statuses, tags, t) => {
    let mappedAutomations = [];
    mappedAutomations = automations.map((automation) => ({
        ...automation,
        applyConditionTime: getApplyOccasion(automation.taskChange, t),
        actions: automation.actions.map((automationAction) => {
            let target = newSourceOptions.find(
                (option) =>
                    option.value ===
                    (automationAction.target === 'pendingChangable'
                        ? 'pendingChangeable'
                        : automationAction.target)
            );
            if (automationAction.target.includes('custom')) {
                const settingsForCustomAttribute = settingsForCustomAttributes.find(
                    (setting) => setting.type === automationAction.target
                );
                if (!automationAction.customAttribute) {
                    target = null;
                } else {
                    target = {
                        ...automationAction.customAttribute,
                        ...settingsForCustomAttribute,
                        label: automationAction.customAttribute.title,
                        value: automationAction.customAttribute.id,
                    };
                }
            } else {
                target = translateSelectItem(target, t, 'value', 'value');
            }
            const action = translateSelectItem(
                actionOptions.find((option) => option.value === automationAction.action),
                t,
                'translationKey'
            );
            const value = createValues(automationAction, statuses, tags, t);
            const notificationMessage =
                automationAction.target === 'notification' && automationAction.values.length > 0
                    ? automationAction.values[0].text
                    : null;
            return {
                ...automationAction,
                target,
                action,
                value,
                notificationMessage,
            };
        }),
        conditions: groupByOrGroup(
            automation.conditions.map((automationCondition) => {
                let source = newSourceOptions.find(
                    (option) => option.value === automationCondition.source
                );
                if (automationCondition.source.includes('custom')) {
                    let settingsForCustomAttribute = settingsForCustomAttributes.find(
                        (setting) => setting.type === automationCondition.source
                    );
                    if (!automationCondition.customAttribute) {
                        source = null;
                    } else {
                        source = {
                            ...automationCondition.customAttribute,
                            ...settingsForCustomAttribute,
                            label: automationCondition.customAttribute.title,
                            value: automationCondition.customAttribute.id,
                        };
                    }
                } else {
                    source = translateSelectItem(source, t, 'value');
                }
                const condition = translateSelectItem(
                    conditionOptions.find((option) => {
                        if (
                            automationCondition.source === 'tags' &&
                            automationCondition.condition === 'setTo'
                        ) {
                            return option.value === 'areSetTo';
                        }
                        if (
                            automationCondition.source === 'tags' &&
                            automationCondition.condition === 'unsetFrom'
                        ) {
                            return option.value === 'areUnsetFrom';
                        }
                        return option.value === automationCondition.condition;
                    }),
                    t,
                    'translationKey'
                );
                const target = getConditionTarget(automationCondition, statuses, tags, t);

                return {
                    ...automationCondition,
                    source,
                    condition,
                    target,
                };
            })
        ),
    }));

    return mappedAutomations;
};

export const remapAutomationsToBackend = (automations) => {
    let remappedAutomations = [];

    remappedAutomations = automations.map((automation) => ({
        id: automation.id,
        active: automation.active,
        title: automation.title,
        order: parseInt(automation.order),
        description: automation.description,
        ...eventAttributes(automation),
        ...timerAttributes(automation),
        conditions: automation.conditions
            .flat()
            .filter(
                (condition) =>
                    condition.condition &&
                    condition.source &&
                    (automation.applyConditionTime || condition.target)
            )
            .map((condition) => ({
                id: condition.id,
                orGroup: condition.orGroup < 0 ? condition.orGroup * -1 : condition.orGroup,
                condition: ['areSetTo', 'areUnsetFrom'].includes(condition.condition.value)
                    ? condition.condition.value.substring(3, 4).toLowerCase() +
                      condition.condition.value.substring(4)
                    : condition.condition.value,
                source: getConditionSource(condition.source),
                ...createTargetData(condition, !automation.applyConditionTime),
            })),
        actions: automation.actions
            .filter((action) => action.target && action.action && action.value)
            .map((action) => ({
                id: action.id,
                target: getActionTarget(action),
                action: action.action.value,
                ...createAddValues(action),
            })),
    }));

    return remappedAutomations;
};

const groupByOrGroup = (conditions) => {
    let result = [...conditions];
    return result.reduce((accumulator, currentItem) => {
        const currentItemHasExistingOrGroup = accumulator.find(
            (orGroup) => orGroup[0].orGroup === currentItem.orGroup
        );
        if (currentItemHasExistingOrGroup) {
            return accumulator.map((orGroup) => {
                if (orGroup[0].orGroup === currentItem.orGroup) {
                    return [...orGroup, currentItem];
                }
                return orGroup;
            });
        } else {
            return [...accumulator, [currentItem]];
        }
    }, []);

    /*
  .reduce(
    (result, item) => [
      ...result,
      [
        ...(result[item["orGroup"]] || []),
        item,
      ],
    ],
    [],
  )
  */
};

const getApplyOccasion = (taskChange, t) => {
    if (!taskChange) {
        return null;
    }
    return translateSelectItem(
        applyOccasion.find((occasion) => occasion.value === taskChange),
        t,
        'translationKey'
    );
};

const eventAttributes = (automation) => {
    if (automation.applyConditionTime) {
        return {
            taskChange: automation.applyConditionTime.value, //create || edit
        };
    }
    return {};
};

const timerAttributes = (automation) => {
    if (automation.applyConditionTime) {
        return {};
    }
    return {
        startAt: '',
        repeatEvery: 1,
    };
};

const getConditionTarget = (condition, statuses, tags, t) => {
    const translatedAnyOptions = translateSelectItem(anyOption, t, 'value');
    const translatedRequesterOption = translateSelectItem(requesterOption, t, 'value');
    const translatedAssignedOption = translateSelectItem(assignedOption, t, 'value');
    const translatedEmptyOption = translateSelectItem(emptyOption, t, 'value');
    const translatedCurrentUserOption = translateSelectItem(currentUserOption, t, 'value');
    const translatedCreatorOption = translateSelectItem(creatorOption, t, 'value');
    const translatedBoolOptions = translateAllSelectItems(boolOptions, t, 'value');

    let target = null;
    switch (condition.source) {
        case 'task':
            if (condition.condition === 'created') {
                //do nothing
            }
            if (condition.condition === 'editedBy') {
                target = [
                    ...condition.targetDatas.map((target) => ({
                        id: target.id,
                        ...[
                            translatedAnyOptions,
                            translatedRequesterOption,
                            translatedAssignedOption,
                        ].find((option) => option.value === target.type),
                    })),
                    ...condition.users.map((user) => ({
                        ...user,
                        label: user.fullName,
                        value: user.id,
                    })),
                ];
            }
            break;
        case 'important':
            target = [
                {
                    ...translatedBoolOptions.find(
                        (option) => option.value === condition.targetDatas[0].boolean
                    ),
                    id: condition.targetDatas[0].id,
                },
            ];
            break;
        case 'status':
            if (['setTo', 'unsetFrom', 'in', 'notIn'].includes(condition.condition)) {
                target = [
                    ...condition.targetDatas.map((target) => ({
                        id: target.id,
                        ...(target.type === 'any'
                            ? translatedAnyOptions
                            : statuses.find((status) => status.label === target.text)),
                    })),
                ];
            }
            if (condition.condition === 'hasAction') {
                target = [
                    ...condition.targetDatas.map((target) => ({
                        id: target.id,
                        ...statuses.find((status) => status.action === target.text),
                    })),
                ];
            }
            break;
        case 'tags':
            target = [
                ...condition.targetDatas.map((target) => ({
                    id: target.id,
                    ...(['any', 'empty'].includes(target.type)
                        ? [translatedEmptyOption, translatedAnyOptions].find(
                              (option) => option.value === target.type
                          )
                        : tags
                              .map((tag) => ({
                                  ...tag,
                                  value: tag.id,
                                  label: tag.title,
                              }))
                              .find((tag) => tag.title === target.text)),
                })),
            ];
            break;
        case 'assignedTo':
            target = [
                ...condition.targetDatas.map((target) => ({
                    id: target.id,
                    ...[
                        translatedAnyOptions,
                        translatedEmptyOption,
                        translatedRequesterOption,
                        translatedCurrentUserOption,
                    ].find((option) => option.value === target.type),
                })),
                ...condition.users.map((user) => ({
                    ...user,
                    label: user.fullName,
                    value: user.id,
                })),
            ];
            break;
        case 'requester':
            target = [
                ...condition.targetDatas.map((target) => ({
                    id: target.id,
                    ...[translatedAnyOptions, translatedCurrentUserOption].find(
                        (option) => option.value === target.type
                    ),
                })),
                ...condition.users.map((user) => ({
                    ...user,
                    label: user.fullName,
                    value: user.id,
                })),
            ];
            break;
        case 'company':
            target = [
                ...condition.targetDatas.map((target) => ({
                    id: target.id,
                    ...[translatedAnyOptions].find((option) => option.value === target.type),
                })),
                ...condition.companies.map((company) => ({
                    ...company,
                    label: company.title,
                    value: company.id,
                })),
            ];
            break;
        case 'startsAt':
        case 'pendingDate':
        case 'closeDate':
        case 'updatedAt':
        case 'statusDate':
        case 'createdAt':
            if (condition.condition === 'changed') {
                //do nothing
            }
            if (['before', 'after'].includes(condition.condition)) {
                target = [
                    {
                        id: condition.targetDatas[0].id,
                        value: fromUnixToDate(condition.targetDatas[0].date),
                    },
                ];
            }
            break;
        case 'deadline':
            if (condition.condition === 'changed') {
                //do nothing
            }
            if (['before', 'after', 'setTo', 'unsetFrom'].includes(condition.condition)) {
                target = [
                    {
                        id: condition.targetDatas[0].id,
                        value: fromUnixToDate(condition.targetDatas[0].date),
                    },
                ];
            }
            break;
        case 'pendingChangeable':
            break;
        case 'title':
            if (condition.condition === 'changed') {
                //do nothing
            }
            break;
        case 'description':
            if (condition.condition === 'changed') {
                //do nothing
            }
            break;
        case 'attachment':
            if (['addedBy', 'deletedBy'].includes(condition.condition)) {
                target = [
                    ...condition.targetDatas.map((target) => ({
                        id: target.id,
                        ...[
                            translatedAnyOptions,
                            translatedRequesterOption,
                            translatedAssignedOption,
                        ].find((option) => option.value === target.type),
                    })),
                    ...condition.users.map((user) => ({
                        ...user,
                        label: user.fullName,
                        value: user.id,
                    })),
                ];
            }
            break;
        case 'subtask':
        case 'shortSubtask':
        case 'material':
            if ('setTo' === condition.condition) {
                target = [
                    {
                        ...translatedBoolOptions.find(
                            (option) => option.value === condition.targetDatas[0].boolean
                        ),
                        id: condition.targetDatas[0].id,
                    },
                ];
            }
            if (['addedBy', 'editedBy', 'deletedBy'].includes(condition.condition)) {
                target = [
                    ...condition.targetDatas.map((target) => ({
                        id: target.id,
                        ...[
                            translatedAnyOptions,
                            translatedRequesterOption,
                            translatedAssignedOption,
                        ].find((option) => option.value === target.type),
                    })),
                    ...condition.users.map((user) => ({
                        ...user,
                        label: user.fullName,
                        value: user.id,
                    })),
                ];
            }
            break;
        case 'repeat':
            if (['addedBy', 'editedBy', 'deletedBy'].includes(condition.condition)) {
                target = [
                    ...condition.targetDatas.map((target) => ({
                        id: target.id,
                        ...[
                            translatedAnyOptions,
                            translatedRequesterOption,
                            translatedAssignedOption,
                        ].find((option) => option.value === target.type),
                    })),
                    ...condition.users.map((user) => ({
                        ...user,
                        label: user.fullName,
                        value: user.id,
                    })),
                ];
            }
            break;
        case 'project':
            if (['setTo', 'in', 'notIn'].includes(condition.condition)) {
                if (condition.project) {
                    target = [
                        {
                            ...condition.project,
                            value: condition.project?.id,
                            label: condition.project?.title,
                        },
                    ];
                } else if (condition.projects) {
                    target = condition.projects.map((project) => ({
                        ...project,
                        value: project.id,
                        label: project.title,
                    }));
                } else if (condition.targetDatas[0].type === 'any') {
                    target = [
                        {
                            ...translatedAnyOptions,
                        },
                    ];
                }
            }
            break;
        case 'comment':
            if (['addedBy'].includes(condition.condition)) {
                target = [
                    ...condition.targetDatas.map((target) => ({
                        id: target.id,
                        ...[
                            translatedAnyOptions,
                            translatedRequesterOption,
                            translatedAssignedOption,
                        ].find((option) => option.value === target.type),
                    })),
                    ...condition.users.map((user) => ({
                        ...user,
                        label: user.fullName,
                        value: user.id,
                    })),
                ];
            }
            if (['contains'].includes(condition.condition)) {
                target = [
                    {
                        value: condition.targetDatas[0].text,
                        id: condition.targetDatas[0].id,
                    },
                ];
            }
            break;
        default:
            switch (condition.source) {
                case 'customAttributeText':
                case 'customAttributeTextarea':
                    target = [
                        {
                            value: condition.targetDatas[0].text,
                            id: condition.targetDatas[0].id,
                        },
                    ];
                    //customAttribute = automationEvent.source.id;
                    break;
                case 'customAttributeNumber':
                    target = [
                        {
                            value: condition.targetDatas[0].number,
                            id: condition.targetDatas[0].id,
                        },
                    ];
                    //  customAttribute = automationEvent.source.id;
                    break;
                case 'customAttributeSelect':
                case 'customAttributeMultiselect':
                    target = [
                        ...condition.targetDatas.map((target) => ({
                            id: target.id,
                            ...[translatedAnyOptions, translatedEmptyOption].find(
                                (option) => option.value === target.type
                            ),
                        })),
                        ...condition.customSelectValues.map((customOption) => ({
                            ...customOption,
                            label:
                                customOption.value.substring(0, 1).toUpperCase() +
                                customOption.value.substring(1),
                            value: customOption.value,
                        })),
                    ];
                    break;
                default:
                //do nothing
            }
    }

    return target;
};

const createValues = (action, statuses, tags, t) => {
    const translatedRequesterOption = translateSelectItem(requesterOption, t, 'value');
    const translatedAssignedOption = translateSelectItem(assignedOption, t, 'value');
    const translatedEmptyOption = translateSelectItem(emptyOption, t, 'value');
    const translatedCurrentUserOption = translateSelectItem(currentUserOption, t, 'value');
    const translatedCreatorOption = translateSelectItem(creatorOption, t, 'value');
    const translatedBoolOptions = translateAllSelectItems(boolOptions, t, 'value');
    const translatedTaskCreatorCompanyOption = translateSelectItem(
        creatorCompanyOption,
        t,
        'value'
    );
    const translatedRequesterCompanyOption = translateSelectItem(
        requesterCompanyOption,
        t,
        'value'
    );

    let value = null;

    switch (action.target) {
        case 'important':
            value = [
                {
                    ...translatedBoolOptions.find(
                        (option) => option.value === action.values[0].boolean
                    ),
                    id: action.values[0].id,
                },
            ];
            break;
        case 'status':
            value = [
                ...action.values.map((actionValue) => ({
                    id: actionValue.id,
                    ...statuses.find((status) => status.label === actionValue.text),
                })),
            ];
            break;
        case 'tags':
            value = [
                ...action.values.map((actionValue) => ({
                    id: actionValue.id,
                    ...(['empty'].includes(actionValue.type)
                        ? [translatedEmptyOption].find(
                              (option) => option.value === actionValue.type
                          )
                        : tags
                              .map((tag) => ({
                                  ...tag,
                                  value: tag.id,
                                  label: tag.title,
                              }))
                              .find((tag) => tag.title === actionValue.text)),
                })),
            ];
            break;
        case 'assignedTo':
            value = [
                ...action.values.map((actionValue) => ({
                    id: actionValue.id,
                    ...[translatedCreatorOption, translatedCurrentUserOption].find(
                        (option) => option.value === actionValue.type
                    ),
                })),
                ...action.users.map((user) => ({
                    ...user,
                    label: user.fullName,
                    value: user.id,
                })),
            ];
            break;
        case 'requester':
            value = [
                ...action.values.map((actionValue) => ({
                    id: actionValue.id,
                    ...[translatedCreatorOption, translatedCurrentUserOption].find(
                        (option) => option.value === actionValue.type
                    ),
                })),
                ...action.users.map((user) => ({
                    ...user,
                    label: user.fullName,
                    value: user.id,
                })),
            ];
            break;
        case 'company':
            if (action.values.length > 0 && 'creator' === action.values[0].type) {
                value = [
                    {
                        ...translatedTaskCreatorCompanyOption,
                        id: action.values[0].id,
                    },
                ];
            } else if (action.values.length > 0 && 'requester' === action.values[0].type) {
                value = [
                    {
                        ...translatedRequesterCompanyOption,
                        id: action.values[0].id,
                    },
                ];
            } else {
                value = [
                    {
                        ...action.company,
                        label: action.company.title,
                        value: action.company.id,
                    },
                ];
            }
            break;
        case 'startsAt':
        case 'deadline':
        case 'pendingDate':
        case 'closeDate':
        case 'updatedAt':
        case 'statusDate':
        case 'createdAt':
            value = [
                {
                    id: action.values[0].id,
                    value: fromUnixToDate(action.values[0].date),
                },
            ];
            break;
        case 'pendingChangeable':
            value = [
                {
                    ...translatedBoolOptions.find(
                        (option) => option.value === action.values[0].boolean
                    ),
                    id: action.values[0].id,
                },
            ];
            break;
        case 'email':
        case 'notification':
            value = [
                ...action.values
                    .filter((value) => value.type)
                    .map((actionValue) => ({
                        id: actionValue.id,
                        ...[
                            translatedCreatorOption,
                            translatedRequesterOption,
                            translatedAssignedOption,
                        ].find((option) => option.value === actionValue.type),
                    })),
                ...action.users.map((user) => ({
                    ...user,
                    label: user.fullName,
                    value: user.id,
                })),
            ];
            break;
        default:
            switch (action.target) {
                case 'customAttributeText':
                case 'customAttributeTextarea':
                    value = [
                        {
                            value: action.values[0].text,
                            id: action.values[0].id,
                        },
                    ];
                    //  customAttribute = action.target.id;
                    break;
                case 'customAttributeNumber':
                    value = [
                        {
                            value: action.values[0].number,
                            id: action.values[0].id,
                        },
                    ];
                    //    customAttribute = action.target.id;
                    break;
                case 'customAttributeSelect':
                case 'customAttributeMultiselect':
                    value = [
                        ...action.customSelectValues.map((customOption) => ({
                            ...customOption,
                            label:
                                customOption.value.substring(0, 1).toUpperCase() +
                                customOption.value.substring(1),
                            value: customOption.value,
                        })),
                    ];
                    break;
                default:
                //do nothing
            }
    }

    return value;
};

const getConditionSource = (source) => {
    if (source.type) {
        return source.type;
    }
    return source.value;
};

const getActionTarget = (action) => {
    if (action.target.value === 'pendingChangeable') {
        return 'pendingChangable';
    }
    if (action.target.type) {
        return action.target.type;
    }
    return action.target.value;
};

const createTargetData = (automationEvent, useProjects) => {
    let targetDatas = [];
    let users = [];
    let companies = [];
    let project = null;
    let projects = [];
    let customSelectValues = [];
    let customAttribute = null;

    if (!automationEvent.target) {
        return {
            targetDatas,
            users,
            companies,
            ...(useProjects
                ? {
                      projects,
                  }
                : {
                      project,
                  }),
            customSelectValues,
            customAttribute,
        };
    }

    switch (automationEvent.source.value) {
        case 'task':
            if (automationEvent.condition.value === 'created') {
                //do nothing
            }
            if (automationEvent.condition.value === 'editedBy') {
                targetDatas = automationEvent.target
                    .filter((target) => ['any', 'requester', 'assignedTo'].includes(target.value))
                    .map((target) => ({
                        id: fakeID--,
                        type: target.value,
                        text: null,
                        date: null,
                        dateShift: null,
                        number: null,
                        boolean: null,
                        isNull: null,
                    }));
                users = automationEvent.target
                    .filter((target) => !['any', 'requester', 'assignedTo'].includes(target.value))
                    .map((target) => target.value);
            }
            break;
        case 'important':
            targetDatas = [
                {
                    id: fakeID--,
                    type: null,
                    text: null,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: automationEvent.target[0].value,
                    isNull: null,
                },
            ];
            break;
        case 'status':
            if (['setTo', 'unsetFrom', 'in', 'notIn'].includes(automationEvent.condition.value)) {
                targetDatas = automationEvent.target.map((target) => ({
                    id: fakeID--,
                    type: target.value === 'any' ? 'any' : null,
                    text: target.value === 'any' ? null : target.label,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: null,
                    isNull: null,
                }));
            }
            if (automationEvent.condition.value === 'hasAction') {
                targetDatas = automationEvent.target.map((target) => ({
                    id: fakeID--,
                    type: 'statusActions',
                    text: target.label,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: null,
                    isNull: null,
                }));
            }
            break;
        case 'tags':
            targetDatas = automationEvent.target.map((target) => ({
                id: fakeID--,
                type: ['any', 'empty'].includes(target.value) ? target.value : null,
                text: ['any', 'empty'].includes(target.value) ? null : target.label,
                date: null,
                dateShift: null,
                number: null,
                boolean: null,
                isNull: null,
            }));
            break;
        case 'assignedTo':
            targetDatas = automationEvent.target
                .filter((target) =>
                    ['empty', 'any', 'requester', 'currentUser'].includes(target.value)
                )
                .map((target) => ({
                    id: fakeID--,
                    type: target.value,
                    text: null,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: null,
                    isNull: null,
                }));
            users = automationEvent.target
                .filter(
                    (target) => !['empty', 'any', 'requester', 'currentUser'].includes(target.value)
                )
                .map((target) => target.value);
            break;
        case 'requester':
            targetDatas = automationEvent.target
                .filter((target) => ['any', 'currentUser'].includes(target.value))
                .map((target) => ({
                    id: fakeID--,
                    type: target.value,
                    text: null,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: null,
                    isNull: null,
                }));
            users = automationEvent.target
                .filter((target) => !['any', 'currentUser'].includes(target.value))
                .map((target) => target.value);
            break;
        case 'company':
            targetDatas = automationEvent.target
                .filter((target) => ['any'].includes(target.value))
                .map((target) => ({
                    id: fakeID--,
                    type: target.value,
                    text: null,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: null,
                    isNull: null,
                }));
            companies = automationEvent.target
                .filter((target) => !['any'].includes(target.value))
                .map((target) => target.value);
            break;
        case 'startsAt':
        case 'pendingDate':
        case 'closeDate':
        case 'deadline':
        case 'updatedAt':
        case 'statusDate':
        case 'createdAt':
            if (automationEvent.condition.value === 'changed') {
                //do nothing
            }
            if (['before', 'after'].includes(automationEvent.condition.value)) {
                targetDatas = [
                    {
                        id: fakeID--,
                        type: useProjects ? 'now' : null,
                        text: null,
                        date: useProjects ? null : automationEvent.target[0].value.valueOf() + '',
                        dateShift: null,
                        number: null,
                        boolean: null,
                        isNull: null,
                    },
                ];
            }
            break;
        case 'pendingChangeable':
            break;
        case 'title':
        case 'description':
            if (automationEvent.condition.value === 'changed') {
                //do nothing
            }
            break;
        case 'attachment':
            if (['addedBy', 'deletedBy'].includes(automationEvent.condition.value)) {
                targetDatas = automationEvent.target
                    .filter((target) => ['any', 'requester', 'assignedTo'].includes(target.value))
                    .map((target) => ({
                        id: fakeID--,
                        type: target.value,
                        text: null,
                        date: null,
                        dateShift: null,
                        number: null,
                        boolean: null,
                        isNull: null,
                    }));
                users = automationEvent.target
                    .filter((target) => !['any', 'requester', 'assignedTo'].includes(target.value))
                    .map((target) => target.value);
            }
            break;
        case 'subtask':
        case 'shortSubtask':
        case 'material':
            if ('setTo' === automationEvent.condition.value) {
                targetDatas = [
                    {
                        id: fakeID--,
                        type: null,
                        text: null,
                        date: null,
                        dateShift: null,
                        number: null,
                        boolean: automationEvent.target[0].value === 'open' ? false : true,
                        isNull: null,
                    },
                ];
            }
            if (['addedBy', 'editedBy', 'deletedBy'].includes(automationEvent.condition.value)) {
                targetDatas = automationEvent.target
                    .filter((target) => ['any', 'requester', 'assignedTo'].includes(target.value))
                    .map((target) => ({
                        id: fakeID--,
                        type: target.value,
                        text: null,
                        date: null,
                        dateShift: null,
                        number: null,
                        boolean: null,
                        isNull: null,
                    }));
                users = automationEvent.target
                    .filter((target) => !['any', 'requester', 'assignedTo'].includes(target.value))
                    .map((target) => target.value);
            }
            break;
        case 'repeat':
            if (['addedBy', 'editedBy', 'deletedBy'].includes(automationEvent.condition.value)) {
                targetDatas = automationEvent.target
                    .filter((target) => ['any', 'requester', 'assignedTo'].includes(target.value))
                    .map((target) => ({
                        id: fakeID--,
                        type: target.value,
                        text: null,
                        date: null,
                        dateShift: null,
                        number: null,
                        boolean: null,
                        isNull: null,
                    }));
                users = automationEvent.target
                    .filter((target) => !['any', 'requester', 'assignedTo'].includes(target.value))
                    .map((target) => target.value);
            }
            break;
        case 'project':
            if (['setTo', 'in', 'notIn'].includes(automationEvent.condition.value)) {
                if (automationEvent.target[0].value === 'any') {
                    targetDatas = [
                        {
                            id: fakeID--,
                            type: automationEvent.target[0].value,
                            text: null,
                            date: null,
                            dateShift: null,
                            number: null,
                            boolean: null,
                            isNull: null,
                        },
                    ];
                } else {
                    if (useProjects) {
                        projects = automationEvent.target.map((project) => project.id);
                    } else {
                        project = automationEvent.target[0].value;
                    }
                }
            }
            break;
        case 'comment':
            if (['addedBy'].includes(automationEvent.condition.value)) {
                targetDatas = automationEvent.target
                    .filter((target) => ['any', 'requester', 'assignedTo'].includes(target.value))
                    .map((target) => ({
                        id: fakeID--,
                        type: target.value,
                        text: null,
                        date: null,
                        dateShift: null,
                        number: null,
                        boolean: null,
                        isNull: null,
                    }));
                users = automationEvent.target
                    .filter((target) => !['any', 'requester', 'assignedTo'].includes(target.value))
                    .map((target) => target.value);
            }
            if (['contains'].includes(automationEvent.condition.value)) {
                targetDatas = [
                    {
                        id: fakeID--,
                        type: null,
                        text: automationEvent.target,
                        date: null,
                        dateShift: null,
                        number: null,
                        boolean: null,
                        isNull: null,
                    },
                ];
            }
            break;
        default:
            switch (automationEvent.source.type) {
                case 'customAttributeText':
                case 'customAttributeTextarea':
                    targetDatas = [
                        {
                            id: fakeID--,
                            type: null,
                            text: automationEvent.target[0].value,
                            date: null,
                            dateShift: null,
                            number: null,
                            boolean: null,
                            isNull: null,
                        },
                    ];
                    customAttribute = automationEvent.source.id;
                    break;
                case 'customAttributeNumber':
                    targetDatas = [
                        {
                            id: fakeID--,
                            type: null,
                            text: null,
                            date: null,
                            dateShift: null,
                            number: parseFloat(automationEvent.target[0].value),
                            boolean: null,
                            isNull: null,
                        },
                    ];
                    customAttribute = automationEvent.source.id;
                    break;
                case 'customAttributeSelect':
                case 'customAttributeMultiselect':
                    targetDatas = automationEvent.target
                        .filter((target) => ['any'].includes(target.value))
                        .map((target) => ({
                            id: fakeID--,
                            type: target.value,
                            text: null,
                            date: null,
                            dateShift: null,
                            number: null,
                            boolean: null,
                            isNull: null,
                        }));
                    customSelectValues = automationEvent.target
                        .filter((target) => !['any', 'empty'].includes(target.value))
                        .map((target) => target.id);
                    customAttribute = automationEvent.source.id;
                    break;
                default:
                //do nothing
            }
    }

    return {
        targetDatas,
        users,
        companies,
        ...(useProjects
            ? {
                  projects,
              }
            : {
                  project,
              }),
        customSelectValues,
        customAttribute,
    };
};

const createAddValues = (action) => {
    let values = [];
    let users = [];
    let company = null;
    let customSelectValues = [];
    let customAttribute = null;

    if (!action.value) {
        return {
            values,
            users,
            company,
            customSelectValues,
            customAttribute,
        };
    }

    switch (action.target.value) {
        case 'important':
            values = [
                {
                    id: fakeID--,
                    type: null,
                    text: null,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: action.value[0].value,
                    isNull: null,
                },
            ];
            break;
        case 'status':
            values = action.value.map((value) => ({
                id: fakeID--,
                type: null,
                text: value.label,
                date: null,
                dateShift: null,
                number: null,
                boolean: null,
                isNull: null,
            }));
            break;
        case 'tags':
            values = action.value.map((value) => ({
                id: fakeID--,
                type: ['empty'].includes(value.value) ? value.value : null,
                text: ['empty'].includes(value.value) ? null : value.label,
                date: null,
                dateShift: null,
                number: null,
                boolean: null,
                isNull: null,
            }));
            break;
        case 'assignedTo':
            values = action.value
                .filter((value) => ['creator', 'currentUser'].includes(value.value))
                .map((value) => ({
                    id: fakeID--,
                    type: value.value,
                    text: null,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: null,
                    isNull: null,
                }));
            users = action.value
                .filter((value) => !['creator', 'currentUser'].includes(value.value))
                .map((value) => value.value);
            break;
        case 'requester':
            values = action.value
                .filter((value) => ['creator', 'currentUser'].includes(value.value))
                .map((value) => ({
                    id: fakeID--,
                    type: value.value,
                    text: null,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: null,
                    isNull: null,
                }));
            users = action.value
                .filter((value) => !['creator', 'currentUser'].includes(value.value))
                .map((value) => value.value);
            break;
        case 'company':
            if ('creatorCompany' === action.value[0].value) {
                values = [
                    {
                        id: fakeID--,
                        type: 'creator',
                        text: null,
                        date: null,
                        dateShift: null,
                        number: null,
                        boolean: null,
                        isNull: null,
                    },
                ];
            } else if ('requesterCompany' === action.value[0].value) {
                values = [
                    {
                        id: fakeID--,
                        type: 'requester',
                        text: null,
                        date: null,
                        dateShift: null,
                        number: null,
                        boolean: null,
                        isNull: null,
                    },
                ];
            } else {
                company = action.value[0].value;
            }
            break;
        case 'startsAt':
        case 'deadline':
        case 'pendingDate':
        case 'closeDate':
        case 'createdAt':
        case 'updatedAt':
        case 'statusDate':
            values = [
                {
                    id: fakeID--,
                    type: null,
                    text: null,
                    date: action.value[0].value.valueOf() + '',
                    dateShift: null,
                    number: null,
                    boolean: null,
                    isNull: null,
                },
            ];
            break;
        case 'pendingChangeable':
            values = [
                {
                    id: fakeID--,
                    type: null,
                    text: null,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: action.value[0].value,
                    isNull: null,
                },
            ];
            break;
        case 'email':
            values = action.value
                .filter((value) => ['creator', 'requester', 'assignedTo'].includes(value.value))
                .map((value) => ({
                    id: fakeID--,
                    type: value.value,
                    text: null,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: null,
                    isNull: null,
                }));
            users = action.value
                .filter((value) => !['creator', 'requester', 'assignedTo'].includes(value.value))
                .map((value) => value.value);
            if (users.length > 0) {
                values.push({
                    id: fakeID--,
                    type: null,
                    text: action.notificationMessage,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: null,
                    isNull: null,
                });
            }
            break;
        case 'notification':
            values = action.value
                .filter((value) => ['creator', 'requester', 'assignedTo'].includes(value.value))
                .map((value) => ({
                    id: fakeID--,
                    type: value.value,
                    text: action.notificationMessage,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: null,
                    isNull: null,
                }));
            users = action.value
                .filter((value) => !['creator', 'requester', 'assignedTo'].includes(value.value))
                .map((value) => value.value);
            if (users.length > 0) {
                values.push({
                    id: fakeID--,
                    type: null,
                    text: action.notificationMessage,
                    date: null,
                    dateShift: null,
                    number: null,
                    boolean: null,
                    isNull: null,
                });
            }
            break;
        default:
            switch (action.target.type) {
                case 'customAttributeText':
                case 'customAttributeTextarea':
                    values = [
                        {
                            id: fakeID--,
                            type: null,
                            text: action.value[0].value,
                            date: null,
                            dateShift: null,
                            number: null,
                            boolean: null,
                            isNull: null,
                        },
                    ];
                    customAttribute = action.target.id;
                    break;
                case 'customAttributeNumber':
                    values = [
                        {
                            id: fakeID--,
                            type: null,
                            text: null,
                            date: null,
                            dateShift: null,
                            number: parseFloat(action.value[0].value),
                            boolean: null,
                            isNull: null,
                        },
                    ];
                    customAttribute = action.target.id;
                    break;
                case 'customAttributeSelect':
                case 'customAttributeMultiselect':
                    customSelectValues = action.value.map((target) => target.id);
                    customAttribute = action.target.id;
                    break;
                default:
                //do nothing
            }
    }

    return {
        values,
        users,
        company,
        customSelectValues,
        customAttribute,
    };
};
