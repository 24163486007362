import React from 'react';

import { useNavigate, useParams, NavLink } from 'react-router-dom';

import { useQuery, useSubscription } from '@apollo/client';

import { Label, Nav, NavItem, Button, Modal, ModalBody } from 'reactstrap';
import Select from 'react-select';

import Loading from 'components/loading';
import Empty from 'components/Empty';
import { pickSelectStyle } from 'configs/components/select';

import classnames from 'classnames';

import Filter from '../filter';
import TaskAdd from '../../task/add';
//import MilestoneEdit from '../milestones/milestoneEdit';
import MilestoneAdd from '../milestones/milestoneAdd';
import UserAdd from 'helpdesk/settings/users/userAdd';
import CompanyAdd from 'helpdesk/settings/companies/companyAdd';

import { getEmptyGeneralFilter } from 'configs/constants/filter';

import {
    toSelArr,
    toSelItem,
    sortBy,
    filterUnique,
    getMyData,
    splitArrayByFilter,
    translateSelectItem,
} from 'helperFunctions';

import { dashboard, addProject, allMilestones } from 'configs/constants/sidebar';
import settings from 'configs/constants/settings';

import { useTranslation } from 'react-i18next';

import { GET_MY_PROJECTS, PROJECTS_SUBSCRIPTION } from 'helpdesk/settings/projects/queries';
import { MILESTONES_SUBSCRIPTION } from './queries';
import { GET_MY_FILTERS, FILTERS_SUBSCRIPTION } from '../filter/queries';

import {
    GET_PROJECT,
    GET_LOCAL_CALENDAR_USER_ID,
    GET_FILTER_OPEN,
} from 'apollo/localSchema/queries';

import {
    setFilter,
    setProject,
    setMilestone,
    setCalendarUserId,
    setFilterOpen,
} from 'apollo/localSchema/actions';
import folderIcon from 'scss/icons/folder.svg';
import filterIcon from 'scss/icons/filter.svg';

export default function TasksSidebar(props) {
    //data & queries
    const { toggleSidebar, sidebarOpen } = props;

    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    //ziskaj len z networku data
    //prichystaj refresg ak sa nieco zmeni
    //listener zmien updatne lokalny projekt/filter/milestone
    //network
    const {
        data: myProjectsData,
        loading: myProjectsLoading,
        refetch: myProjectsRefetch,
    } = useQuery(GET_MY_PROJECTS, {
        fetchPolicy: 'network-only',
    });

    const {
        data: myFiltersData,
        loading: myFiltersLoading,
        refetch: myFiltersRefetch,
    } = useQuery(GET_MY_FILTERS, {
        fetchPolicy: 'network-only',
    });

    //local
    /*
  const {
  data: filterData,
  loading: filterLoading
  } = useQuery(GET_FILTER);
  */
    const { data: projectData, loading: projectLoading } = useQuery(GET_PROJECT);

    const { data: localCalendarUserId } = useQuery(GET_LOCAL_CALENDAR_USER_ID);

    const { data: filterOpenData } = useQuery(GET_FILTER_OPEN);
    /*
  const {
  data: milestoneData,
  loading: milestoneLoading
  } = useQuery(GET_MILESTONE);
  */
    useSubscription(PROJECTS_SUBSCRIPTION, {
        onData: () => {
            myProjectsRefetch();
        },
    });

    useSubscription(MILESTONES_SUBSCRIPTION, {
        onData: () => {
            myProjectsRefetch();
        },
    });

    useSubscription(FILTERS_SUBSCRIPTION, {
        onData: () => {
            myFiltersRefetch();
        },
    });

    //state
    const [showFilters, setShowFilters] = React.useState(true);
    const [showCalendarUsers, setShowCalendarUsers] = React.useState(true);
    const [openMilestoneAdd, setOpenMilestoneAdd] = React.useState(false);
    const [openCompanyAdd, setOpenCompanyAdd] = React.useState(false);
    const [openUserAdd, setOpenUserAdd] = React.useState(false);

    // sync
    React.useEffect(() => {
        if (!myFiltersLoading) {
            setLocalFilter();
        }
    }, [myFiltersData, myFiltersLoading, params.filterID]);

    React.useEffect(() => {
        if (!myProjectsLoading) {
            if (myProjectsData.myProjects) {
                const currentProject = myProjectsData.myProjects.find(
                    (project) => project.project.id === projectData.localProject.id
                );
                if (currentProject) {
                    setProject({
                        ...currentProject,
                        id: currentProject.project.id,
                        value: currentProject.project.id,
                        title: currentProject.project.title,
                        label: currentProject.project.title,
                    });
                }
            }
        }
    }, [myProjectsData, myProjectsLoading]);

    const currentUser = getMyData();
    const localProject = projectData.localProject;
    const filterOpen = filterOpenData.filterOpen;

    const getApplicableFilters = () => {
        if (myFiltersLoading || projectLoading) {
            return [];
        }
        let [publicFilters, customFilters] = splitArrayByFilter(
            myFiltersData.myFilters,
            (filter) => filter.pub
        );
        publicFilters = sortBy(publicFilters, [
            {
                key: 'order',
                asc: true,
            },
        ]);
        customFilters = sortBy(customFilters, [
            {
                key: 'title',
                asc: true,
            },
        ]);

        if (localProject.id === null) {
            return {
                publicFilters,
                customFilters,
            };
        }
        if (localProject.id) {
            return {
                publicFilters: publicFilters.filter(
                    (myFilter) =>
                        myFilter.global ||
                        (myFilter.project && myFilter.project.id === localProject.id)
                ),
                customFilters: customFilters.filter(
                    (myFilter) =>
                        myFilter.global ||
                        (myFilter.project && myFilter.project.id === localProject.id)
                ),
            };
        }
        return {
            publicFilters: [],
            customFilters: [],
        };
    };

    const getProjectFilters = () => {
        if (localProject.id === null) {
            return [];
        }
        return sortBy(localProject.project.projectFilters, [
            {
                key: 'order',
                asc: true,
            },
        ]);
    };

    const setLocalFilter = () => {
        if (window.location.pathname.length > 12) {
            let applicableFilters = getApplicableFilters();
            const allAvailableFilters = [
                ...applicableFilters.publicFilters,
                ...getProjectFilters(),
                ...applicableFilters.customFilters,
            ];
            const newFilter = allAvailableFilters.find(
                (item) => item.id === parseInt(params.filterID)
            );
            if (newFilter) {
                setFilter(newFilter);
            } else {
                setFilter(getEmptyGeneralFilter());
            }
        }
    };

    const dataLoading = !currentUser || myProjectsLoading || myFiltersLoading || projectLoading;

    if (dataLoading && sidebarOpen) {
        return <Loading />;
    }

    if (dataLoading && !sidebarOpen) {
        return (
            <div className="m-l-7">
                <Loading noPos={false} flex={true} noText={true} />
            </div>
        );
    }

    //Constants
    const myRights = currentUser.role.accessRights;
    const myProjects = (myProjectsData ? [...myProjectsData.myProjects] : []).sort(
        (project1, project2) => (project1.project.title > project2.project.title ? 1 : -1)
    );

    const canEditProject =
        localProject.id !== null &&
        (myRights.projects ||
            (localProject.right !== undefined && localProject.right.projectPrimaryRead));

    const canAddTask =
        localProject.id === null ||
        (localProject.right !== undefined && localProject.right.addTasks);

    const projects = [dashboard, ...myProjects];
    //const milestones = [allMilestones];
    //const milestones = [ allMilestones, ...( localProject.project.id !== null ? localProject.project.milestones : [] ) ]
    const tasklistPage = !['/helpdesk/repeats', '/helpdesk/companies', '/helpdesk/users'].some(
        (url) => window.location.pathname.includes(url)
    );

    const renderProjects = () => {
        let selectProjects = toSelArr(
            projects.map((project) => ({
                ...project,
                id: project.project.id,
                title: t(project.project.title),
            }))
        );
        /*
    if (myRights.addProjects && false) {
    selectProjects.push({
    label: `+ ${t('project')}`,
    value: -1
    });
    }
    */
        const URLprefix = `/helpdesk/taskList/i/all`;
        return (
            <div>
                <div className="sidebar-label row">
                    <div>
                        <img className="m-r-9" src={folderIcon} alt="Folder icon not found" />
                        <Label>{t('project')}</Label>
                    </div>
                    {localProject.id !== null && localProject.right.projectRead && (
                        <NavLink
                            className={() =>
                                `link-item-inline m-l-auto text-highlight no-decoration center-hor p-r-0`
                            }
                            to={`/helpdesk/project/${localProject.id}/description`}
                        >
                            <i className="fa fa-cog no-decoration p-r-0" />
                        </NavLink>
                    )}
                    {localProject.id === null && myRights.addProjects && (
                        <NavLink
                            className={() =>
                                `link-item-inline m-l-auto text-highlight no-decoration center-hor p-r-0`
                            }
                            to={`/helpdesk/project/add`}
                        >
                            <i className="fa fa-plus" />
                        </NavLink>
                    )}
                </div>
                <div
                    className="sidebar-menu-item"
                    style={{ paddingLeft: '6px', paddingRight: '6px' }}
                >
                    <Select
                        options={selectProjects}
                        value={translateSelectItem(localProject, t)}
                        styles={pickSelectStyle([
                            'invisible',
                            'blueFont',
                            'sidebar',
                            'flex',
                            'noPadding',
                        ])}
                        onChange={(pro) => {
                            if (pro.value === -1) {
                                navigate(`/helpdesk/project/add`);
                                return;
                            }
                            setMilestone(translateSelectItem(allMilestones, t));
                            setProject(pro);
                            if (tasklistPage) {
                                navigate(URLprefix);
                            }
                        }}
                    />
                </div>
            </div>
        );
    };
    const renderProjectsPopover = () => {
        const URLprefix = `/helpdesk/taskList/i/all`;

        return (
            <div className="sidebar-popover" style={{ top: 'calc( 0.5em + ( 4.5em * 1 ) )' }}>
                <div>
                    <div className="sidebar-label row">
                        <div>
                            <img className="m-r-9" src={folderIcon} alt="Folder icon not found" />
                            <Label>{t('project')}</Label>
                        </div>
                    </div>
                    <Nav vertical>
                        {projects
                            .map((project) => ({
                                ...project,
                                id: project.project.id,
                                title: project.project.title,
                            }))
                            .map((project) => (
                                <NavItem
                                    key={project.id}
                                    className={classnames('row full-width sidebar-item', {
                                        'active': localProject.id === project.id,
                                    })}
                                >
                                    <span
                                        className={classnames('clickable sidebar-menu-item link', {
                                            'active': localProject.id === project.id,
                                        })}
                                        onClick={() => {
                                            setMilestone(allMilestones);
                                            setProject({
                                                ...project,
                                                label: project.title,
                                                value: project.id,
                                            });
                                            if (tasklistPage) {
                                                navigate(URLprefix);
                                            }
                                        }}
                                    >
                                        {project.title}
                                    </span>
                                    {canEditProject &&
                                        localProject.id !== null &&
                                        localProject.id === project.id && (
                                            <Button
                                                className="btn-link ml-auto m-r-15"
                                                onClick={() =>
                                                    navigate(
                                                        `/helpdesk/project/${localProject.id}/description`
                                                    )
                                                }
                                            >
                                                <i className="fa fa-cog" />
                                            </Button>
                                        )}
                                </NavItem>
                            ))}
                    </Nav>
                    {renderProjectAddBtn()}
                </div>
            </div>
        );
    };
    const renderProjectAddBtn = () => {
        if (!myRights.addProjects) {
            return null;
        }
        return (
            <div className="row full-width">
                <button className="btn-link" onClick={() => navigate(`/helpdesk/project/add`)}>
                    <i className="fa fa-plus p-l-15" />
                    {addProject.project.title}
                </button>
            </div>
        );
    };

    const renderCalendarUsers = (alwaysShow) => {
        return (
            <div className={classnames({ clickable: !alwaysShow })}>
                <div
                    className="sidebar-label row clickable"
                    onClick={() =>
                        setShowCalendarUsers(alwaysShow ? showCalendarUsers : !showCalendarUsers)
                    }
                >
                    <div>
                        <i className="m-r-9 fa fa-user" />
                        <Label className={classnames({ clickable: !alwaysShow })}>
                            {t('calendarUsers')}
                        </Label>
                    </div>
                    {!alwaysShow && (
                        <div className="ml-auto">
                            {showCalendarUsers && <i className="fas fa-chevron-up" />}
                            {!showCalendarUsers && <i className="fas fa-chevron-down" />}
                        </div>
                    )}
                </div>
                {(showCalendarUsers || alwaysShow) && renderCalendarUsersList()}
            </div>
        );
    };
    const renderCalendarUsersList = () => {
        if (!myRights.tasklistCalendar) {
            return null;
        }
        const myID = currentUser.id;
        const userID = localCalendarUserId.localCalendarUserId;
        let usersToRender = [];
        if (localProject.id !== null) {
            usersToRender = localProject.usersWithRights.filter(
                (userWithRights) => userWithRights.assignable
            );
        } else {
            usersToRender = myProjects
                .filter((myProject) => myProject.attributeRights.assigned.view)
                .reduce((acc, cur) => {
                    const usersToAdd = cur.usersWithRights.filter(
                        (userWithRights) => userWithRights.assignable
                    );
                    return [...acc, ...usersToAdd];
                }, []);
        }
        usersToRender = sortBy(
            filterUnique(
                usersToRender.map((userToRender) => userToRender.user),
                'id'
            ),
            [
                {
                    key: 'fullName',
                    asc: true,
                },
            ]
        );

        return (
            <Nav vertical>
                {usersToRender.map((user) => (
                    <NavItem
                        key={user.id}
                        className={classnames('row full-width sidebar-item', {
                            'active': userID === user.id || (userID === null && myID === user.id),
                        })}
                    >
                        <span
                            className={classnames('clickable sidebar-menu-item link', {
                                'active':
                                    userID === user.id || (userID === null && myID === user.id),
                            })}
                            onClick={() => {
                                setCalendarUserId(user.id);
                            }}
                        >
                            {user.fullName}
                        </span>
                    </NavItem>
                ))}
            </Nav>
        );
    };
    const renderCalendarUsersPopover = () => {
        if (!myRights.tasklistCalendar) {
            return null;
        }
        return (
            <div className="sidebar-popover" style={{ top: 'calc( 0.5em + ( 4.5em * 4 ) )' }}>
                {renderCalendarUsers(true)}
            </div>
        );
    };

    const renderFiltersList = () => {
        const applicableFilters = getApplicableFilters();
        return (
            <Empty>
                <NavLink
                    key="all"
                    className={({ isActive }) =>
                        `link-item ${
                            isActive || 'all' === params.filterID ? 'active-highlight' : ''
                        }`
                    }
                    to={`/helpdesk/taskList/i/all`}
                >
                    {t('allTasks')}
                </NavLink>
                {applicableFilters.publicFilters.map((filter) => (
                    <NavItem
                        key={filter.id}
                        className={classnames('row full-width sidebar-item', {
                            'active': filter.id === parseInt(params.filterID),
                        })}
                    >
                        <span
                            className={classnames('clickable sidebar-menu-item link', {
                                'active': filter.id === parseInt(params.filterID),
                            })}
                            onClick={() => navigate(`/helpdesk/taskList/i/${filter.id}`)}
                        >
                            {filter.title}
                        </span>
                        {myRights.publicFilters && (
                            <div
                                className={classnames('sidebar-icon clickable', {
                                    'active': filter.id === parseInt(params.filterID),
                                })}
                                onClick={() => {
                                    if (filter.id === parseInt(params.filterID)) {
                                        setFilterOpen(true);
                                    }
                                }}
                            >
                                <i className="fa fa-cog p-r-3" />
                            </div>
                        )}
                    </NavItem>
                ))}
                {getProjectFilters().map((filter) => (
                    <NavItem
                        key={filter.id}
                        className={classnames('row full-width sidebar-item', {
                            'active': filter.id === parseInt(params.filterID),
                        })}
                    >
                        <span
                            className={classnames('clickable sidebar-menu-item link', {
                                'active': filter.id === parseInt(params.filterID),
                            })}
                            onClick={() => navigate(`/helpdesk/taskList/i/${filter.id}`)}
                        >
                            {filter.title}
                        </span>
                    </NavItem>
                ))}
                {applicableFilters.customFilters.map((filter) => (
                    <NavLink
                        key={filter.id}
                        className={({ isActive }) =>
                            `link-item ${
                                isActive || filter.id === parseInt(params.filterID)
                                    ? 'active-highlight'
                                    : ''
                            }`
                        }
                        to={`/helpdesk/taskList/i/${filter.id}`}
                    >
                        {filter.title}
                        {myRights.customFilters && filter.id === parseInt(params.filterID) && (
                            <div
                                className="m-l-auto active"
                                onClick={() => {
                                    if (filter.id === parseInt(params.filterID)) {
                                        setFilterOpen(true);
                                    }
                                }}
                            >
                                <i className="fa fa-cog center-hor" />
                            </div>
                        )}
                    </NavLink>
                ))}
                <NavLink
                    key="repeats"
                    className={({ isActive }) => `link-item ${isActive ? 'active-highlight' : ''}`}
                    to={`/helpdesk/repeats`}
                >
                    {t('repetitiveTasks')}
                </NavLink>
            </Empty>
        );
    };
    const renderFilters = (alwaysShow) => {
        return (
            <div className={classnames({ clickable: !alwaysShow })}>
                <div
                    className="sidebar-label row"
                    onClick={() => setShowFilters(alwaysShow ? showFilters : !showFilters)}
                >
                    <div>
                        <img
                            className="m-r-5"
                            style={{
                                color: '#212121',
                                height: '17px',
                                marginBottom: '3px',
                            }}
                            src={filterIcon}
                            alt="Filter icon not found"
                        />
                        <Label className={classnames({ clickable: !alwaysShow })}>
                            {t('filters')}
                        </Label>
                    </div>
                    {!alwaysShow && (
                        <div className="ml-auto m-r-3">
                            {showFilters && <i className="fas fa-chevron-up" />}
                            {!showFilters && <i className="fas fa-chevron-down" />}
                        </div>
                    )}
                </div>
                {(showFilters || alwaysShow) && renderFiltersList()}
                {(showFilters || alwaysShow) &&
                    (myRights.customFilters || myRights.publicFilters) &&
                    renderFilterAddBtn()}
            </div>
        );
    };
    const renderFilterAddBtn = () => {
        if (!myRights.customFilters && !myRights.publicFilters) {
            return null;
        }
        return (
            <div className="row full-width">
                <button
                    className="btn-link p-l-15"
                    onClick={() => {
                        navigate('/helpdesk/taskList/i/all');
                        setFilterOpen(true);
                    }}
                >
                    <i className="fa fa-plus" />
                    {t('filter')}
                </button>
            </div>
        );
    };
    const renderFilterAdd = () => {
        if (!myRights.customFilters && !myRights.publicFilters) {
            return null;
        }
        return (
            <div>
                <div className="sidebar-label row" onClick={() => setShowFilters(!showFilters)}>
                    <div>
                        <img
                            className="m-r-5"
                            style={{
                                color: '#212121',
                                height: '17px',
                                marginBottom: '3px',
                            }}
                            src={filterIcon}
                            alt="Filter icon not found"
                        />
                        <Label>
                            {parseInt(params.filterID)
                                ? `${t('edit')} ${t('filter').toLowerCase()}`
                                : `${t('add')} ${t('filter').toLowerCase()}`}
                        </Label>
                    </div>
                </div>
                <Filter
                    close={() => {
                        setFilterOpen(false);
                    }}
                />
            </div>
        );
    };
    const renderFilterContainer = () => {
        return (
            <div className="sidebar-popover" style={{ top: 'calc( 0.5em + ( 4.5em * 2 ) )' }}>
                {filterOpen &&
                    (myRights.customFilters || myRights.publicFilters) &&
                    renderFilterAdd()}
                {!filterOpen && renderFilters(true)}
            </div>
        );
    };

    const renderTaskAddBtn = () => {
        return (
            <TaskAdd
                disabled={!canAddTask}
                projectID={
                    localProject.id !== null && localProject.right.addTask ? localProject.id : null
                }
                toggleSidebar={toggleSidebar}
                sidebarOpen={sidebarOpen}
            />
        );
    };

    const renderSettings = (canSeeSettings) => {
        return (
            <div>
                {canSeeSettings && (
                    <NavLink
                        key="settings"
                        className={({ isActive }) =>
                            `link-item ${isActive ? 'active-highlight' : ''}`
                        }
                        to={`/helpdesk/settings`}
                    >
                        <i className="fa fa-cog m-r-1 m-t-3" />
                        {t('settings')}
                    </NavLink>
                )}
                {myRights.users && (
                    <NavLink
                        key="users"
                        className={({ isActive }) =>
                            `link-item ${isActive ? 'active-highlight' : ''}`
                        }
                        to={`/helpdesk/users`}
                    >
                        <i className="fa fa-users m-r-1 m-t-3" />
                        {t('users')}
                    </NavLink>
                )}
                {myRights.companies && (
                    <NavLink
                        key="companies"
                        className={({ isActive }) =>
                            `link-item ${isActive ? 'active-highlight' : ''}`
                        }
                        to={`/helpdesk/companies`}
                    >
                        <i className="fa fa-building m-r-1 m-t-3" />
                        {t('companies')}
                    </NavLink>
                )}

                <NavLink
                    key="webchat"
                    className={({ isActive }) => `link-item ${isActive ? 'active-highlight' : ''}`}
                    to={`/helpdesk/webchat`}
                >
                    <i className="fa fa-regular fa-comment m-r-5 m-t-3" />
                    {t('webchat')}
                </NavLink>
                <NavLink
                    key="statistics"
                    className={({ isActive }) => `link-item ${isActive ? 'active-highlight' : ''}`}
                    to={`/helpdesk/statistics`}
                >
                    <i className="fa fa-solid fa-chart-line m-r-5 m-t-3" />
                    {t('statistics')}
                </NavLink>
            </div>
        );
    };

    const renderOpenSidebar = () => {
        const canSeeSettings = settings.some((setting) => myRights[setting.value]);
        return (
            <div>
                {renderTaskAddBtn()}

                <hr className="m-l-15 m-r-15 m-t-15" />
                {!filterOpen && (
                    <Empty>
                        {renderProjects()}

                        <hr className="m-l-15 m-r-15 m-t-11" />
                        {renderFilters()}

                        {(localProject.id === null || localProject.attributeRights.assigned.view) &&
                            currentUser.tasklistLayout === 3 && (
                                <Empty>
                                    <hr className="m-l-15 m-r-15 m-t-11" />
                                    {renderCalendarUsers()}
                                </Empty>
                            )}
                    </Empty>
                )}

                {filterOpen &&
                    (myRights.customFilters || myRights.publicFilters) &&
                    renderFilterAdd()}

                {(filterOpen || canSeeSettings || myRights.vykazy) && (
                    <hr className="m-l-15 m-r-15 m-t-11 m-b-11" />
                )}

                {!filterOpen &&
                    (canSeeSettings || myRights.vykazy) &&
                    renderSettings(canSeeSettings)}
            </div>
        );
    };

    return (
        <div>
            {!sidebarOpen && (
                <div>
                    <button
                        className="btn-link color-white popover-toggler full-width"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleSidebar();
                        }}
                    >
                        <i className="fa fa-bars" />
                    </button>

                    {renderTaskAddBtn()}

                    <div className="btn-link color-white popover-toggler text-center">
                        <img
                            src={folderIcon}
                            className="invert"
                            id="folder-icon"
                            alt="Folder icon not found"
                            style={{
                                color: 'white',
                            }}
                        />
                        {renderProjectsPopover()}
                    </div>

                    <div className="btn-link color-white popover-toggler text-center">
                        <img
                            src={filterIcon}
                            className="invert"
                            id="filter-icon"
                            alt="Filter icon not found"
                            style={{
                                color: 'white',
                            }}
                        />
                        {renderFilterContainer()}
                    </div>

                    {settings.some((setting) => myRights[setting.value]) && (
                        <button
                            className="btn-link color-white popover-toggler full-width"
                            onClick={() => {
                                toggleSidebar();
                                navigate('/helpdesk/settings');
                            }}
                        >
                            <i className="fa fa-cog" />
                        </button>
                    )}

                    {myRights.users && (
                        <button
                            className="btn-link color-white popover-toggler full-width"
                            onClick={() => navigate(`/helpdesk/users`)}
                        >
                            <i className="fa fa-users" />
                        </button>
                    )}
                    {myRights.companies && (
                        <button
                            className="btn-link color-white popover-toggler full-width"
                            onClick={() => navigate(`/helpdesk/companies`)}
                        >
                            <i className="fa fa-building" />
                        </button>
                    )}

                    {(localProject.id === null || localProject.attributeRights.assigned.view) &&
                        currentUser.tasklistLayout === 3 && (
                            <button className="btn-link color-white popover-toggler full-width">
                                <i className="fa fa-user" style={{ color: 'white' }} />
                                {renderCalendarUsersPopover()}
                            </button>
                        )}

                    <button
                        className="btn-link color-white popover-toggler full-width"
                        onClick={() => navigate(`/helpdesk/webchat`)}
                    >
                        <i className="fa fa-regular fa-comment" />
                    </button>
                </div>
            )}

            {sidebarOpen && renderOpenSidebar()}

            {canEditProject && openMilestoneAdd && (
                <MilestoneAdd
                    open={openMilestoneAdd}
                    closeModal={(newMilestone) => {
                        if (newMilestone) {
                            setMilestone(toSelItem(newMilestone));
                        }
                        setOpenMilestoneAdd(false);
                    }}
                />
            )}

            {openUserAdd && (
                <Modal isOpen={openUserAdd} className="modal-without-borders">
                    <ModalBody>
                        <UserAdd
                            closeModal={() => setOpenUserAdd(false)}
                            addUserToList={() => {}}
                        />
                    </ModalBody>
                </Modal>
            )}

            {openCompanyAdd && (
                <Modal isOpen={openCompanyAdd} className="modal-without-borders">
                    <ModalBody>
                        <CompanyAdd
                            closeModal={() => setOpenCompanyAdd(false)}
                            addCompanyToList={() => {}}
                        />
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
}
