import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import classnames from 'classnames';

import Empty from 'components/Empty';
import SettingLoading from '../components/settingLoading';
import SettingListContainer from '../components/settingListContainer';
import TripTypeAdd from './tripTypeAdd';
import TripTypeEdit from './tripTypeEdit';
import Loading from 'components/loading';

import { useTranslation } from 'react-i18next';
import { orderArr } from 'helperFunctions';
import { itemAttributesFullfillsString } from '../components/helpers';

import {
    GET_TRIP_TYPES,
    TRIP_TYPES_SUBSCRIPTION,
} from './queries';

export default function TripTypeListContainer(props) {
    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    const {
        data: tripTypesData,
        loading: tripTypesLoading,
        refetch: tripTypesRefetch,
    } = useQuery(GET_TRIP_TYPES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(TRIP_TYPES_SUBSCRIPTION, {
        onData: () => {
            tripTypesRefetch();
        },
    });

    // state
    const [tripTypeFilter, setTripTypeFilter] =
        React.useState('');

    if (tripTypesLoading) {
        return <SettingLoading />;
    }

    const tripTypes = orderArr(tripTypesData.tripTypes);

    const RightSideComponent = (
        <Empty>
            {params.id && params.id === 'add' && (
                <TripTypeAdd />
            )}
            {tripTypesLoading &&
                params.id &&
                params.id !== 'add' && <Loading />}
            {params.id &&
                params.id !== 'add' &&
                tripTypes.some(
                    (item) =>
                        item.id.toString() === params.id
                ) && <TripTypeEdit />}
        </Empty>
    );

    return (
        <SettingListContainer
            header={t('tripTypes')}
            filter={tripTypeFilter}
            setFilter={setTripTypeFilter}
            addURL="/helpdesk/settings/tripTypes/add"
            addLabel={t('tripType')}
            RightSideComponent={RightSideComponent}
        >
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>{t('title')}</th>
                        <th width="50">{t('order')}</th>
                    </tr>
                </thead>
                <tbody>
                    {tripTypes
                        .filter((item) =>
                            itemAttributesFullfillsString(
                                item,
                                tripTypeFilter,
                                ['title']
                            )
                        )
                        .map((tripType) => (
                            <tr
                                key={tripType.id}
                                className={classnames(
                                    'clickable',
                                    {
                                        'active':
                                            parseInt(
                                                params.id
                                            ) ===
                                            tripType.id,
                                    }
                                )}
                                onClick={() =>
                                    navigate(
                                        `/helpdesk/settings/tripTypes/${tripType.id}`
                                    )
                                }
                            >
                                <td>{tripType.title}</td>
                                <td>
                                    {tripType.order
                                        ? tripType.order
                                        : 0}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </SettingListContainer>
    );
}
