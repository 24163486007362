import React, { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import classnames from "classnames";

import { FormGroup, Label } from "reactstrap";
import Loading from "components/loading";
import { REST_URL } from "configs/restAPI";
import { GET_WEBCHAT_TOKEN } from "./queries";

import { useTranslation } from "react-i18next";

export default function WebchatSettings() {
  const { t } = useTranslation();

  const [generating, setGenerating] = useState(false);
  const [wasCopied, setWasCopied] = React.useState(false);

  const {
    data: webchatTokenData,
    loading: webchatTokenLoading,
    refetch: webchatTokenRefetch,
  } = useQuery(GET_WEBCHAT_TOKEN, {
    variables: {
      forceNew: false,
    },
  });

  //functions
  const generateNewToken = () => {
    setGenerating(true);
    webchatTokenRefetch({
      forceNew: true,
    });
  };

  const webchatToken = useMemo(() => {
    setGenerating(false);
    return webchatTokenData ? webchatTokenData.webchatToken : "";
  }, [webchatTokenData]);

  if (webchatTokenLoading) {
    return <Loading />;
  }

  const code = `<script>
  function displayWebchat() {
    var webchat = document.getElementById("webchat-iframe");
    if (webchat.style.display === "none"){
      webchat.style.display = "block";
    } else {
      webchat.style.display = "none";
    }
  }
</script>

<div style="z-index: 100; position: absolute; width: 100px; right: 18px; bottom: 70px;">
  <span style="background-color: transparent; border: none; color: #333; font: 14px Segoe UI Semibold;" >
    Support
  </span>
  <button
    id="webchat-btn-2"
    onclick="displayWebchat()"
    style="background-color: #0078d4; border: none; color: white; font: 14px Segoe UI; width: 100%; height: 30px;"
  >
    Chat
  </button>
</div>

<iframe
  id="webchat-iframe"
  name="webchat-iframe"
  src="${REST_URL}/get-webchat/?token=${webchatToken}"
  allowtransparency="true"
  style="z-index:99; position:absolute; right:18px; bottom:130px; width:600px; height:400px; display:none;"
/>
`;

  return (
    <div className="scroll-visible p-20 fit-with-header">
      <h2 className="m-b-20">{`${t("webchat")} ${t("settings")}`}</h2>
      <FormGroup>
        <Label>{t("placeIframe")}</Label>
        <button
          className="btn-link m-r-5"
          onClick={() => {
            navigator.clipboard.writeText(code);
            setWasCopied(true);
            setTimeout(() => {
              setWasCopied(false);
            }, 3000);
          }}
        >
          <i className={`p-l-5 p-r-5 center-hor fa fa-copy`} />
          {t("copy")}
        </button>
        {wasCopied && (
          <span className="inline-success-message">{t("copied")}</span>
        )}
        <div style={{ backgroundColor: "#f2f1f1", padding: "10px" }}>
          <code
            style={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              color: "black",
            }}
          >
            {code}
          </code>
        </div>
      </FormGroup>

      <div className="form-buttons-row">
        <button
          className={classnames("btn")}
          disabled={generating}
          onClick={() => {
            if (window.confirm(t("oldTokenWontWork"))) {
              generateNewToken();
            }
          }}
        >
          {generating ? t("generatingNewToken") : t("generateNewToken")}
        </button>
      </div>
    </div>
  );
}
