import React from 'react';

import Empty from 'components/Empty';
import CustomAttributeEntry from './entry';

import {
  useTranslation
} from "react-i18next";

export default function CustomAttributesList( props ) {
  const {
    customAttributes,
    setCustomAttributes
  } = props;

  const {
    t
  } = useTranslation();

  return (
    <Empty>
      {
        customAttributes.map((customAttribute) => (
          <CustomAttributeEntry key={customAttribute.id} {...props} customAttribute={customAttribute} />
        ))
      }
    </Empty>
  )
}
